import React from 'react';
import Layout from '../Layout';
import DashSideBar from "../sections/DashSideBar";
import Navigation from "../sections/Navigation";
import CopyrightCreateOrUpdate from "../sections/CopyrightCreateOrUpdate";
import { useTranslation } from 'react-i18next';
import CheckRole from '../sections/CheckRole';
import { roles } from "../../helpers/roles";

const DashCopyrights = () => {
    const { t } = useTranslation();
    return (<Layout>
			<CheckRole roles={ [ roles.teacher ] } />
        <main className="main">
            <section className="section section-dashboard py-0">
                <div className="container">
                    <div className="row">
                        <DashSideBar/>
                        <div className="col-md-12 col-lg-9 col-xl-10">
                            <div className="dashboard-right">
                                <Navigation
                                    is_dash={true}
                                    is_btn={true}
                                    active= {t('create_course.create')} 
                                    navs={[
                                        {
                                            'title': t('navigation.account'),
                                            'link': '/dashboard'
                                        },
                                        {
                                            'title': t('author_page.course'),
                                            'link': '/dashboard/copyrights'
                                        }
                                    ]}
                                />
                                <div className="dashboard-title">
                                    <h5> {t('create_course.create')} </h5>
                                </div>
                                <CopyrightCreateOrUpdate
                                    data={{}}
                                    is_create={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </Layout>);
}
export default DashCopyrights;