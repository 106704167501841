import React from "react";
import Highcharts from "highcharts";
import { HighchartsChart, withHighcharts } from "react-jsx-highcharts";
import { useTranslation } from "react-i18next";
import * as ChartModuleMore from "highcharts/highcharts-more.js";
ChartModuleMore(Highcharts);

const ChartColumnH = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  let names = [];
  let percents = [];
  data.map((cat) => {
    names.push(cat.name);
    percents.push(cat.persent);
  });

  const chart = {
    type: "bar",
  };
  const xAxis = {
    categories: names,
    title: {
      text: "",
    },
  };
  const yAxis = {
    min: 0,
    title: {
      text: "",
      align: "high",
    },
    labels: {
      overflow: "justify",
    },
  };
  const tooltip = {
    shared: true,
    pointFormat:
      '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}%</b><br/>',
  };
  const plotOptions = {
    bar: {
      dataLabels: {
        enabled: true,
        format: "{y} %",
      },
    },
  };
  const legend = {
    accessibility: {
      enabled: false,
    },
    keyboardNavigation: {
      enabled: true,
    },
  };
  const credits = {
    enabled: false,
  };
  const series = [
    //   {
    //     name: exercise?t('exercise.exercise_test'):t('profile-page.result-diagnostic'),
    //     data: diagnostics,
    //     color: 'red'
    //   },
    {
      name: t("test_result.diagnostics-results"),
      data: percents,
      color: "#2962FF",
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <HighchartsChart
        chart={chart}
        series={series}
        yAxis={yAxis}
        xAxis={xAxis}
        plotOptions={plotOptions}
        tooltip={tooltip}
        legend={legend}
        credits={credits}
      ></HighchartsChart>
    </div>
  );
};

export default withHighcharts(ChartColumnH, Highcharts);
