import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import Navigation from '../sections/Navigation';
import { getInstance } from '../../helpers/httpClient';
import Pagination from '../sections/Pagination';
import { useTranslation } from 'react-i18next';
import Loading from '../sections/Loading';


const FAQ = (props) => {
	const [faqs, setFaqs] = useState([ ]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const [perPage, setPerPage] = useState(1);
	const [total, setTotal] = useState(1);
	const { page } = props.match.params;
	useEffect(() => {
		setLoading(true);
		getInstance()
			.get(`/api/v1/faq?page=${page}`)
			.then(response => {
				if (response.status === 200) {
					const { data, total, per_page } = response.data;
					setFaqs(data);
					setPerPage(per_page);
					setTotal(total);
				} else {
					setFaqs([]);
				}
				setLoading(false);
			})
			.catch(error =>{ setFaqs([]); setLoading(false); });
	}, [page]);
	return (
		<>
			<Layout>
				<main className="main">
					<section className="section section-breadcrumbs">
						<div className="container container-lg">
							<div className="row">
								<div className="col-12">
									<Navigation is_btn={false} active={t('faq.nav-title')} navs={[]} />
								</div>
							</div>
							<div
								className="row p-5"
								style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<div className="col-12 col-xl-10 mb-5">
									<h3 style={{ fontSize: 22, fontWeight: 700 }}>{t('faq.title')}</h3>
								</div>
								{loading?<Loading/>:faqs.map((item, index) => {
									const { title, body } = item;
									const order = `${index + 1 + (parseInt(page) - 1) * 20}. ${title}`;
									return (
										<div
											className="col-12 col-xl-10 "
											key={index}
											style={{
												margin: '15px 0',
												boxShadow: '0px 7px 29px rgba(13, 46, 105, 0.05)',
												borderRadius: 10,
												padding: 24,
											}}>
											<details>
												<summary
													style={{
														fontSize: 18,
														fontWeight: 500,
														color: '#0D2E69',
														outline: 'none',
														display: 'flex',
														justifyContent: 'space-between',
														appearance: 'none',
													}}>
													<span>{order}</span>
													<span
														style={{ color: '#898DA6' }}
														className="details-arrow icon-arrow-down-1"></span>
												</summary>
												<p className='faq_body_target' dangerouslySetInnerHTML={{ __html: body }}></p>
											</details>
										</div>
									);
								})}
							</div>
							<div className="row p-3" style={{ display: 'flex', justifyContent: 'center' }}>
								<Pagination total={total} count={perPage} active={parseInt(page)} link="/faq/" />
							</div>
						</div>
						<style jsx>{`
							details summary::-webkit-details-marker {
								display: none;
							}
							.details-arrow {
								transform: scale(0.7) rotateX(0);
								transform-origin: center;
								display: flex;
								transition: 200ms;
								justify-content: center;
								align-items: center;
							}
							details[open] .details-arrow {
								transform: rotateX(180deg) scale(0.7);
							}
						`}</style>
					</section>
				</main>
			</Layout>
		</>
	);
}
export default FAQ;
