import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import JoditText from "./JoditText";
import Select from "react-select";
import Loading from "./Loading";
import { connect } from "react-redux";
import { updateLessonQuestion } from "../../actions";
import { get } from "lodash";

const TestExamQuestionAdd = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState(null);
  const [question_e, setQuestionE] = useState("");
  const [answer, setAnswer] = useState(null);
  const [answer_e, setAnswerE] = useState("");
  const [extra_question, setExtra_question] = useState(null);
  const [extra_question_e, setExtra_questionE] = useState("");
  const [type, setType] = useState({
    value: "single",
    label: t("diagnostics.single"),
  });
  const [loading, setLoading] = useState(false);
  const [category_id, setCategory_id] = useState(0);
  const [category_e, setCategory_e] = useState("");
  const { lesson_id, update_lesson_question_status, is_exam = false } = props;
  useEffect(() => {
    if (update_lesson_question_status.status === "success") {
      setLoading(false);
      setQuestion("");
      setType({ value: "single", label: t("diagnostics.single") });
      props.close();
    }
    if (update_lesson_question_status.status === "error") {
      setLoading(false);
    }
  }, [update_lesson_question_status]);

  const onSubmit = () => {
    if (
      question &&
      (props?.is_diagnostic ? true : answer && extra_question) &&
      (props?.is_diagnostic ? category_id : true)
    ) {
      setLoading(true);
      const data = {
        question: question,
        type: type.value,
        answer: answer,
        extra_question: extra_question,
        score: 0,
        category_id: category_id,
        [is_exam ? "exam_id" : "lesson_id"]: lesson_id,
      };
      props.updateLessonQuestion(data, is_exam);
    } else {
      if (!question) setQuestionE(t("diagnostics.q_e"));
      if (!answer) setAnswerE(t("teacher_test.text_e_answer"));
      if (!extra_question) setExtra_questionE(t("diagnostics.q_e"));
      if (!category_id) setCategory_e(t("create_course.cat"));
    }
  };
  return (
    <>
      <div className="card card--shadow mt-3" style={{ marginBottom: 30 }}>
        <div className="card-body card-body-diagnostics">
          <div className="row">
            <div className="col-12 dig-title">
              <b>{t("diagnostics.add_q")}</b>
              <span
                className="icon icon-close"
                onClick={() => props.close()}
              ></span>
            </div>
            <div className="col-12 mb-1">
              <label>{t("teacher_test.question")}</label>
              <JoditText
                value={question}
                setValue={(v) => setQuestion(v)}
                setValueE={(e) => setQuestionE(e)}
              />
              <div style={{ color: "red" }}>{question_e}</div>
            </div>
            {props?.is_diagnostic ? (
              <></>
            ) : (
              <>
                <div className="col-12 mb-1">
                  <label>{t("teacher_test.text_answer")}</label>
                  <JoditText
                    value={answer}
                    setValue={(v) => setAnswer(v)}
                    setValueE={(e) => setAnswerE(e)}
                  />
                  <div style={{ color: "red" }}>{answer_e}</div>
                </div>
                <div className="col-12 mb-1">
                  <label>{t("diagnostics.question")}</label>
                  <JoditText
                    value={extra_question}
                    setValue={(v) => setExtra_question(v)}
                    setValueE={(e) => setExtra_questionE(e)}
                  />
                  <div style={{ color: "red" }}>{extra_question_e}</div>
                </div>
              </>
            )}
            {props?.is_diagnostic ? (
              <div className="col-12">
                <div className="form-group mb-1">
                  <label>
                    {t("courses.categorys")}
                    <div>
                      <Select
                        value={get(
                          (props?.categorys ?? []).filter(
                            (o) => o.value === category_id
                          ),
                          "0",
                          null
                        )}
                        onChange={(select) => {
                          setCategory_id(select?.value);
                          setCategory_e(false);
                        }}
                        options={props?.categorys ?? []}
                        placeholder={t("courses.categorys")}
                      />
                    </div>
                  </label>
                  <div style={{ color: "red" }}>{category_e}</div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-6">
              <div className="form-group mb-1">
                <label>
                  {t("diagnostics.type")}
                  <div>
                    <Select
                      value={type}
                      onChange={(select) => {
                        setType(select);
                      }}
                      options={[
                        { value: "single", label: t("diagnostics.single") },
                        { value: "multiple", label: t("diagnostics.multiple") },
                        // { value: 'grouping', label: t('diagnostics.grouping') },
                        // { value: 'dragwords', label: t('diagnostics.dragwords') },
                      ]}
                      placeholder={t("diagnostics.type")}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="col-md-6 btn-target">
              {loading ? (
                <Loading />
              ) : (
                <button
                  className="btn btn-primary mb-1 mr-1 btn-sm"
                  onClick={() => onSubmit()}
                >
                  {t("diagnostics.save")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(
  (state) => ({
    update_lesson_question_status: state.update_lesson_question_status,
  }),
  (dispatch) => ({
    updateLessonQuestion: (data, is_exam) =>
      dispatch(updateLessonQuestion(data, false, is_exam)),
  })
)(TestExamQuestionAdd);
