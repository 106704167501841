import React, { useState, useEffect } from 'react';
import { deleteLesson } from "../../actions";
import { connect } from "react-redux";
import $ from "jquery";
import { useTranslation } from 'react-i18next';
import Loading from "./Loading";
window.jQuery = $;

const LessonDelete = (props) => {
    const { t } = useTranslation();
    const { del_lesson, delete_lessons_status } = props;
    const [del_loading, setDelLoading] = useState(false);
    useEffect(() => {
        if(delete_lessons_status.status === 'started'){
            setDelLoading(true);
        }
        if(delete_lessons_status.status === 'success'){
            window.clickOk = false;
            $( "body" ).removeClass( "modal-open" );
            $('#modalDeleteLesson').hide();
            $('#modalDeleteLesson').removeClass('show');
            $('#modalDeleteLesson').attr('aria-hidden', true);
            $('#modalDeleteLesson').removeAttr('aria-modal');
            $('.modal-backdrop').remove();
            $( "body" ).css("padding-right", "");
            $('#modalDeleteLesson').css("padding-right", ""); 
            setDelLoading(false);
        }
        if(delete_lessons_status.status === 'error'){
            setDelLoading(false);
        }
    }, [delete_lessons_status]);

    const LessonDeleteHandle = () => {
        setDelLoading(true);
        props.deleteLesson(del_lesson.id);
    }
    return (<>
        <div className="modal fade" id="modalDeleteLesson" tabindex="-1" role="dialog" aria-labelledby="modalDeleteLessonTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('teacher_lesson.del_lesson')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="icon icon-close"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group mb-3">
                            <label for="">{t('teacher_lesson.del_lesson')}: {del_lesson.name}</label>
                        </div>
                        {del_loading?<Loading/>:<button type="button" className="btn btn-danger w-100"
                                onClick={()=>LessonDeleteHandle()}
                            >{t('teacher_lesson.delete')}</button>}
                    </div>
                </div>
            </div>
        </div>
      </>);
}
export default connect(
    (state) => ({
        delete_lessons_status: state.delete_lessons_status
    }),
    (dispatch) => ({
        deleteLesson: (lesson_id) => dispatch(deleteLesson(lesson_id))
    })
)(LessonDelete);
