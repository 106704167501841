import React, {useState, useEffect} from 'react';
import { getTeacherLesson, createLesson } from "../../actions";
import { connect } from "react-redux";
import Layout from '../Layout';
import DashSideBar from "../sections/DashSideBar";
import Navigation from "../sections/Navigation";
import { Link } from 'react-router-dom';
import NoData from '../sections/NoData';
import Loading from '../sections/Loading';
import LessonDelete from '../sections/LessonDelete';
import { useTranslation } from 'react-i18next';
import CheckRole from '../sections/CheckRole';
import { roles } from "../../helpers/roles";
import _ from 'lodash';

const DashTeacherLesson = (props) => {
    const { t } = useTranslation();
    const course_id =  props.match.params.course_id;
    const section_id =  props.match.params.section_id;
    const { teacher_lessons_status, create_lesson_status, teacher_lesson_data } = props;
    const [loading, setLoading] = useState(true);
    const [create_loading, setCreateLoading] = useState(false);
    const [del_lesson, setDelLesson] = useState({});
    useEffect(() => {
        props.getTeacherLesson(course_id, section_id);
    }, []);

    
    useEffect(() => {
        if(teacher_lessons_status.status === 'started'){
            setLoading(true);
        }
        if(teacher_lessons_status.status === 'success'){
            setLoading(false);
        }
        if(teacher_lessons_status.status === 'error'){
            setLoading(false);
        }
    }, [teacher_lessons_status]);
    
    useEffect(() => {
        if(create_lesson_status.status === 'success'){
            setCreateLoading(false);
        }
        if(create_lesson_status.status === 'error'){
            setCreateLoading(false);
        }
    }, [create_lesson_status]);

    const CreateLesson = type => {
        setCreateLoading(true);
        const data = { 
            course_id: course_id, 
            section_id: section_id, 
            name: type===2?"Test":type===1?"Video":"Text", 
            lesson_type: type, 
            min_percentage: 55 
        };
        props.createLesson(data);
    }

    return (<Layout>
			<CheckRole roles={ [ roles.teacher ] } />
      <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            
                            <Navigation
                                 is_dash={true}
                                 is_btn={true}
                                 active= {t('teacher_lesson.lesson')} 
                                 navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    },
                                    {
                                        'title': t('author_page.course'),
                                        'link': '/dashboard/copyrights'
                                    },
                                     {
                                         'title': t('course_items.items'),
                                         'link': '/dashboard/copyrights/course-items/'+course_id
                                     }
                                ]}
                               
                            />
                            {loading?<Loading/>:<>
                            <div className="dashboard-title">
                                <h5>{t('teacher_lesson.lessons')}</h5>
                            </div>
                            <div className="card card--shadow">
                                <div className="card-body"> 
                                    {!_.get(teacher_lesson_data, 'id', false)? <>
                                        {create_loading?<Loading/>:
                                        <div className="new-lesson__top-buttons">
                                            <label style={{width: '100%'}}>{t('teacher_lesson.add')}</label>
                                            
                                            <ul className="nav tab" id="newLesson" role="tablist">
                                                
                                                    <li>
                                                        <button className="btn btn-outline-light " 
                                                            onClick={()=>CreateLesson(2)}
                                                        >{t('teacher_lesson.test')}</button>
                                                    </li>
                                                    <li>
                                                        <button className="btn btn-outline-light" 
                                                            onClick={()=>CreateLesson(1)}
                                                        >{t('teacher_lesson.video')}</button>
                                                    </li>
                                                    {/* <li>
                                                        <button className="btn btn-outline-light"  
                                                            onClick={()=>CreateLesson(4)}
                                                        >{t('teacher_lesson.webinar')}</button>
                                                    </li> */}
                                                    <li>
                                                        <button className="btn btn-outline-light"  
                                                            onClick={()=>CreateLesson(3)}
                                                        >{t('teacher_lesson.text')}</button>
                                                    </li>
                                                    {/* <li>
                                                        <button className="btn btn-outline-light"  
                                                            onClick={()=>CreateLesson(5)}
                                                        >{t('survey.survey')}</button>
                                                    </li> */}
                                            
                                            </ul>
                                        </div> }
                                        <NoData text={t('teacher_lesson.no_data')} />
                                    </>:<>
                                        <div style={{display: 'flex'}}>
                                            <b>{teacher_lesson_data.name}</b>
                                            <span style={{marginLeft: 'auto'}}>
                                                <span className="button" 
                                                    style={{
														color: '#898DA6',
                                                        margin: '5px',
                                                        cursor: 'pointer',
                                                        float: 'right',
                                                        background: 'rgba(137, 141, 166, 0.1)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: 32,
                                                        height: 32,
                                                        borderRadius: 8,
													}}
                                                        data-toggle="modal" data-target="#modalDeleteLesson"
                                                        onClick={()=>setDelLesson(teacher_lesson_data)}
                                                    ><span className="icon icon-remove"></span>
                                                </span>
                                                <Link to={`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson/${teacher_lesson_data?.lesson_type_id===1?'video':teacher_lesson_data?.lesson_type_id===2?'test':teacher_lesson_data?.lesson_type_id===3?'text':teacher_lesson_data?.lesson_type_id===5?'survey':''}`}
                                                    className="button"
                                                    style={{
														color: '#898DA6',
                                                        margin: '5px',
                                                        cursor: 'pointer',
                                                        float: 'right',
                                                        background: 'rgba(137, 141, 166, 0.1)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: 32,
                                                        height: 32,
                                                        borderRadius: 8,
													}}
                                                    >
                                                    <span className="icon icon-edit"></span>
                                                </Link>
                                                {/* {teacher_lesson_data?.lesson_type_id===1?
                                                <Link to={`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson/video/questions`}
                                                    className="button"
                                                    style={{
														color: '#898DA6',
                                                        margin: '5px',
                                                        cursor: 'pointer',
                                                        float: 'right',
                                                        background: 'rgba(137, 141, 166, 0.1)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: 32,
                                                        height: 32,
                                                        borderRadius: 8,
													}}
                                                    >
                                                    <span className="icon icon-question"></span>
                                                </Link>:null} */}
                                                {teacher_lesson_data?.lesson_type_id===5?
                                                <Link to={`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson/survey/${_.get(teacher_lesson_data, 'survey.id', 0)}/result`}
                                                    className="button"
                                                    style={{
														color: '#898DA6',
                                                        margin: '5px',
                                                        cursor: 'pointer',
                                                        float: 'right',
                                                        background: 'rgba(137, 141, 166, 0.1)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: 32,
                                                        height: 32,
                                                        borderRadius: 8,
													}}
                                                    >
                                                    <span className="icon icon-grid"></span>
                                                </Link>:null}
                                            </span>
                                        </div>
                                    </>}
                                </div>
                            </div> </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
    <LessonDelete del_lesson={del_lesson}/>
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherLesson: (_course_id, _section_id) => dispatch(getTeacherLesson(_course_id, _section_id)),
        createLesson: (data) => dispatch(createLesson(data))
    })
)(DashTeacherLesson);