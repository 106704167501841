import React,{useState, useEffect} from 'react';
import { connect } from "react-redux";
import { getTeacherLesson } from "../../actions";
import Layout from '../Layout';
import { useTranslation } from 'react-i18next';
import CheckRole from '../sections/CheckRole';
import DashSideBar from '../sections/DashSideBar';
import Navigation from '../sections/Navigation';
import { roles } from "../../helpers/roles";
// import Creator from '../sections/survey-creator/Creator';
import histoty from '../../history';
import { getInstance } from '../../helpers/httpClient';
import Loading from '../sections/Loading';
import _ from 'lodash';

const DashTeacherCourseSurvey = (props) => {
	const { t } = useTranslation();
    const course_id =  props.match.params.course_id;
    const section_id =  props.match.params.section_id;
    const { teacher_lessons_status, teacher_lesson_data } = props;
    const [loading, setLoading] = useState(true);
	const [survey, setSurvey] = useState({});

    useEffect(() => {
        props.getTeacherLesson(course_id, section_id);
    }, []);

    useEffect(() => {
        if(teacher_lessons_status.status === 'started'){
            setLoading(true);
        }
        if(teacher_lessons_status.status === 'success'){
            setLoading(false);
			setSurvey(_.get(teacher_lesson_data, 'survey', {}));
            if(!teacher_lesson_data.id){
                histoty.push(`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson`);
            }
        }
        if(teacher_lessons_status.status === 'error'){
            setLoading(false);
        }
    }, [teacher_lessons_status, teacher_lesson_data]);

    
	const setSurveyJson = (d, auto_save = false) => {
		console.log(d);
		getInstance()
			.post('/api/v1/survey/update-survey', {...d, lesson_id: _.get(teacher_lesson_data, 'id', '')})
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
                        if(!auto_save)histoty.push(`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson`);
					} catch {
						// setPositionTypes([]);
					}
				}
			})
			.catch(error => {  } );
	}
    return(
        <Layout>
			<CheckRole roles={[ roles.teacher ]} />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<DashSideBar />
							<div class="col-md-12 col-lg-9 col-xl-10">
								<div class="dashboard-right" style={{paddingBottom: 0}}>
									<Navigation is_dash={true} is_btn={true} active={t('survey.survey')}
                                        navs={[
											{
												'title': t('navigation.account'),
												'link': '/dashboard'
											},
											{
												'title': t('author_page.course'),
												'link': '/dashboard/copyrights'
											},
											{
												'title': t('course_items.items'),
												'link': '/dashboard/copyrights/course-items/'+course_id
											},
											{
												'title': t('teacher_lesson.lesson'),
												'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson'
											} 
										]} 
									/>
									<div class="dashboard-title dashboard-title--has-buttons">
										<h5>{t('survey.questions')}</h5>
										
                                    </div>
									{/* <Creator survey_data={survey}
										setSurveyJson={(d, auto_save)=>setSurveyJson(d, auto_save)}
										loading={loading}
									/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherLesson: (_course_id, _section_id) => dispatch(getTeacherLesson(_course_id, _section_id)),
        
    })
)(DashTeacherCourseSurvey);