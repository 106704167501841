import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import JoditText from './JoditText';
import JoditTextShow from './JoditTextShow';
import Loading from "./Loading";
import { connect } from 'react-redux';
import { updateLessonAnswer, deleteAnswer } from '../../actions';

const VideoAnswerChange = props =>{
	const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [answer, setAnswer] = useState('');
    const [answer_e, setAnswerE] = useState('');
    const [score, setScore] = useState('');
    const [score_e, setScoreE] = useState('');
    const [loading, setLoading] = useState(false);
    const {answer_data} = props;
    const {update_lesson_answer_status, delete_answer_status, index_a} = props;

    useEffect(()=>{
        if(update_lesson_answer_status.status === 'success'){
            setLoading(false);
            setEdit(false);
        }
        if(update_lesson_answer_status.status === 'error'){
            setLoading(false);
            // setEdit(false);
        }
    },[update_lesson_answer_status]);

    useEffect(()=>{
        if(delete_answer_status.status === 'success'){
            setLoading(false);
            setEdit(false);
        }
        if(delete_answer_status.status === 'error'){
            setLoading(false);
            // setEdit(false);
        }
    },[delete_answer_status]);
    useEffect(()=>{
        setAnswer(answer_data.answer);
        setScore(answer_data.score);
    },[answer_data]);

    const onSubmit = () => {
        if(answer){
            setLoading(true);
            const data ={
                ...answer_data,
                answer: answer,
                score: score?score:0
            }
            props.updateLessonAnswer(data);
        }else{
            setAnswerE( t('diagnostics.answer_e') );
        }
    }

    return(<>
        <div className="card card--shadow ml-5 mt-1 mb-0">
            <div className="card-body card-body-diagnostics">
                <div className="row">
                    <div className="col-12 dig-title">
                        <div style={{fontSize: 14}}> { index_a +'-' + t('diagnostics.answer') } </div>
                        {edit?
                            <span className="icon icon-close" onClick={()=>setEdit(false)}></span>
                        :
                            <span className="icon icon-edit dig-answer-edit" onClick={()=>setEdit(true)}></span>
                        }
                    </div>
                    {edit?<>
                    <div className="col-12 mb-1">
                        <label>
                        { t('diagnostics.answer_t') }
                        </label>
                        <JoditText 
                            value = {answer}
                            setValue = { v => setAnswer(v)}
                            setValueE = { e => setAnswerE(e)}
                        />
                        <div style={{color: 'red'}}>{answer_e}</div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-1">
                            <label for=""> { t('diagnostics.score') } 
                                <input type="number" className="form-control"  style={{width: 150}} 
                                    value={score}
                                    onChange={(event)=>{
                                        setScore(event.target.value);
                                        setScoreE('');
                                    }}
                                />
                                <div style={{color: 'red'}}>{score_e}</div>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6 btn-target">
                        {loading?<Loading/>:<>
                            <button className="btn btn-primary mb-1 mr-1 btn-sm"
                                onClick={()=>onSubmit()}
                            >
                                { t('diagnostics.save') }
                            </button>
                            <button className="btn btn-lighter mb-1 btn-sm"
                                onClick={()=>{setLoading(true); props.deleteAnswer({id: answer_data.id, question_id: answer_data.question_id})}}
                            >
                                { t('diagnostics.delete') }
                            </button>
                        </>}
                    </div>
                     </>: <>
                        <div className="col-12 col-md-9 col-lg-10 dig-title" >
                            <div className="test-items-target"  style={{width: '100%'}}> <JoditTextShow text={answer} /> </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 dig-title pl-0" >
                            <div className="dig-items-terget">
                                <div className="test-items-target mr-2 mb-2 dig-item">  {score}  {t('diagnostics.ball')}</div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    </>)
}
export default connect(
	state => ({
		update_lesson_answer_status: state.update_lesson_answer_status,
        delete_answer_status: state.delete_answer_status
	}),
	dispatch => ({
		updateLessonAnswer: (data) => dispatch(updateLessonAnswer(data, true)),
        deleteAnswer: (data) => dispatch(deleteAnswer(data, true))
	}),
)(VideoAnswerChange);
