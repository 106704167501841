 
import { useState } from "react";
import Layout from "../Layout"
import DashSideBar from "../sections/DashSideBar"
import Navigation from "../sections/Navigation"
import { useTranslation } from 'react-i18next';
import { getInstance } from "../../helpers/httpClient";
import Loading from "../sections/Loading";
import {get} from 'lodash';
import history from '../../history';


const ExamCreate = () => {
	const { t } = useTranslation();
    const [obj, setObj] = useState({status: 0});
    const [err, setErr] = useState({}); 
    const [loading, setLoading] = useState(false); 
    const onSubmit = (event) => {
		event.preventDefault();
        setLoading(true)
        let errr = {}, t = true; 

        if(!obj.name){ errr = {...errr, name: true}; t=false; }
        if(!obj.desc){ errr = {...errr, desc: true}; t=false; }
        if(!obj.start_date){ errr = {...errr, start_date: true}; t=false; }
        if(!obj.end_date){ errr = {...errr, end_date: true}; t=false; } 
        if(t){
            getInstance().post('/api/v1/manager/exams/create', {...obj, group_id: 1}  )
            .then(res=>{
                setObj(get(res, 'data.data'));
                history.push('/dashboard/exams/edit/'+get(res, 'data.data.id', 0))

            }).catch(()=>{}).finally(()=>{setLoading(false)})
        }else{setErr(errr); setLoading(false)}

    }
    return(
      <Layout>
        <main className="main">
          <section className="section section-dashboard py-0">
            <div className="container">
              <div className="row">
                <DashSideBar />
                <div className="col-md-12 col-lg-9 col-xl-10">
                  <div className="dashboard-right">
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      active={t('exams.add')}
                      navs={[
                        {
                          title: t('navigation.account'),
                          link: '/dashboard',
                        },
                        {
                          title: t('exams.title'),
                          link: '/dashboard/exams',
                        },
                      ]}
                    />
                    <div className="dashboard-title">
                        <h5 className="mb-3">{t('exams.add')}</h5>                      
                    </div>
                    <div className="card card--shadow">
                        <form onSubmit={onSubmit} className="form-group m-0 p-2">
                            <label>* {t('exams.name')}</label>
                            <input type='text' className="form-control" name="name" 
                                value={get(obj, 'name', '')}
                                onChange={e=>{setObj({...obj, name: e.target.value}); setErr({...err, name: false})}}
                                placeholder={t('exams.name_e')} />
                            {err?.name?<div className="input_error">{t('exams.name_e')}</div>:''}
                            <div className="setting__buttons form-group my-2" style={{alignItems: 'center', justifyContent: 'left'}}>
                                <label className="switch mx-2">
                                    <input type="checkbox" checked={get(obj, 'status', 0)?true:false}
                                        onChange={(e)=>{ 
                                            setObj({...obj, status: get(obj, 'status', 0)?0:1});  
                                            setErr({...err, status: false}) 
                                        }}
                                    />
                                    {/* {_.get(result, 'value', '')?null:
                                    <style jsx>{`
                                        .switch .slider:before{
                                            left: 15px
                                        }
                                    `}</style>} */}
                                    <span className="slider round"></span>
                                </label>
                                { t('exams.status') }
                            </div>
                            <label>* {t('exams.start_date')}</label>
                            <input type='datetime-local' className="form-control" name="start_date"
                                onChange={e=>{setObj({...obj, start_date: e.target.value}); setErr({...err, start_date: false})}}
                                value={get(obj, 'start_date', '')}  />
                            {err?.start_date?<div className="input_error">{t('exams.start_date_e')}</div>:''}
                            <label>* {t('exams.end_date')}</label>
                            <input type='datetime-local' className="form-control" name="end_date" 
                                onChange={e=>{setObj({...obj, end_date: e.target.value}); setErr({...err, end_date: false})}}
                                value={get(obj, 'end_date', '')} />
                            {err?.end_date?<div className="input_error">{t('exams.end_date_e')}</div>:''}
                            <label>* {t('exams.desc')}</label>
                            <input type='text' className="form-control" name="name" 
                                value={get(obj, 'desc', '')}
                                onChange={e=>{setObj({...obj, desc: e.target.value}); setErr({...err, desc: false})}}
                                placeholder={t('exams.desc_e')} />
                            {err?.desc?<div className="input_error">{t('exams.desc_e')}</div>:''}
                            <div className="mt-2">
                                {loading?<Loading size={5}/>:
                                <input className="btn btn-primary btn-sm" value={t('exams.save')} type='submit' />}
                            </div>
                        </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
}
export default ExamCreate;