import React from "react";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import { HighchartsChart, withHighcharts } from "react-jsx-highcharts";
import HCSoldGauge from "highcharts/modules/solid-gauge";
import * as ChartModuleMore from "highcharts/highcharts-more.js";
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);

const ChartSpider = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  let names = [];
  let percents = [];
  data.map((cat) => {
    names.push(cat.name);
    percents.push(cat.persent);
  });
  const chartxaxis = {
    categories: names,
    tickmarkPlacement: "on",
    lineWidth: 0,
  };
  const chartseries = [
    {
      name: t("test_result.diagnostics-results"),
      data: percents,
      pointPlacement: "on",
      color: "#2962FF",
    },
  ];
  const plotOptions = {
    solidgauge: {
      dataLabels: {
        y: 5,
        borderWidth: 0,
        useHTML: true,
      },
    },
  };
  const chartype = {
    polar: true,
    type: "line",
  };
  //   const chartitle = {
  //       text: 'Budget vs spending',
  //       x: -80
  //   }
  const chartpane = {
    size: "80%",
  };
  const chartyaxis = {
    gridLineInterpolation: "polygon",
    lineWidth: 0,
    min: 0,
  };
  const chartooltip = {
    shared: true,
    pointFormat:
      '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}%</b><br/>',
  };
  const chartlegend = {
    accessibility: {
      enabled: false,
    },
    keyboardNavigation: {
      enabled: true,
    },
  };
  return (
    <div style={{ width: "100%" }}>
      <HighchartsChart
        polar
        plotOptions={plotOptions}
        chart={chartype}
        pane={chartpane}
        xAxis={chartxaxis}
        yAxis={chartyaxis}
        tooltip={chartooltip}
        legend={chartlegend}
        series={chartseries}
      ></HighchartsChart>
    </div>
  );
};
export default withHighcharts(ChartSpider, Highcharts);
