import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { set_message } from '../actions';
import AboutPage from '../componets/profile/AboutPage';
import ConfidentialityPage from '../componets/profile/ConfidentialityPage';
import HelpPage from '../componets/profile/HelpPage';
import AuthOldToken from '../componets/profile/AuthOldToken'; 
import DashCopyrightCreate from '../componets/teacher/DashCopyrightCreate';
import DashCopyrights from '../componets/teacher/DashCopyrights';
import DashCopyrightsWebinars from '../componets/teacher/DashCopyrightsWebinars';
import DashCopyrightUpdate from '../componets/teacher/DashCopyrightUpdate';
import DashCopyrightWebinarCreate from '../componets/teacher/DashCopyrightWebinarCreate';
import DashCopyrightWebinarUpdate from '../componets/teacher/DashCopyrightWebinarUpdate';
import DashCourseItems from '../componets/teacher/DashCourseItems'; 
import DashNotificationPage from '../componets/profile/DashNotificationPage'; 
import DashProfilePage from '../componets/profile/DashProfilePage'; 
import DashSettingsPage from '../componets/profile/DashSettingsPage';
import DashTeacherCourseSurvey from '../componets/teacher/DashTeacherCourseSurvey';
import DashTeacherLesson from '../componets/teacher/DashTeacherLesson';
import DashTeacherLessonTests from '../componets/teacher/DashTeacherLessonTests';
import DashTeacherLessonText from '../componets/teacher/DashTeacherLessonText';
import DashTeacherLessonVideo from '../componets/teacher/DashTeacherLessonVideo';
import DashTeacherLessonVideoQuestionCreate from '../componets/teacher/DashTeacherLessonVideoQuestionCreate';
import DashTeacherLessonVideoQuestions from '../componets/teacher/DashTeacherLessonVideoQuestions';
import DashTeacherLessonVideoQuestionUpdate from '../componets/teacher/DashTeacherLessonVideoQuestionUpdate';
import DashTeacherLessonWebinar from '../componets/teacher/DashTeacherLessonWebinar'; 
import ExamCreate from '../componets/exams/ExamCreate';
import ExamEdite from '../componets/exams/ExamEdite';
import ExamsPage from '../componets/exams/ExamsPage';
import ExamsTests from '../componets/exams/ExamsTests';
import FAQ from '../componets/profile/FAQ';
import GroupAddUser from '../componets/groups/GroupAddUser';
import GroupsCreate from '../componets/groups/GroupCreate'; 
import GroupEdite from '../componets/groups/GroupEdite';
import GroupsPage from '../componets/groups/GroupsPage';
// import HomePage from '../componets/HomePage'; 
import NotFoundPage from '../componets/profile/NotFoundPage'; 
import TeacherLessonSurveysResult from '../componets/teacher/TeacherLessonSurveysResult'; 
import Users from '../componets/manager/Users';
import UserCourses from '../componets/manager/UserCourses';
import Courses from '../componets/manager/Courses';
import CourseUsers from '../componets/manager/CourseUsers';
import Diadnostics from '../componets/exams/Diadnostics';
import DiadnosticStatistic from '../componets/exams/DiadnosticStatistic';

const Routes = props => {
	const { t } = useTranslation();
	const { set_message } = props;
	useEffect(() => {
		window.addEventListener('offline', () =>
			set_message({ text: t('connection.error'), type: 'w' })
		);
		window.addEventListener('online', () =>
			set_message({ text: t('connection.success'), type: 's' })
		);
	}, []);
	return (
		<Switch>
			<Redirect exact from="/sign-in" to="/" /> 
			<Route path="/" exact component={AuthOldToken} />
			<Route path="/old-token" exact component={AuthOldToken} />
			<Redirect exact from="/faq" to="/faq/1" />
			<Route exact path="/faq/:page" component={FAQ} />
			<Redirect exact from="/dashboard" to="/dashboard/profile" />
			<Route path="/dashboard/profile" exact component={DashProfilePage} />
			<Route path="/dashboard/settings" exact component={DashSettingsPage} />
			<Redirect exact from="/dashboard/notification" to="/dashboard/notification/1" />
			<Route path="/dashboard/notification/:page" exact component={DashNotificationPage} />
			<Route path="/dashboard/exams/create" exact component={ExamCreate} />
			<Route path="/dashboard/exams/edit/:id" exact component={ExamEdite} />
			{/* <Route path="/dashboard/exams/edit/:id/add-users" exact component={GroupAddUser} /> */}
			<Redirect exact from="/dashboard/exams" to="/dashboard/exams/1" />
			<Route path="/dashboard/exams/:page" exact component={ExamsPage} />
			<Route path="/dashboard/exams/:id/tests" exact component={ExamsTests} />
			<Redirect exact from="/dashboard/copyrights" to="/dashboard/copyrights/list/1" />
			<Redirect exact from="/dashboard/copyrights/list" to="/dashboard/copyrights/list/1" />
			<Route path="/dashboard/copyrights/list/:page" exact component={DashCopyrights} />
			<Route path="/dashboard/copyrights/course-create" exact component={DashCopyrightCreate} />
			<Redirect exact from="/dashboard/copyrights/course-update" to="/dashboard/copyrights/list/1?status=1" />
			<Route path="/dashboard/copyrights/course-update/:id" exact component={DashCopyrightUpdate} />
			<Redirect exact from="/dashboard/copyrights/course-items" to="/dashboard/copyrights/list/1?status=1" />
			<Route path="/dashboard/copyrights/course-items/:id" exact component={DashCourseItems} />
			<Redirect exact
				from="/dashboard/copyrights/course-items/:course_id/section"
				to="/dashboard/copyrights/course-items/:id"
			/>
			<Redirect exact
				from="/dashboard/copyrights/course-items/:course_id/section/:section_id"
				to="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson"
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson"
				exact component={DashTeacherLesson}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/text"
				exact component={DashTeacherLessonText}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/test"
				exact component={DashTeacherLessonTests}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video"
				exact component={DashTeacherLessonVideo}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions"
				exact component={DashTeacherLessonVideoQuestions}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions/create"
				exact component={DashTeacherLessonVideoQuestionCreate}
			/>
			<Redirect exact
				from="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions/update"
				to="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions/create"
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions/update/:time"
				exact component={DashTeacherLessonVideoQuestionUpdate}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/webinar"
				exact component={DashTeacherLessonWebinar}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/survey"
				exact component={DashTeacherCourseSurvey}
			/>
			<Route path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/survey/:survey_id/result"
				exact component={TeacherLessonSurveysResult}
			/>
			<Redirect exact from="/dashboard/copyrights/webinars/list" to="/dashboard/copyrights/webinars/list/1" />
			<Route path="/dashboard/copyrights/webinars/list/:page" exact component={DashCopyrightsWebinars} />
			<Route path="/dashboard/copyrights/webinar-create" exact component={DashCopyrightWebinarCreate} />
			<Route path="/dashboard/copyrights/webinar-update/:id" exact component={DashCopyrightWebinarUpdate} />
			<Route exact path="/about" component={AboutPage} />
			<Route exact path="/confidentiality" component={ConfidentialityPage} />
			<Route exact path="/help" component={HelpPage} />
			<Route path="/dashboard/groups/create" exact component={GroupsCreate} />
			<Route path="/dashboard/groups/edit/:id" exact component={GroupEdite} />
			<Route path="/dashboard/groups/edit/:id/add-users" exact component={GroupAddUser} />
			<Redirect exact from="/dashboard/groups" to="/dashboard/groups/1" />
			<Route path="/dashboard/groups/:page" exact component={GroupsPage} />
			<Redirect exact from="/dashboard/users" to="/dashboard/users/1" />
			<Route path="/dashboard/users/:page" exact component={Users} />
			<Route path="/dashboard/users/courses/:page" exact component={UserCourses} />
			<Redirect exact from="/dashboard/courses" to="/dashboard/courses/1" />
			<Route path="/dashboard/courses/:page" exact component={Courses} />
			<Route path="/dashboard/courses/users/:page" exact component={CourseUsers} />
			<Route path="/dashboard/diagnostics" exact component={Diadnostics} />
			<Route path="/dashboard/diagnostic-statistic" exact component={DiadnosticStatistic} />

			<Route component={NotFoundPage} />
		</Switch>
	);
};
export default connect(
	state => ({}),
	dispatch => ({
		set_message: e => dispatch(set_message(e)),
	})
)(Routes);
