import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteFile, updateTeacherWebinar } from '../../actions';
import { FILE_URL, IMG_URL } from '../../helpers/api';
import Loading from './Loading';

const TeacherWebinarCreateOrUpdate = props => {
	const { webinar_change_data, is_create, upadte_teacher_webinar_status, deleteFile } = props;
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	// const [score_e, setScoreE] = useState('');
	const [name_e, setNameE] = useState('');
	const [content_e, setContentE] = useState('');
	const [whole_e, setWholeE] = useState('');
	const [minut_e, setMinutE] = useState('');
	const [date_e, setDateE] = useState('');
	const [time_e, setTimeE] = useState('');
	const [img, setImg] = useState('');
	const [hourse, setHourse] = useState(0);
	const [minut, setMinut] = useState(0);
	const [date, setDate] = useState(new Date());
	const [time, setTime] = useState(new Date());
	const [is_date, setIsDate] = useState(false);
	const [presentation, setPresentation] = useState('');

	useEffect(() => {
		if (upadte_teacher_webinar_status.status === 'success') {
			window.scrollTo(0, 0);
			setLoading(false);
		}
		if (upadte_teacher_webinar_status.status === 'error') {
			window.scrollTo(0, 0);
			setLoading(false);
		}
	}, [upadte_teacher_webinar_status]);
	useEffect(() => {
		if (_.get(webinar_change_data, 'image')) {
			setImg(IMG_URL + _.get(webinar_change_data, 'image'));
		}
		setPresentation(_.get(webinar_change_data, 'presentation'));
		setHourse(parseInt(_.get(webinar_change_data, 'duration', 0) / 60));
		setMinut(parseInt(_.get(webinar_change_data, 'duration', 0)) % 60);
		setDate(_.get(webinar_change_data, 'startdate') ? _.get(webinar_change_data, 'startdate').slice(0, 10) : null);
		setTime(_.get(webinar_change_data, 'startdate') ? _.get(webinar_change_data, 'startdate').slice(11) : null);
		setIsDate(_.get(webinar_change_data, 'startdate') ? true : false);
	}, [webinar_change_data]);
	const onChangeHourse = value => {
		const v = parseInt(value);
		if (Number.isInteger(v) && v >= 0) {
			if (v > 5) {
				setHourse(5);
			} else {
				setHourse(v);
			}
		} else {
			setHourse('');
		}
	};
	const onChangeMinut = value => {
		const v = parseInt(value);
		if (Number.isInteger(v) && v >= 0) {
			if (v > 59) {
				setMinut(59);
			} else {
				setMinut(v);
			}
		} else {
			setMinut('');
		}
	};
	const onChangeDate = value => {
		const v = new Date(value);
		const now = new Date();
		v.setHours(0);
		v.setMinutes(0);
		v.setSeconds(0);
		v.setMilliseconds(0);
		now.setHours(0);
		now.setMinutes(0);
		now.setSeconds(0);
		now.setMilliseconds(0);
		setDate(value);
		if (v.getFullYear() < 1000 || v >= now) {
			setDateE('');
			setIsDate(true);
			onChangeTime(time, value);
			return true;
		} else {
			if (v.getFullYear() && v.getMonth() && v.getDate()) {
				setDateE(t('create_webinar.date_e1'));
			}
			setIsDate(false);
			return false;
		}
	};
	const onChangeTime = (value, date_a = date) => {
		setTime(value);
		const v = new Date('2020-01-01 ' + value);
		const now = new Date();
		const n = new Date();
		const d = new Date(date_a);
		d.setHours(0);
		d.setMinutes(0);
		d.setSeconds(0);
		d.setMilliseconds(0);
		now.setHours(0);
		now.setMinutes(0);
		now.setSeconds(0);
		now.setMilliseconds(0);
		v.setFullYear(2021);
		v.setMonth(0);
		v.setDate(1);
		n.setFullYear(2021);
		n.setMonth(0);
		n.setDate(1);
		if (
			d.getFullYear() === now.getFullYear() &&
			d.getMonth() === now.getMonth() &&
			d.getDate() === now.getDate() &&
			v < n
		) {
			setTimeE(t('create_webinar.time_e1'));
			return false;
		} else {
			setTimeE('');
			return true;
		}
	};
	const changeImages = event => {
		const images = event.target.files;
		if (images[0]) {
			const imgUrl = URL.createObjectURL(images[0]);
			setImg(imgUrl);
		}
	};
	const UpdateLesson = event => {
		event.preventDefault();
		setLoading(true);
		let formData = new FormData(event.target);
		let name = formData.get('title');
		let score = formData.get('score');
		let content = formData.get('description');
		let whole = formData.get('whole');
		let minut = formData.get('minut');
		let time = formData.get('time');
		let date = formData.get('date');

		// console.log(time, date);

		let n = true,
			c = true,
			s = true,
			w = true,
			d = true,
			tt = true,
			dc = true,
			tc = true,
			m = true;
		if (!time) {
			tt = false;
			setTimeE(t('create_webinar.time_e'));
		}
		if (!date) {
			d = false;
			setDateE(t('create_webinar.date_e'));
		}
		dc = onChangeDate(date);
		tc = onChangeTime(time);
		if (tt && d && tc && dc) {
			const startdate = date + ' ' + time;
			formData.append('startdate', startdate);
		}
		if (name === '') {
			n = false;
			setNameE(t('create_webinar.name_e'));
		}
		// if(score === ''){
		//     s=false;
		//     setScoreE('Введите максимальные баллы');
		// }
		if (content === '') {
			c = false;
			setContentE(t('create_webinar.content_e'));
		}
		if (!whole || whole < 0) {
			w = false;
			whole = 0;
			setWholeE(t('create_webinar.whole_e'));
		} else {
			setWholeE('');
		}
		if (!minut || minut < 0 || minut > 59) {
			m = false;
			minut = 0;
			setMinutE(t('create_webinar.minut_e'));
		} else {
			setMinutE('');
		}
		if (m && w) {
			const duration = parseInt(whole) * 60 + parseInt(minut);
			formData.append('duration', duration);
		}

		if (n && c && s && m && w && d && tt && tc && dc) {
			props.updateTeacherWebinar(formData);
		} else {
			setLoading(false);
		}
	};
	const deleteImage = field => {
		if (field === 'image') {
			deleteFile({ id: _.get(webinar_change_data, 'id'), type: 'webinar', field: ['image'] });
			setImg('');
		} else if (field === 'presentation') {
			deleteFile({ id: _.get(webinar_change_data, 'id'), type: 'webinar', field: ['presentation'] });
			setPresentation('');
		}
	};
	return (
		<>
			<div className="card card--shadov">
				<div className="card-body">
					<form onSubmit={UpdateLesson} noValidate>
						<div className="tab-content" id="newLessonContent">
							<div id="new-lesson-1-3">
								<div className="row row-min-8 new-lesson__tab-content">
									<div className="col ">
										{is_create ? null : (
											<input type="hidden" name="id" value={_.get(webinar_change_data, 'id')} />
										)}
										<div className="form-group">
											<label for=""> {t('create_webinar.name')} </label>
											<input
												type="text"
												className="form-control"
												name="title"
												defaultValue={_.get(webinar_change_data, 'title')}
												onClick={() => setNameE('')}
											/>
											<span style={{ color: 'red' }}>{name_e}</span>
										</div>
										{/* <div className="form-group">
                            <label for="">Максимальный балл</label>
                            <input type="number" className="form-control " style={{width: 150}} 
                                defaultValue={_.get(webinar_change_data, 'score')}
                                name="score"
                                onClick={()=>setScoreE('')}
                            />
                            <span style={{color: 'red'}}>{score_e}</span>
                        </div> */}
										<div className="form-group">
											<label for=""> {t('create_webinar.description')} </label>
											<textarea
												className="form-control"
												rows="8"
												name="description"
												defaultValue={_.get(webinar_change_data, 'description')}
												onClick={() => setContentE('')}></textarea>
											<span style={{ color: 'red' }}>{content_e}</span>
										</div>

										<div className="fit-content-form-group">
											<div className="form-group">
												<label for=""> {t('create_webinar.whole')} </label>
												<div className="row  hours-target">
													<div className="col-6 col-xl-4">
														<input
															type="text"
															className="form-control "
															name="whole"
															onClick={() => setWholeE('')}
															value={hourse}
															onChange={event => onChangeHourse(event.target.value)}
														/>{' '}
														{t('create_webinar.hourse')}
														<span style={{ color: 'red' }}>{whole_e}</span>
													</div>
													<div className="col-6 col-xl-4">
														<input
															type="text"
															className="form-control "
															name="minut"
															onClick={() => setMinutE('')}
															onChange={event =>
																onChangeMinut(parseInt(event.target.value))
															}
															value={minut}
														/>{' '}
														{t('create_webinar.minut')}
														<span style={{ color: 'red' }}>{minut_e}</span>
													</div>
												</div>
											</div>
										</div>
										<div className="fit-content-form-group mt-1">
											<div className="row">
												<div className="col-12 col-xl-4">
													<div className="form-group mb-0">
														<label for=""> {t('create_webinar.is_record_l')} </label>
														<label className="checkbox-custom lg text-secondary mb-3">
															<input
																type="checkbox"
																name="is_record"
																value="1"
																defaultChecked={_.get(webinar_change_data, 'is_record')}
															/>
															<p> {t('create_webinar.is_record')} </p>
															<span className="checkmark"></span>
														</label>
													</div>
												</div>
												<div className="col-12 col-xl-4">
													<div className="form-group mb-0">
														<label for=""> {t('create_webinar.is_auto_record_l')} </label>
														<label className="checkbox-custom lg text-secondary mb-3">
															<input
																type="checkbox"
																value="1"
																name="is_auto_record"
																defaultChecked={_.get(
																	webinar_change_data,
																	'is_auto_record',
																)}
															/>
															<p> {t('create_webinar.is_auto_record')} </p>
															<span className="checkmark"></span>
														</label>
													</div>
												</div>
											</div>
										</div>

										<div className="fit-content-form-group mt-1">
											<div className="row">
												<div className="col-12">
													<div className="form-group mb-0">
														<label for=""> {t('create_webinar.is_webcams_l')} </label>
														<label className="checkbox-custom lg text-secondary mb-3">
															<input
																type="checkbox"
																value="1"
																name="is_webcams"
																defaultChecked={_.get(
																	webinar_change_data,
																	'is_webcams',
																)}
															/>
															<p> {t('create_webinar.is_webcams')} </p>
															<span className="checkmark"></span>
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="form-group mb-0  mt-1">
											<label for=""> {t('create_webinar.image')} (.jpg, .jpeg, .png)</label>
											<div className="custom-file">
												<input
													type="file"
													style={{ height: 40, width: 120 }}
													name="image"
													accept=".jpg, .jpeg, .png"
													onChange={event => changeImages(event)}
												/>
												<div>{t('create_webinar.upload')}</div>
												{img ? (
													<button
														type="button"
														className="btn btn-sm"
														onClick={() => deleteImage('image')}
														style={{ background: 'red', marginLeft: 10, color: 'white' }}>
														<span
															className="icon icon-remove"
															style={{ marginRight: 5 }}></span>
														<span>{t('create_webinar.delete-webinar')}</span>
													</button>
												) : null}
											</div>
											<img
												style={{
													height: 150,
													width: 150,
													margin: 10,
													objectFit: 'cover',
													borderRadius: 10,
												}}
												src={img}
												onError={e => {
													e.target.onerror = null;
													e.target.src = '/images/courses/no-image.jpg';
												}}
												alt="Course"
											/>
										</div>
										<div className="form-group mb-0  mt-1">
											<label for="">
												{t('create_webinar.presentation')} (.pdf, .doc, .docx, .ppt, .pptx)
											</label>
											<div className="custom-file">
												<input
													type="file"
													style={{ height: 40, width: 120 }}
													name="presentation"
													accept=".pdf, .doc, .docx, .ppt, .pptx"
												/>
												<div>{t('create_webinar.upload')}</div>
												{presentation ? (
													<button
														type="button"
														className="btn btn-sm"
														onClick={() => deleteImage('presentation')}
														style={{ background: 'red', marginLeft: 10, color: 'white' }}>
														<span
															className="icon icon-remove"
															style={{ marginRight: 5 }}></span>
														<span>{t('create_webinar.delete-presentation')}</span>
													</button>
												) : null}
											</div>
											{presentation ? (
												<a
													href={FILE_URL + _.get(webinar_change_data, 'presentation')}
													className="btn btn-primary ml-auto mt-2"
													target="blank">
													{t('create_webinar.presentation_view')}
												</a>
											) : null}
										</div>
										<div className="fit-content-form-group  mt-3">
											<div className="row">
												<div className="col-6 col-xl-4">
													<div className="form-group mb-0">
														<label for=""> {t('create_webinar.date')} </label>
														<input
															type="date"
															className="form-control w-100"
															name="date"
															value={date}
															onClick={() => setDateE('')}
															onChange={event => onChangeDate(event.target.value)}
														/>
														<span style={{ color: 'red' }}>{date_e}</span>
													</div>
												</div>
												{is_date ? (
													<div className="col-6 col-xl-4">
														<div className="form-group mb-0">
															<label for=""> {t('create_webinar.time')} </label>
															<input
																type="time"
																className="form-control w-100"
																step="1"
																name="time"
																onClick={() => setTimeE('')}
																value={time}
																onChange={event => onChangeTime(event.target.value)}
															/>
															<span style={{ color: 'red' }}>{time_e}</span>
														</div>
													</div>
												) : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="dashboard-buttons mt-5">
							{loading ? (
								<Loading />
							) : (
								<>
									{/* <button type="button" className="btn btn-danger mr-auto">Удалить</button> */}
									<button type="submit" className="btn btn-primary ml-auto">
										{' '}
										{t('create_webinar.save')}{' '}
									</button>
								</>
							)}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		updateTeacherWebinar: data => dispatch(updateTeacherWebinar(data)),
		deleteFile: data => dispatch(deleteFile(data)),
	}),
)(TeacherWebinarCreateOrUpdate);
