import React, {useState, useEffect, useRef} from 'react';
import { connect } from "react-redux";
import { getTeacherCategorys, getCoursesSearch } from "../../actions";
import { Link } from 'react-router-dom';
import { issetToken } from "../../helpers/tokenStorge";
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { getLanguage } from "../../helpers/language";
import history from '../../history';
window.jQuery = $;

const  Header = props => {
	let auto_time = useRef(null);
    const [show_btn, setShowBtn] = useState(false);
    useEffect(() => {
        $('.mobile-menu-btn').click(function() {
            $('body').addClass('mobile-menu-opened');
            $('.mobile-menu').addClass('opened');
        });
        $('.left-sidebar-menu-btn').click(function() {
            $('body').addClass('mobile-left-sidebar-menu-opened');
            $('.left-sidebar-menu').addClass('opened');
        });
        $('.right-sidebar-menu-btn').click(function() {
            $('body').addClass('right-sidebar-menu-opened');
            $('.right-sidebar-menu').addClass('opened');
        });
        $('.mobile-menu-btn, .left-sidebar-menu-btn, .right-sidebar-menu-btn').click(function(e) {
            e.stopPropagation();
        });
        $(document).click(function() {
            $('.mobile-menu').removeClass('opened');
            $('body').removeClass('mobile-menu-opened');
            $('.left-sidebar-menu').removeClass('opened');
            $('body').removeClass('mobile-left-sidebar-menu-opened');
            $('.right-sidebar-menu').removeClass('opened');
            $('body').removeClass('right-sidebar-menu-opened');
        });
        $('.accordion__btn').click(function() {
            $(this).toggleClass('rotate');
            $(this).parents('.accordion').toggleClass('closed');
            $(this).parents('.accordion').find('.accordion__body').slideToggle('fast');
        });
        $('.progress-circle .progress').each(function() {
            var progressCirclePercent = $(this).data('percent')
            $(this).css('stroke-dashoffset', 1.95 * (100 - progressCirclePercent));
        });

        $('.progress-circle-big .progress').each(function() {
            var progressCirclePercent = $(this).data('percent')
            $(this).css('stroke-dashoffset', 3.78 * (100 - progressCirclePercent));
        });
        $('.chat__contacts-btn').click(function() {
            $(this).toggleClass('active');
            $('.chat__contacts').toggleClass('active');
        });
        if(!teacher_category.data){
            clearTimeout(auto_time.current );
            auto_time.current  = setTimeout(()=>{
                props.getTeacherCategorys();
            }, 5000);
        }
        return () => {
            clearTimeout(auto_time.current );
		}
    },[]);
    // useEffect(() => {
    //     const search_D = props.location.search; 
    //     const params = new URLSearchParams(search_D);
    //     const search_p = params.get('search'); 
    //     setSearch(search_p);
    // }, [props.location.search])
    
const SearchCourse = (search) => {
        props.setSearch(search);
        // history.push('/courses/1?search='+search);
}
const searchSend = () =>{
    history.push('/courses/1?search='+props.search);
    setShowBtn(false);
}
const onLanguageHandle = newLang => {
    i18n.changeLanguage(newLang);
    window.localStorage.setItem('language', newLang);
};
const {teacher_category} = props;
const { t, i18n } = useTranslation();
const lan = getLanguage();
return (<>
<header className="header" > 
{/* <header className="header" style={{padding: 0}}> 
    <div class="marquee">
        <p> Tizimimizda texnik ishlar olib borilmoqda. Tizimdagi kamchiliklar uchun uzr so'raymiz! </p>
    </div>
    <style jsx={true}>{`
        .marquee {
            height: 30px;
            overflow: hidden;
            position: relative;
            background: #fefefe;
            color: #333;
            border-bottom: 1px solid #26CAAC;
        }
        
        .marquee p {
            position: absolute;
            width: 100%;
            height: 100%;
            margin: 0;
            line-height: 30px;
            text-align: center;
            -moz-transform: translateX(100%);
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            -moz-animation: scroll-left 2s linear infinite;
            -webkit-animation: scroll-left 2s linear infinite;
            animation: scroll-left 20s linear infinite;
            color: #26CAAC
        }
        
        @-moz-keyframes scroll-left {
            0% {
                -moz-transform: translateX(100%);
            }
            100% {
                -moz-transform: translateX(-100%);
            }
        }
        
        @-webkit-keyframes scroll-left {
            0% {
                -webkit-transform: translateX(100%);
            }
            100% {
                -webkit-transform: translateX(-100%);
            }
        }
        
        @keyframes scroll-left {
            0% {
                -moz-transform: translateX(100%);
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
            }
            100% {
                -moz-transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
            }
        }
        `}
    </style> */}
    <div className="container">
        <div className="header__inner">
            <Link to="/" className="logo">
               <b>Didaktika.uz</b>
            </Link>
            {/* <Link to="/faq/1" className="btn btn-outline-primary d-none d-lg-block "  style={{fontSize: 16, padding: '10px 15px', margin: 'auto'}}>
                <span>{ t('header.instructions') }</span>
            </Link> */}
            {/* <div className="header__languages d-none d-lg-block">
                <div className="dropdown">
                    <button className="btn btn-outline-primary " type="button" id="dropdownCategorys" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        style={{fontSize: 16, padding: '10px 15px'}}
                    >
                        <span className="icon icon-list  mr-0 mr-sm-2"></span>
                        <span>{ t('header.categories') }</span>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownCategorys">
                        {teacher_category.data?teacher_category.data.map((cat, index)=>(
                            <Link to={"/courses/1?category="+cat.id} className="dropdown-item"  key={index}>{ cat.name }</Link>
                        )):null}
                    </div>
                </div>
            </div> */}
            <div className="header__search d-none d-lg-block" style={{margin: 'auto'}}>
                {/* {show_btn?null:<label for="search"><span className="icon icon-search"></span></label>}
                <input type="text" id="search" className="form-control" placeholder={ t('header.search')}
                    style={show_btn?{paddingLeft: 10}:{}}
                    onChange={(event)=>SearchCourse(event.target.value)}
                    onClick={()=>setShowBtn(true)}
                    value={props.search}
                />
                {show_btn?<button type="button" className="btn search_btn"
                    onClick={()=>searchSend()}
                >
                    <span className="icon icon-search"></span>
                </button>:null} */}
            </div>
            <div className="header__languages d-none d-lg-block"  style={{margin: 'auto'}}>
                <div className="dropdown">
                    <button className="btn btn-text dropdown-toggle" type="button" id="dropdownLanguages" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {lan==="uz"?t('header.uz'):t('header.ru')}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownLanguages">
                        {/* <div className="dropdown-item" onClick={()=>onLanguageHandle('en')}>English</div> */}
                        <div className="dropdown-item" onClick={()=>onLanguageHandle('ru')}>{ t('header.ru') }</div>
                        <div className="dropdown-item" onClick={()=>onLanguageHandle("uz")}>{ t('header.uz') }</div>
                    </div>
                </div>
            </div>
            <div className="header__profile">
                {issetToken()?
                    <Link to="/dashboard/profile" className="btn btn-primary">
                        <span className="icon icon-user mr-0 mr-sm-2"></span>
                        <span>{ t('header.my_account') }</span>
                    </Link>
                :
                    <span  className="btn btn-primary" data-toggle="modal" data-target="#modalLogin">
                        <span className="icon icon-user mr-0 mr-sm-2"></span>
                        <span>{ t('header.sign_in') }</span>
                    </span> 
                }              
            </div>
            <div className="mobile-menu-btn">
                <button type="button" className="btn btn-primary">
                    <span className="icon icon-list"></span>
                </button>
            </div>
        </div>
    </div>
</header>
<div className="mobile-menu">
    <div className="header__languages">
        <ul>
            <li  onClick={()=>onLanguageHandle('ru')}><span  className={lan==="uz"?"btn btn-outline-primary":"btn btn-primary"} >{t('header.ru_m')}</span></li>
            <li  onClick={()=>onLanguageHandle('uz')}><span className={lan==="uz"?"btn btn-primary":"btn btn-outline-primary"} >{t('header.uz_m')}</span></li>
            
        </ul>
    </div>
    {/* <div className="header__search">
        <label for="search"><span className="icon icon-search"></span></label>
        <input type="text" id="search-m" className="form-control" placeholder={t('header.search')}/>
        
    </div> */}
    <div className="header__categories">
        <ul>
            {teacher_category.data?teacher_category.data.map((cat, index)=>(
                <li key={index}><Link to={"/courses/1?category="+cat.id} >{ cat.name }</Link></li>
            )):null}
        </ul>
        {/* <div style={{position: 'absolute', bottom: 15}}>
            <Link to="/faq/1" className="btn btn-outline-primary "  style={{fontSize: 16, padding: '10px 15px', margin: 'auto'}}>
                { t('header.instructions') }
            </Link>
        </div> */}
    </div>
</div>
</>);
}
export default  connect(
    (state) => ({
        teacher_category: state.teacher_category,
        search: state.search
    }),
    (dispatch) => ({
        getTeacherCategorys: () => dispatch(getTeacherCategorys('paid')),
        getCoursesSearch: (search) => dispatch(getCoursesSearch(search)),
        setSearch: (s) => dispatch({type: 'SEARCH', payload: s})
    })
)(Header);
