import React, {useState} from 'react';
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import ValidateSMS from "./ValidateSMS";
import ForgotPassword from "./ForgotPassword";
import ForgotValidateSms from "./ForgotValidateSms";
import ForgotNewPassword from "./ForgotNewPassword";
import ValidateBotSMS from "./ValidateBotSMS";
import ForgotValidateBotSms from "./ForgotValidateBotSms";
import ValidNewPassword from './ValidNewPassword';

const  AuthModal = (props) => {
    const [act, actState] = useState(0);
    return (<>
        {props.name?<button  type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#modalLogin">{props.name}</button>:null}
        <div className="modal fade" id="modalLogin" tabindex="-1" role="dialog" aria-labelledby="modalLoginTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                {act===1?<SignUp actState={(a)=>actState(a)}/>
                :act===2?<ValidateSMS actState={(a)=>actState(a)}/>
                :act===3?<ForgotPassword actState={(a)=>actState(a)}/>
                :act===4?<ForgotValidateSms actState={(a)=>actState(a)}/>
                :act===5?<ForgotNewPassword actState={(a)=>actState(a)}/>
                :act===6?<ValidateBotSMS actState={(a)=>actState(a)}/>
                :act===7?<ForgotValidateBotSms actState={(a)=>actState(a)}/>
                :act===8?<ValidNewPassword actState={(a)=>actState(a)}/>
                :<SignIn actState={(a)=>actState(a)}/>}
            </div>
        </div>
     </>);
}
export default AuthModal;