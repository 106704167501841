import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../Layout";
import DashSideBar from "../sections/DashSideBar";
import Loading from "../sections/Loading";
import Navigation from "../sections/Navigation";
import NoData from "../sections/NoData";
import Pagination from "../sections/Pagination";
import CheckRole from "../sections/CheckRole";
import { roles } from "../../helpers/roles";
import { getInstance } from "../../helpers/httpClient";
import Moment from "react-moment";
import { getToken } from "../../helpers/tokenStorge";

const UserCourses = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState({});
  const [u_loading, setU_loading] = useState(true);
  const { page } = props.match.params;
  const params = ``;
  const search = props.location.search;
  const b_params = new URLSearchParams(search);
  const user_id = b_params.get("user");

  useEffect(() => {
    getUsersCourses(page);
  }, [page]);
  const getUsersCourses = (p = 1) => {
    setU_loading(true);
    getInstance()
      .get(`/api/v1/manager/result/course?user_id=${user_id}&page=${page}`)
      .then((r) => {
        setUsers(r?.data?.data?.courses ?? {});
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
        setU_loading(false);
      });
  };
  return (
    <Layout>
      <CheckRole roles={[roles.manager, roles.vazirlik]} />
      <main class="main">
        <section class="section section-dashboard py-0">
          <div class="container">
            <div class="row">
              <DashSideBar />
              <div class="col-md-12 col-lg-9 col-xl-10">
                <div class="dashboard-right">
                  <Navigation
                    is_dash={true}
                    is_btn={true}
                    active={t("dash_side_bar.m_courses")}
                    navs={[
                      {
                        link: "/dashboard/users/1",
                        title: t("dash_side_bar.m_puplis"),
                      },
                    ]}
                  />
                  <div class="dashboard-title dashboard-title--has-buttons">
                    <h5>{t("dash_side_bar.m_courses")}</h5>
                    <div class="buttons butons--target">
                      <a
                        href={`https://api.didaktika.uz/api/v1/manager/result/courses-export?user_id=${user_id}&token=${getToken()}`}
                        className="btn btn-primary btn-sm font-weight-medium"
                      >
                        {t("m_pupils.excel_export")}
                      </a>
                    </div>
                  </div>
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <div class="card card--shadow">
                        <div class="card-body pb-0 pr-3 pl-3 pt-3">
                          <div class="scroll scroll--horizontall">
                            <table class="table table-bordered table--style-1 manager-teachers-table">
                              <thead>
                                <tr>
                                  <th>
                                    <div class="cell-inner">
                                      {t("course_view.name")}
                                    </div>
                                  </th>
                                  <th>
                                    <div class="cell-inner">
                                      {t("table.type")}
                                    </div>
                                  </th>
                                  <th>
                                    <div class="cell-inner">
                                      {t("table.c_date")}
                                    </div>
                                  </th>
                                  <th>
                                    <div class="cell-inner">
                                      {t("table.e_date")}
                                    </div>
                                  </th>
                                  <th>
                                    <div class="cell-inner">
                                      {t("table.user_ball")}
                                    </div>
                                  </th>
                                  <th>
                                    <div class="cell-inner">
                                      {t("table.all_ball")}
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              {u_loading ? null : (
                                <tbody>
                                  {users.data && users.data.length
                                    ? users.data.map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <div
                                              class="cell-inner"
                                              style={{
                                                width: 240,
                                                whiteSpace: "normal",
                                              }}
                                            >
                                              {item.name}
                                            </div>
                                          </td>
                                          <td>
                                            <div class="cell-inner">
                                              {t("create_course." + item?.type)}
                                            </div>
                                          </td>
                                          <td>
                                            <div class="cell-inner">
                                              <Moment format="HH:mm DD.MM.YYYY">
                                                {
                                                  item?.buys_course_latest
                                                    ?.created_at
                                                }
                                              </Moment>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="cell-inner">
                                              <Moment format="HH:mm DD.MM.YYYY">
                                                {
                                                  item?.user_hour_latest
                                                    ?.created_at
                                                }
                                              </Moment>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="cell-inner">
                                              {item?.user_hour_latest
                                                ?.user_score ?? 0}
                                            </div>
                                          </td>
                                          <td>
                                            <div class="cell-inner">
                                              {item?.user_hour_latest
                                                ?.max_score ?? 0}
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              )}
                            </table>
                            {u_loading ? (
                              <div>
                                <Loading size={7} />
                              </div>
                            ) : users.data && users.data.length ? null : (
                              <NoData text={t("m_courses.no_data")} />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 p-0">
                          <div className="mt-4" style={{ textAlign: "center" }}>
                            {(page - 1) * 20 + 1} -{" "}
                            {page * 20 > users.total ? users.total : page * 20}{" "}
                            / {t("m_pupils.all_p")}{" "}
                            {users.total ? users.total : 0} {t("m_pupils.ta")}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 p-0">
                          <Pagination
                            total={users.total ? users.total : 20}
                            count={20}
                            active={parseInt(page)}
                            link="/dashboard/users/"
                            search_params={"?" + params.substring(1)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};
export default UserCourses;
