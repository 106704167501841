import React, {useState, useEffect} from 'react';
import { getTeacherLesson, updatedVideoQuestionTime } from "../../actions";
import { connect } from "react-redux";
import Layout from '../Layout';
import DashSideBar from "../sections/DashSideBar";
import Navigation from "../sections/Navigation";
import Loading from '../sections/Loading';
import history from "../../history";
import { useTranslation } from 'react-i18next';
import CheckRole from '../sections/CheckRole';
import VideoQuestionPlayer from '../sections/VideoQuestionPlayer';
import NoData from '../sections/NoData';
import { roles } from "../../helpers/roles";
import _ from 'lodash';
import VideoQuestionChange from '../sections/VideoQuestionChange';
import VideoAnswerChange from '../sections/VideoAnswerChange';
import VideoAnswerAdd from '../sections/VideoAnswerAdd';
import VideoExplanChange from '../sections/VideoExplanChange';
import VideoQuestionAdd from '../sections/VideoQuestionAdd';

const DashTeacherLessonVideoQuestionUpdate = (props) => {
    const { t } = useTranslation();
    const course_id =  props.match.params.course_id;
    const section_id =  props.match.params.section_id;
    const time =  parseInt(props.match.params.time);
    const { teacher_lessons_status, teacher_lesson_data, updatedVideoQuestionTime, update_question_time_status } = props;
    const [loading, setLoading] = useState(true);
    const [editTime, setEditTime] = useState(false);
    const [add, setAdd] = useState(false);
    useEffect(() => {
        props.getTeacherLesson(course_id, section_id);
    }, []);

    useEffect(() => {        
        if(teacher_lessons_status.status === 'started'){
            setLoading(true);
        }
        if(teacher_lessons_status.status === 'success'){
            setLoading(false);
            if(!teacher_lesson_data.id){
                history.push(`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson`);
            }
        }
        if(teacher_lessons_status.status === 'error'){
            setLoading(false);
        }
    }, [teacher_lessons_status, teacher_lesson_data]);
    
    const updateTime = (t) =>{
        updatedVideoQuestionTime({
            time: t,
            lesson_id: _.get(teacher_lesson_data, 'id', 0),
            questions: _.map(_.filter(_.get(teacher_lesson_data, 'questions', []), (o) => { return o.time===parseInt(time); }), _.iteratee('id') )
        });
    }

    return (<Layout>
	<CheckRole roles={ [ roles.teacher ] } />
    <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('teacher_lesson.questions_update')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    },
                                    {
                                        'title': t('author_page.course'),
                                        'link': '/dashboard/copyrights'
                                    },
                                    {
                                        'title': t('course_items.items'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id
                                    },
                                    {
                                        'title': t('teacher_lesson.lesson'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson'
                                    },
                                    {
                                        'title': teacher_lesson_data.name,
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson/video'
                                    },
                                    {
                                        'title': t('teacher_lesson.questions'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson/video/questions'
                                    }
                                ]}
                            />
                            {loading?<Loading/>:<>
                            <div className="dashboard-title">
                                <h5>{t('teacher_lesson.questions_update_title1')}</h5>
                            </div>
                            <div className="card card--shadow">
                                <div className="card-body">
                                    {editTime?<>
                                        <div className="mb-4">
                                            {t('teacher_lesson.questions_new_title')}
                                            <span style={{float: 'right'}}>
                                                <span className="button" 
                                                    style={{
                                                        color: '#898DA6',
                                                        margin: '5px',
                                                        cursor: 'pointer',
                                                        float: 'right',
                                                        background: 'rgba(137, 141, 166, 0.1)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: 32,
                                                        height: 32,
                                                        borderRadius: 8,
                                                    }}
                                                        data-toggle="modal" data-target="#modalDeleteLesson"
                                                        onClick={()=>setEditTime(false)}
                                                    ><span className="icon icon-close"></span>
                                                </span>
                                            </span>
                                        </div>
                                        <div>
                                            <VideoQuestionPlayer time={time} course_lesson={teacher_lesson_data} course={course_id} section={section_id} 
                                                updateTime={(t)=>{ updateTime(t); setEditTime(false); } } is_update={true}
                                                update_question_time_status={update_question_time_status}
                                            />
                                        </div>
                                    </>:<div className="form-group" style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                                        <label for="" style={{display: 'inline', margin: 10 }}>{t('teacher_lesson.minute')}
                                            <input type="number" className="form-control " disabled value={parseInt(time/60)}  style={{ width: 80}}/> 
                                        </label>
                                        <label for=""  style={{display: 'inline', margin: 10 }}>{t('teacher_lesson.second')}
                                            <input type="number" className="form-control " disabled value={time%60}  style={{ width: 80}} />
                                        </label>
                                        <span style={{marginLeft: 'auto'}}>
                                            <span className="button" 
                                                style={{
                                                    color: '#898DA6',
                                                    margin: '5px',
                                                    cursor: 'pointer',
                                                    float: 'right',
                                                    background: 'rgba(137, 141, 166, 0.1)',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: 32,
                                                    height: 32,
                                                    borderRadius: 8,
                                                }}
                                                    data-toggle="modal" data-target="#modalDeleteLesson"
                                                    onClick={()=>setEditTime(true)}
                                                ><span className="icon icon-edit"></span>
                                            </span>
                                        </span>
                                    </div>}
                                </div>
                            </div>
                            <div className="dashboard-title">
                                <h5>{ t('teacher_lesson.questions') }</h5>
                            </div>
                            {_.filter(_.get(teacher_lesson_data, 'questions', []), (o) => { return o.time===parseInt(time); }).length?<>
                            {    
                                _.filter(_.get(teacher_lesson_data, 'questions', []), (o) => { return o.time===parseInt(time); })
                                .map((question, index)=>(<><div className="card card--shadow ">
                                    <div className="card-body" style={{margin: 0, padding: 0}}>
                                        <VideoQuestionChange question_data={question} index={index} time={time}/>
                                        <div>
                                            {question.answers.map((answer, index_a)=>(
                                                <div key={index_a} >
                                                    <VideoAnswerChange answer_data={answer} index_a={index_a+1}  />
                                                </div>
                                            ))} 
                                            <div>
                                                <VideoAnswerAdd question_id={ question.id }  />
                                            </div>
                                        </div>
                                        <div>
                                            <VideoExplanChange question_data={question}  time={time}/>
                                        </div>
                                    </div>
                                </div></>))
                            }
                            {add?null:<div className="buttons">
                                <span
                                    className="btn btn-primary  btn-sm font-weight-medium"
                                    onClick={()=>setAdd(true)}
                                >
                                    <span className="icon icon-plus" style={{color: '#fff'}} ></span> { t('diagnostics.add') }
                                </span>
                            </div>}
                            </>:add?null:<NoData text={<>
                                <div>
                                    <div className="buttons">
                                        <span
                                            className="btn btn-primary  btn-sm font-weight-medium"
                                            onClick={()=>setAdd(true)}
                                        >
                                            <span className="icon icon-plus" style={{color: '#fff'}} ></span> { t('diagnostics.add') }
                                        </span>
                                    </div>
                                </div>
                            </>}/>}
                            <div style={add?{}:{display: 'none'}}>
                                <VideoQuestionAdd
                                    close={()=>setAdd(false)}
                                    lesson_id={teacher_lesson_data.id}  time={time}
                                />
                            </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>    
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherLesson: (_course_id, _section_id) => dispatch(getTeacherLesson(_course_id, _section_id)),
        updatedVideoQuestionTime: (data) => dispatch(updatedVideoQuestionTime(data))
    })
)(DashTeacherLessonVideoQuestionUpdate);