import React, {useState, useEffect}  from 'react';
import { connect } from "react-redux";
import PhoneInput from "./PhoneInput";
import {signUp} from "../../actions";
import Loading from './Loading';
import { useTranslation } from 'react-i18next';

const  SignUp = (props) => {
    const { t } = useTranslation();
    const {is_edu_institution} = props;
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [loading, setLoading] = useState(false);
    const [commonError, setCommonError] = useState('');
    const [ofertaError, setOfertaError] = useState('');
    const [oferta, setOferta] = useState(false);
    useEffect(() => {
        if(props.sign_up.status === 'success'){
            setPhone('');
            props.signUpEnd();
            props.actState(2);
        }
        if(props.sign_up.status === 'success_bot'){
            setPhone('');
            props.signUpEnd();
            props.actState(6);
        }
        if(props.sign_up.status === 'started'){
            setLoading(true);
        }else{
            setLoading(false);
            if(props.sign_up.status === 'error'){
                setCommonError(props.sign_up.error_msg);
            }
        }
    }, [props.sign_up]);
    const onSubmit = event => {
		event.preventDefault();
        let p=true;
        if(phone.length!==12){
            setPhoneError(t('sign_up.phone_e'));
            p=false;
        }
        if( is_edu_institution && !oferta ){
            p = false;
            setOfertaError(t('sign_up.oferta_e'));
        }
        if(p){
            props.signUp(
                is_edu_institution?{
                    login: phone,
                    is_edu_institution: 1
                }
                :{
                    login: phone,
                }
            );
        }
    }
    return (<>
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{t('sign_up.title')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span className="icon icon-close"></span>
                </button>
            </div>
            <div className="modal-body">
            <form onSubmit={onSubmit} noValidate>
                <div className="form-group mb-3">
                    <label for="">{t('sign_up.phone')}</label>
                    <PhoneInput
                            inputClass="form-control"
                        onClickInput={()=>{ setPhoneError(""); setCommonError('');}}
                        setPhone={(phone)=>setPhone(phone)}
                    />
                </div>
                {is_edu_institution?
                <div className="form-group mb-3 checkbox-custom" data-toggle="modal" data-target="#modalOferta">
                    <input type="checkbox" name={'oferta'} checked={oferta}/>
                    <p>{t('sign_up.oferta')}</p>
                     <span className="checkmark"></span>
                </div>:''}
                <div style={{color: 'red'}}>{phoneError}</div>
                <div style={{color: 'red'}}>{commonError}</div>
                <div style={{color: 'red'}}>{ofertaError}</div>

                {loading?<Loading />:
                <button type="submit" className="btn btn-primary w-100">
                    {t('sign_up.btn')}
                </button>}
                <div className="form-group mt-4 mb-5">
                    <p style={{color: '#26CAAC', cursor: 'pointer'}}
                        onClick={()=>props.actState(3)}
                    >{t('forgot.forgot')}</p>
                    <p style={{color: '#26CAAC', cursor: 'pointer'}}
                        onClick={()=>props.actState(0)}
                    >{t('sign_up.sign_in')}</p>
                </div>
            </form>
            </div>
        </div>
        {is_edu_institution?
        <div className="modal fade" id="modalOferta" tabindex="-1" role="dialog" aria-labelledby="modalOfertaTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: 900}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Oferta shartlari</h5>
                    </div>
                    <div className="modal-body">
                        <h5>Foydalanish kelishuvi</h5>
                        <p>
                            <ul>
                                <li>
                                    Ushbu Foydalanish kelishuvi (keyingi o‘rinlarda – Kelishuv) O‘zbekiston Respublikasi Fuqarolik kodeksining 369-moddasiga muvofiq A.Avloniy nomidagi xalq ta’limi muammolarini o‘rganish va istiqbollarini belgilash ilmiy-tadqiqot instituti (keyingi o‘rinlarda – Institut)ning ommaviy ofertasi hisoblanadi va har qanday murojaat qilgan jismoniy yoki yuridik shaxs (keyingi o‘rinlarda – Foydalanuvchi) bilan «https://onlinedu.uz/» platformasidan foydalanish shartlari va qoidalarini belgilaydi. Ushbu Kelishuv shartlari barcha Foydalanuvchilar uchun bir hil hisoblanadi.
                                </li>
                            </ul>
                        </p>
                        <h5>Kelishuv predmeti</h5>
                        <p>
                            <ul>
                                <li>Institut platforma egasi hisoblangan holda jismoniy va yuridik shaxslarga tizimidan ro‘yxatdan o‘tish imkoniyatini taqdim etadi. </li>
                                <li>Foydalanuvchilarni ro‘yxatdan o‘tish bilan ta’minlanadi. </li>
                            </ul> 
                        </p>
                        <h5>Kelishuvni tuzish tartibi</h5>
                        <p>
                            <ul>
                                <li>Jismoniy shaxs tizimida ro‘yxatdan o‘tib, ushbu Kelishuv shartlarini so‘zsiz va to‘liq qabul qiladi va o‘zining shaxsiy ma’lumotlarini qayta ishlash va saqlashga rozilik beradi.</li>
                                <li> Tizimida ro‘yxatdan o‘tguncha Foydalanuvchi ushbu Kelishuvning barcha shartlari bilan tanishishga majbur. Tizimida ro‘yxatdan o‘tgan Foydalanuvchi ushbu Kelishuvning barcha shartlari bilan tanishgan va ularga rozi hisoblanadi, bunda Kelishuv ushbu ommaviy oferta shartlari asosida yozma shaklda tuzilgan hisoblanadi va ikki tomon tarafidan imzolangan Kelishuv hisoblanadi. Jismoniy shaxsning tizimda ro‘yxatdan o‘tgan vaqti ushbu Kelishuv tuzilgan payt hisoblanadi.</li>
                            </ul>
                        </p>
                        <h5>Foydalanuvchining huquqlari va majburiyatlari</h5>
                        <p>
                            <ul>
                                <li>
                                    <b>Foydalanuvchi quyidagi huquqlarga ega:</b>
                                    <ul>
                                        <li>Tizimida o‘z hisobga olish yozuvidan foydalanish.</li>
                                        <li>O‘z shaxsiy ma’lumotlariga nisbatan istalgan vaqtda bir tomonlama tartibda ushbu kelishuvni bekor qilish va o‘zining hisobga olish yozuvini o‘chirib tashlash yo‘li orqali axborot tizimlari va resurslaridan foydalanishdan voz kechish.</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Foydalanuvchi quyidagilarga majbur:</b>
                                    <ul>
                                        <li>Tizimga ulangan axborot tizimlarining ishlash jarayonini buzishga qaratilgan xatti-harakatlarni amalga oshirmaslik</li>
                                        <li>Ro‘yxatga olishda ishonchli bo‘lgan ma’lumotlarni taqdim etish, shuningdek ularning aktual holatini ta’minlash, o‘zini boshqa shaxs yoki qandaydir tashkilot vakili qilib ko‘rsatmaslik</li>
                                        <li>O‘zining hisobga olish yozuvlarining saqlanishini ta’minlash uchun kerakli choralarni ko‘rish</li>
                                        <li>Hisobga olish yozuvidan o‘zining foydalana olish vositalari (mobil telefon raqami, login va parol)ning saqlanishi buzilgan holda zudlik bilan login va/yoki parolni o‘zgartirish</li>
                            </ul>
                                </li>
                            </ul>
                        </p>
                        <h5>Institut huquqlari va majburiyatlari</h5>
                        <p>
                            <ul>
                                <li>
                                    <b>Institut quyidagi huquqlarga ega:</b>
                                    <ul>
                                        <li>Tizimida foydalanuvchining xatti-harakatlarining monitoringini o‘tkazish</li>
                                        <li>Foydalanuvchi tomonidan ushbu Kelishuv talablari buzilgan holda, foydalanuvchining tizimiga ulangan axborot tizimlaridan foydalana olishini, shu jumladan tizimidagi foydalanuvchining hisobga olish yozuvini bloklash orqali cheklash</li>
                                        <li>Dastlab Foydalanuvchini xabardor qilmasdan tizimidan foydalanishning ushbu shartlarini o‘zgartirish. O‘zgartirilgan shartlar tizimining bosh sahifasiga joylashtirilishi kerak</li>
                                        <li>Foydalanuvchiga axborot xabarlarini yuborish</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Institut quyidagi majburiyatlarga ega:</b>
                                    <ul>
                                        <li>Tizimi ishlashini ta’minlash</li>
                                        <li>Tizimida foydalanuvchilar ma’lumotlarining butunligi va o‘zgarmasligini kafolatlash</li>
                                        <li>Foydalanuvchilarning tizimga murojaatlari hisobi va statistikasini yuritish</li>
                                        <li>Qonun hujjatlari talablari va shaxsiy ma’lumotlardan foydalanish shartlariga muvofiq foydalanuvchining shaxsiy ma’lumotlarini himoyalashni ta’minlash</li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                        <h5>Javobgarlik</h5>
                        <p>
                            <ul>
                                <li>Institut uning aybi bilan yuzaga kelmagan shaxsiy ma’lumotlarning yo‘qolganligi uchun Foydalanuvchi oldida javobgar bo‘lmaydi. </li>
                                <li>
                                    <b>Tizim faoliyatidagi nosozliklar yoki ishlash qobiliyatining yo‘qolishi uchun javobgar bo‘lmaydi, agar ko‘rsatilgan nosozliklar yoki ishlash qobiliyatining yo‘qolishi quyidagilar oqibatida sodir bo‘lgan bo‘lsa:</b>
                                        <ul><li>Uchinchi shaxslarning dasturiy ta’minotidan ruxsatsiz foydalanishiga va/yoki ko‘rsatilgan dasturiy ta’minotning ishdan chiqishiga qaratilgan xatti-harakatlari ulangan veb-servislarning ishlamay qolishi</li></ul>
                                </li>
                                <li>Tizimdan foydalanish yoki foydalanish imkoniyati bo‘lmaganligi sababli sodir bo‘lgan har qanday bevosita va bilvosita zararlar uchun javobgar bo‘lmaydi.</li>
                                <li>
                                    <b>Institut quyidagi hollarda Foydalanuvchining hisobga olish yozuvining xavfsizligi uchun javobgar bo‘lmaydi:</b>
                                    <ul>
                                        <li>Foydalanuvchi tomonidan uchinchi shaxslarga (qasddan yoki ehtiyotsizlik sababli) parol va shaxsiy ma’lumotlarning taqdim etilishi </li>
                                        <li>Parolni tanlash va/yoki kodni ochish imkonini beradigan dasturiy vositalardan foydalanish oqibatida Foydalanuvchining hisobga olish yozuvidan uchinchi shaxslarning foydalanishi</li>
                                        <li>Parol va hisobga olish ma’lumotlarini tanlash yo‘li bilan Foydalanuvchi hisobga olish yozuvidan uchinchi shaxslarning foydalanishi</li>
                                    </ul>
                                </li>
                                <li>Foydalanuvchi fuqarolik, jinoyat qonun hujjatlari, shuningdek ma’muriy huquqbuzarliklar to‘g‘risidagi qonun hujjatlari talablariga muvofiq taqdim etilgan shaxsiy ma’lumotlarning ishonchliligi, shu jumladan ularning o‘z vaqtida yangilanishi va tuzatilishiga to‘liq javobgar bo‘ladi.</li>
                                <li>Foydalanuvchi ushbu Kelishuv bo‘yicha o‘zining vazifalariga rioya etmaganligi sababli Markazga yetkazilgan zarar uchun javobgar bo‘ladi.</li>
                                <li>Foydalanuvchi hisobga olish yozuvidan foydalanish uchun o‘zi tanlab olgan vositalarning xavfsizligi (shuningdek aniqlashga nisbatan barqarorlik), saqlanishi uchun shaxsan javobgar bo‘ladi, shuningdek ularning maxfiyligini mustaqil ta’minlaydi. Bunda Foydalanuvchining hisobga olish yozuvi ostidagi barcha xatti-harakatlari Foydalanuvchi tomonidan amalga oshirilgan deb hisoblanadi.</li>
                            </ul>
                        </p>
                        <h5>Nizolarni hal qilish tartibi</h5>
                        <p>
                            <ul>
                                <li>Ushbu Kelishuvni bajarish bo‘yicha barcha nizolar va kelishmovchiliklar Tomonlar tarafidan O‘zbekiston Respublikasining amaldagi qonun hujjatlariga muvofiq hal etiladi.</li>
                            </ul>
                        </p>
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn btn-primary btn-sm w-100" data-dismiss="modal"
                                    onClick={()=>{setOferta(true); setOfertaError('');}}
                                >
                                    Oferta shartlari bilan tanishib chiqdim va rozi bo'ldim.
                                </button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-lighter btn-sm w-100"  data-dismiss="modal"
                                    onClick={()=>setOferta(false)}
                                >
                                    Bekor qilish
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>:''}
    </>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        signUp: (data)=>dispatch(signUp(data)),
        signUpEnd: ()=>dispatch({type: 'SIGNUP_STATUS', payload: { status: 'end'} })
    })
)(SignUp);