import React from 'react';
import JoditTextShow from "./JoditTextShow";
import _ from 'lodash';

const LessonTestMultiple = (props) => {
    const { question, number, answersList, setAnswersList, testResultShow, testResult} = props;
    const setAnsver = e => {
        const v = parseInt(e.target.value);
        if(_.get(answersList, question.id, []).includes(v))
            setAnswersList( {...answersList, [question.id]: _.remove( _.get(answersList, question.id, []), (i)=>{return v!==i} )} );
        else
            setAnswersList( {...answersList, [question.id]: [..._.get(answersList, question.id, []), v ] } );
    }
    return (<>
        <div className="test__item card  m-0">
            <div className="card-body">
                <div className="test__item-question">
                    <JoditTextShow text={ question.question } />
                </div>
                <ul className="test__item-answers">
                    {_.get(question, 'vanswers', []).map((answer, index)=>(
                        <li key={index}>
                            <label className="checkbox-custom">
                                <input type="checkbox" name={ 'answers['+answer.question_id+'][]'}  
                                    value={answer.id} 
                                    disabled={testResultShow}
                                    checked={_.get(answersList, question.id, []).includes(answer.id)}
                                    onChange={e=>setAnsver(e)}
                                />
                                <JoditTextShow text={ answer.answer } />
                                {/* {_.get(answersList, question.id, []).includes(answer.id) && testResultShow?
                                    <span style={_.get(testResult, 'vquestions.is_completed', false)
                                    &&(_.get( _.find(_.get(testResult, 'vquestions.vquestions[0].vanswers', []), o=>{return o.id===answer.id}), 'score', 0)>0)?{color: '#26CAAC'}:{color: 'red'}}>
                                        {_.get(testResult, 'vquestions.is_completed', false)
                                        &&(_.get( _.find(_.get(testResult, 'vquestions.vquestions[0].vanswers', []), o=>{return o.id===answer.id}), 'score', 0)>0)
                                            ?"Tog'ri "+
                                            _.get( _.find(_.get(testResult, 'vquestions.vquestions[0].vanswers', []), o=>{return o.id===answer.id}), 'score', 0)
                                        +' ball':'Xato'}
                                    </span>:null
                                } */}
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>);
}
export default LessonTestMultiple;