import {
	S_M,
	C_M,
	COURSES_DATA,
	COURSES_ERROR,
	CAT_COURSES_DATA,
	CAT_COURSES_ERROR,
	CAT_COURSES_START,
	SIGN_STATUS,
	LOG_OUT,
	SIGNUP_STATUS,
	VALIDATE_SMS,
	PROFILE,
	PROFILE_STATUS,
	PROFILE_UPDATE_STATUS,
	PROFILE_UPDATE,
	COURSE,
	COURSE_STATUS,
	TEACHER_COURSE,
	TEACHER_COURSE_STATUS,
	TEACHER_CATEGORY,
	TEACHER_CATEGORY_STATUS,
	TEACHER_LAN,
	TEACHER_LAN_STATUS,
	TEACHER_COURSE_CHANGE,
	TEACHER_COURSE_CHANGE_STATUS,
	GET_UPDATE_COURSE_STATUS,
	GET_UPDATE_COURSE,
	GET_TEACHER_COURSE_SECTIONS_STATUS,
	GET_TEACHER_COURSE_SECTIONS,
	CREATE_SECTION_STATUS,
	CREATE_SECTION,
	DELETE_SECTION_STATUS,
	DELETE_SECTION,
	UPDATE_SECTION_STATUS,
	UPDATE_SECTION,
	ORDER_SECTION_STATUS,
	ORDER_SECTION,
	TEACHER_LESSONS_STATUS,
	TEACHER_LESSONS,
	CREATE_LESSON_STATUS,
	CREATE_LESSON,
	UPDATE_LESSON_TEXT_STATUS,
	UPDATE_LESSON_TEXT,
	UPDATE_LESSON_VIDEO_STATUS,
	UPDATE_LESSON_VIDEO,
	UPDATE_LESSON_WEBINAR_STATUS,
	UPDATE_LESSON_WEBINAR,
	UPDATE_LESSON_QUESTION_STATUS,
	UPDATE_LESSON_QUESTION,
	UPDATE_LESSON_ANSWER_STATUS,
	UPDATE_LESSON_ANSWER,
	GET_TECH_LESSON_QUESTION_STATUS,
	GET_TECH_LESSON_QUESTION,
	DELETE_QUESTION,
	DELETE_QUESTION_STATUS,
	DELETE_ANSWER,
	DELETE_ANSWER_STATUS,
	UPGRADE_CAT_STATUS,
	UPGRADE_CAT,
	UPGRADE_COURSE_STATUS,
	UPGRADE_COURSE,
	TEACHER_COURSE_STATUS_UPDATE,
	COURSE_BUY_STATUS,
	COURSE_BUY,
	RETRAINING_CAT_STATUS,
	RETRAINING_CAT,
	RETRAINING_COURSE,
	RETRAINING_COURSE_STATUS,
	COURSE_SECTIONS_STATUS,
	COURSE_SECTIONS,
	COURSE_LESSON_STATUS,
	COURSE_LESSON,
	MY_COURSES_STATUS,
	MY_COURSES,
	TEST_CHECK_STATUS,
	TEST_CHECK,
	UPDATE_TEACHER_WEBINAR_STATUS,
	UPDATE_TEACHER_WEBINAR,
	TEACHER_WEBINAR_STATUS,
	TEACHER_WEBINAR,
	WEBINAR_DATA_STATUS,
	WEBINAR_DATA,
	MY_WEBINAR_STATUS,
	MY_WEBINAR,
	WEBINARS_LIST_STATUS,
	WEBINARS_LIST,
	CHAT_STATUS,
	CHAT,
	CHAT_MESSAGE_STATUS,
	CHAT_MESSAGE,
	CHAT_MESSAGE_SEND_STATUS,
	CHAT_MESSAGE_SEND,
	CHAT_MESSAGE_MORE_STATUS,
	CHAT_MESSAGE_MORE,
	TEACHER_LIST_STATUS,
	TEACHER_LIST,
	LESSON_WEBINAR_JOIN_STATUS,
	LESSON_WEBINAR_JOIN,
	LESSON_WEBINAR_RECORD_STATUS,
	LESSON_WEBINAR_RECORD,
	DELETE_LESSON_STATUS,
	DELETE_LESSON,
	PASSWORD_UPDATE_STATUS,
	PASSWORD_UPDATE,
	Unread_Count_Notification,
	NOTIFICATIONS_STATUS,
	NOTIFICATIONS,
	NOTIFICATION_MARK,
	STATISTIC_USER_STATUS,
	STATISTIC_USER,
	STATISTIC_STATUS,
	STATISTIC,
	PUPILS_STATUS,
	PUPILS,
	MANAGER_CERTIFICATE_STATUS,
	MANAGER_CERTIFICATE,
	MANAGER_COURSE_STATUS,
	MANAGER_COURSE,
	MANAGER_COUNTS_STATUS,
	MANAGER_COUNTS,
	REGIONS_STATUS,
	REGIONS,
	CITIES_STATUS,
	CITIES,
	FORGOT_PASSWORD_STATUS,
	FORGOT_PASSWORD,
	FORGOT_VALIDATE_SMS,
	FORGOT_NEW_PASSWORD,
	DIAGNOSTIC_TEST_STATUS,
	DIAGNOSTIC_TEST,
	DIAGNOSTIC_ANSWER_STATUS,
	DIAGNOSTIC_ANSWER,
	DIAGNOSTIC_QUESTION_STATUS,
	DIAGNOSTIC_QUESTION,
	DELETE_DIAGNOSTIC_QUESTION_STATUS,
	DELETE_DIAGNOSTIC_QUESTION,
	DELETE_DIAGNOSTIC_ANSWER_STATUS,
	DELETE_DIAGNOSTIC_ANSWER,
	Exercise_TEST_STATUS,
	Exercise_TEST,
	Exercise_ANSWER_STATUS,
	Exercise_ANSWER,
	Exercise_QUESTION_STATUS,
	Exercise_QUESTION,
	DELETE_Exercise_QUESTION_STATUS,
	DELETE_Exercise_QUESTION,
	DELETE_Exercise_ANSWER_STATUS,
	DELETE_Exercise_ANSWER,
	USER_POSITION_STATUS,
	USER_POSITION,
	UPDATE_GROUPING_ANSWER_STATUS,
	UPDATE_GROUPING_ANSWER,
	UPDATE_DRAG_WORDS_ANSWER_STATUS,
	UPDATE_DRAG_WORDS_ANSWER,
	DELETE_VIDEO_ANSWER,
	DELETE_VIDEO_QUESTION,
	UPDATE_VIDEO_ANSWER,
	UPDATE_VIDEO_QUESTION,
	DELETE_VIDEO_TIME_STATUS,
	DELETE_VIDEO_TIME,
	UPDATE_VIDEO_QUESTION_TIME_STATUS,
	UPDATE_VIDEO_QUESTION_TIME,
	CHAT_MORE,
	CHAT_MORE_STATUS,
	NEW_PASSWORD
} from '../actions/types';
import { setToken, /*isssetToken,*/ removeToken } from '../helpers/tokenStorge';
import { getInstance, getNotAuthInstance, getMore } from '../helpers/httpClient';
import { WS_HOST } from '../helpers/api';
import history from '../history';
import axios from 'axios';
import _ from 'lodash';
// import { useSelector } from 'react-redux';

const oldToken = error => {
	if (error.response && error.response.status && error.response.status === 401) {
		history.push('/old-token');
	}
};
export const deleteDiagnosticAnswer = data => {

	return dispatch => {


		dispatch({
			type: DELETE_DIAGNOSTIC_ANSWER_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/diagnostic/delete-answer`, data)
			.then(response => {
				dispatch({
					type: DELETE_DIAGNOSTIC_ANSWER,
					payload: response.data.data,
					id: data.id,
					question_id: data.question_id,
				});
			})
			.catch(error => {
				dispatch({
					type: DELETE_DIAGNOSTIC_ANSWER_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
			});
	};
};
export const deleteExerciseAnswer = data => {
	return dispatch => {
		dispatch({
			type: DELETE_Exercise_ANSWER_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/diagnostic/delete-answer`, data)
			.then(response => {
				dispatch({
					type: DELETE_Exercise_ANSWER,
					payload: response.data.data,
					id: data.id,
					question_id: data.question_id,
				});
			})
			.catch(error => {
				dispatch({
					type: DELETE_Exercise_ANSWER_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
			});
	};
};

export const deleteDiagnosticQuestion = data => {
	return dispatch => {
		dispatch({
			type: DELETE_DIAGNOSTIC_QUESTION_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/diagnostic/delete-question`, data)
			.then(response => {
				dispatch({
					type: DELETE_DIAGNOSTIC_QUESTION,
					payload: response.data.data,
					id: data.id,
				});
			})
			.catch(error => {
				dispatch({
					type: DELETE_DIAGNOSTIC_QUESTION_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
			});
	};
};

export const deleteExerciseQuestion = data => {
	return dispatch => {
		dispatch({
			type: DELETE_Exercise_QUESTION_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/diagnostic/delete-question`, data)
			.then(response => {
				dispatch({
					type: DELETE_Exercise_QUESTION,
					payload: response.data.data,
					id: data.id,
				});
			})
			.catch(error => {
				dispatch({
					type: DELETE_Exercise_QUESTION_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
			});
	};
};

export const getPositions = () => {
	return dispatch => {
		getInstance()
			.get(`/api/v1/profil/user-position`)
			.then(response => {
				dispatch({
					type: USER_POSITION,
					payload: response.data.data,
				});
			})
			.catch(error => {
				dispatch({
					type: USER_POSITION_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const addDiagnosticQuestion = data => {
	return dispatch => {
		dispatch({
			type: DIAGNOSTIC_QUESTION_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/diagnostic/update-question`, data)
			.then(response => {
				dispatch({
					type: DIAGNOSTIC_QUESTION,
					payload: response.data,
					id: data.id,
				});
			})
			.catch(error => {
				dispatch({
					type: DIAGNOSTIC_QUESTION_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const addExerciseQuestion = data => {
	return dispatch => {
		dispatch({
			type: Exercise_QUESTION_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/exercise/update-question`, data)
			.then(response => {
				dispatch({
					type: Exercise_QUESTION,
					payload: response.data,
					id: data.id,
				});
			})
			.catch(error => {
				dispatch({
					type: Exercise_QUESTION_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const addDiagnosticAnswer = data => {
	return dispatch => {
		dispatch({
			type: DIAGNOSTIC_ANSWER_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/diagnostic/update-answer`, data)
			.then(response => {
				dispatch({
					type: DIAGNOSTIC_ANSWER,
					payload: response.data,
					question_id: data.question_id,
					id: data.id,
				});
			})
			.catch(error => {
				dispatch({
					type: DIAGNOSTIC_ANSWER_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const addExerciseAnswer = data => {
	return dispatch => {
		dispatch({
			type: Exercise_ANSWER_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/diagnostic/update-answer`, data)
			.then(response => {
				dispatch({
					type: Exercise_ANSWER,
					payload: response.data,
					question_id: data.question_id,
					id: data.id,
				});
			})
			.catch(error => {
				dispatch({
					type: Exercise_ANSWER_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getDiagnosticTest = (cat, lan, page = 1, positions=[]) => {
	return dispatch => {
		dispatch({
			type: DIAGNOSTIC_TEST_STATUS,
			payload: {
				status: 'started',
			},
		});
		let l = '';
		positions.forEach(item=> {
			l += 'positions[]='+item+'&';
		});
		getInstance()
			.get(
				`/api/v1/course/diagnostics/questions?${l}per_page=8&page=${page}&category_id=${cat}&language_id=${lan}&orderBy=created_at&dir=desc`,
			)
			.then(response => {
				dispatch({
					type: DIAGNOSTIC_TEST,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: DIAGNOSTIC_TEST_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getExerciseTest = (cat, lan, page = 1, positions=[]) => {
	return dispatch => {
		dispatch({
			type: Exercise_TEST_STATUS,
			payload: {
				status: 'started',
			},
		});
		let l = '';
		positions.forEach(item=> {
			l += 'positions[]='+item+'&';
		});
		getInstance()
			.get(
				`/api/v1/course/exercise/questions?${l}per_page=8&page=${page}&category_id=${cat}&language_id=${lan}&orderBy=created_at&dir=desc`,
			)
			.then(response => {
				dispatch({
					type: Exercise_TEST,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: Exercise_TEST_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getRegionList = () => {
	return dispatch => {
		// const state = useSelector(state => state);
		dispatch({
			type: REGIONS_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/profil/regions`)
			.then(response => {
				dispatch({
					type: REGIONS,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: REGIONS_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getCitiesList = (id = '') => {
	return dispatch => {
		dispatch({
			type: CITIES_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/profil/regions?parent_id=${id}`)
			.then(response => {
				dispatch({
					type: CITIES,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: CITIES_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getMenegerCounts = () => {
	return dispatch => {
		dispatch({
			type: MANAGER_COUNTS_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/admin/counts`)
			.then(response => {
				dispatch({
					type: MANAGER_COUNTS,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: MANAGER_COUNTS_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getMenegerCourse = (page = 1, filter) => {
	return dispatch => {
		// dispatch({
		//     type: MANAGER_COURSE_STATUS,
		//     payload: {
		//         status: 'started',
		//     }
		// });
		getInstance()
			.get(`/api/v1/admin/courses${filter}&page=${page}&per_page=20`)
			.then(response => {
				dispatch({
					type: MANAGER_COURSE,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: MANAGER_COURSE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getMenegerTargetCourse = (page = 1, filter) => {
	return dispatch => { 
		getInstance()
			.get(`/api/v1/admin/courses/target${filter}&page=${page}&per_page=20`)
			.then(response => {
				dispatch({
					type: MANAGER_COURSE,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: MANAGER_COURSE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getMenegerCertificates = (page = 1, filter) => {
	return dispatch => {
		dispatch({
			type: MANAGER_CERTIFICATE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/admin/regions${filter}&page=${page}&per_page=20`)
			.then(response => {
				dispatch({
					type: MANAGER_CERTIFICATE,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: MANAGER_CERTIFICATE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getPupils = (page = 1, filter = '?', user = 'admin', per_page = 20) => {
	return dispatch => {
		// dispatch({
		//     type: PUPILS_STATUS,
		//     payload: {
		//         status: 'started',
		//     }
		// });
		getInstance()
			.get(`/api/v1/${user}/users${filter}&page=${page}&per_page=${per_page}`)
			.then(response => {
				dispatch({
					type: PUPILS,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: PUPILS_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getTargetCoursePupils = (page = 1, filter = '?', slug = '', per_page = 20) => {
	return dispatch => {
		getInstance()
			.get(`/api/v1/courses/${slug}/target-statistics${filter}&page=${page}&per_page=${per_page}`)
			.then(response => {
				dispatch({
					type: PUPILS,
					payload: response?.data?.data??{},
				});
			})
			.catch(error => {
				dispatch({
					type: PUPILS_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getPupilsOffline = (page = 1, filter = '?', per_page = 20) => {
	return dispatch => {
		getInstance()
			.get(`/api/v1/edu-institutions/user-all${filter}&page=${page}&per_page=${per_page}`)
			.then(response => {
				dispatch({
					type: PUPILS,
					payload: response?.data?.data,
				});
			})
			.catch(error => {
				dispatch({
					type: PUPILS_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getStatistic = () => {
	return dispatch => {
		dispatch({
			type: STATISTIC_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/dashboard/categories`)
			.then(response => {
				dispatch({
					type: STATISTIC,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: STATISTIC_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getStatisticUser = () => {
	return dispatch => {
		dispatch({
			type: STATISTIC_USER_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/dashboard/user-hours`)
			.then(response => {
				dispatch({
					type: STATISTIC_USER,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: STATISTIC_USER_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const markNotification = id => {
	return dispatch => {
		getInstance()
			.post(`/api/v1/profil/mark-notifications`, { id: id })
			.then(response => {
				dispatch({
					type: NOTIFICATION_MARK,
					payload: id,
				});
			});
	};
};

export const getNotifications = (page = 1) => {
	return dispatch => {
		dispatch({
			type: NOTIFICATIONS_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/profil/notifications?page=${page}&per_page=20`)
			.then(response => {
				dispatch({
					type: NOTIFICATIONS,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: NOTIFICATIONS_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getUnreadCountNotification = () => {
	return dispatch => {
		getInstance()
			.get(`/api/v1/profil/unread-count`)
			.then(response => {
				dispatch({
					type: Unread_Count_Notification,
					payload: response.data,
				});
			});
	};
};

export const getLessonWebinarJoin = (course_slug, section_slug, lesson_id) => {
	return dispatch => {
		dispatch({
			type: LESSON_WEBINAR_JOIN_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/course/${course_slug}/sections/${section_slug}/lessons/${lesson_id}/join`)
			.then(response => {
				dispatch({
					type: LESSON_WEBINAR_JOIN,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: LESSON_WEBINAR_JOIN_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getLessonWebinarRecord = (course_slug, section_slug, lesson_id) => {
	return dispatch => {
		dispatch({
			type: LESSON_WEBINAR_RECORD_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/course/${course_slug}/sections/${section_slug}/lessons/${lesson_id}/record`)
			.then(response => {
				dispatch({
					type: LESSON_WEBINAR_RECORD,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: LESSON_WEBINAR_RECORD_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getTeacherList = (page = 1, per_page=12) => {
	return dispatch => {
		dispatch({
			type: TEACHER_LIST_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/teachers?page=${page}&per_page=${per_page}`)
			.then(response => {
				dispatch({
					type: TEACHER_LIST,
					payload: response.data,
				});
			})
			.catch(error => {
				oldToken(error);
				dispatch({
					type: TEACHER_LIST_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});

				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Kutilmagan xatolik.', type: 'e' },
				// });
			});
	};
};

export const sendChatMessages = (chat_id, data, manager_id = 0) => {
	return dispatch => {
		dispatch({
			type: CHAT_MESSAGE_SEND_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/chat/threads/${chat_id}/messages`, data)
			.then(response => {
				if (response.data.status === 1) {
					const m_id = _.get(response, 'data.data.id', false);
					const t_id = _.get(response, 'data.data.participation.conversation_id', false);
					const u_id = _.get(response, 'data.data.participation.messageable_id', false);
					if(m_id && t_id && u_id) 
						axios.post(`https://${WS_HOST}/chat/send-message`, {
							thread: chat_id, 
							message: m_id, 
							user: u_id,
							manager_id: manager_id
						})
					dispatch({
						type: CHAT_MESSAGE_SEND,
						payload: response.data,
					});
				} else {
					dispatch({
						type: CHAT_MESSAGE_SEND_STATUS,
						payload: {
							status: 'error',
						},
					});

					dispatch({
						type: S_M,
						payload: { text: 'Kutilmagan xatolik.', type: 'e' },
					});
				}
			})
			.catch(error => {
				dispatch({
					type: CHAT_MESSAGE_SEND_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});

				dispatch({
					type: S_M,
					payload: { text: 'Kutilmagan xatolik.', type: 'e' },
				});
			});
	};
};

export const getChatMessages = chat_id => {
	return dispatch => {
		dispatch({
			type: CHAT_MESSAGE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/chat/threads/${chat_id}/messages?page=1`)
			.then(response => {
				dispatch({
					type: CHAT_MESSAGE,
					payload: response.data?.data,
				});
			})
			.catch(error => {
				dispatch({
					type: CHAT_MESSAGE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});

				dispatch({
					type: S_M,
					payload: { text: 'Kutilmagan xatolik.', type: 'e' },
				});
			});
	};
};
export const getChatMessagesMore = next_page_url => {
	return dispatch => {
		dispatch({
			type: CHAT_MESSAGE_MORE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getMore()
			.get(next_page_url)
			.then(response => {
				dispatch({
					type: CHAT_MESSAGE_MORE,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: CHAT_MESSAGE_MORE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});

				dispatch({
					type: S_M,
					payload: { text: 'Kutilmagan xatolik.', type: 'e' },
				});
			});
	};
};

export const getChatMarkall = chat_id => {
	return dispatch => {
		// dispatch({
		//     type: CHAT_MESSAGE_STATUS,
		//     payload: {
		//         status: 'started'
		//     }
		// });
		getInstance()
			.post(`/api/v1/chat/threads/${chat_id}/markall`)
			.then(response => {
				// dispatch({
				//     type: CHAT_MESSAGE,
				//     payload: response.data
				// });
			})
			.catch(error => {
				// dispatch({
				//     type: CHAT_MESSAGE_STATUS,
				//     payload: {
				//         ...error,
				//         status: 'error',
				//     }
				// });
				// dispatch({
				//     type: S_M,
				//     payload: {text: 'Kutilmagan xatolik.', type: "e"}
				// });
			});
	};
};

export const getChatThreads = () => {
	return dispatch => {
		dispatch({
			type: CHAT_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/chat/threads?per_page=16`)
			.then(response => {
				dispatch({
					type: CHAT,
					payload: response.data?.data,
				});
			})
			.catch(error => {
				dispatch({
					type: CHAT_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				}); 
			});
	};
};
export const getChatThreadMore = (next_link) => {
	return dispatch => {
		dispatch({
			type: CHAT_MORE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getMore().get(next_link)
			.then(response => {
				dispatch({
					type: CHAT_MORE,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: CHAT_MORE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				}); 
			});
	};
};

// webinar list
export const getWebinarsList = (page = 1) => {
	return dispatch => {
		dispatch({
			type: WEBINARS_LIST_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/webinars?per_page=6&page=${page}`)
			.then(response => {
				dispatch({
					type: WEBINARS_LIST,
					payload: response.data,
				});
			})
			.catch(error => {
				oldToken(error);
				dispatch({
					type: WEBINARS_LIST_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
//my webinar
export const getMyWebinars = (page = 1) => {
	return dispatch => {
		dispatch({
			type: MY_WEBINAR_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/webinars?per_page=6&page=${page}&is_new=1`)
			.then(response => {
				dispatch({
					type: MY_WEBINAR,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: MY_WEBINAR_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

//webinar data
export const getWebinar = slug => {
	return dispatch => {
		dispatch({
			type: WEBINAR_DATA_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/webinars/${slug}`)
			.then(response => {
				dispatch({
					type: WEBINAR_DATA,
					payload: response.data,
				});
			})
			.catch(error => {
				oldToken(error);
				dispatch({
					type: WEBINAR_DATA_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
//teacher webinar
export const getTeacherWebinars = (page = 1) => {
	return dispatch => {
		dispatch({
			type: TEACHER_WEBINAR_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/webinars?per_page=6&page=${page}&my=1`)
			.then(response => {
				dispatch({
					type: TEACHER_WEBINAR,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: TEACHER_WEBINAR_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

//teacher webinar create or update
export const updateTeacherWebinar = data => {
	return dispatch => {
		dispatch({
			type: UPDATE_TEACHER_WEBINAR_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/webinars/create`, data)
			.then(response => {
				dispatch({
					type: UPDATE_TEACHER_WEBINAR,
					payload: response.data.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
				history.push('/dashboard/copyrights/webinars/list/1');
			})
			.catch(error => {
				dispatch({
					type: UPDATE_TEACHER_WEBINAR_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

//buy course
export const testCheck = (data, course_slug, section_slug, lesson_id, in_dash = true) => {
	return dispatch => {
		dispatch({
			type: TEST_CHECK_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/course/test-check`, data)
			.then(response => {
				dispatch({
					type: TEST_CHECK,
					payload: response.data,
					id: data.id,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Курс начался.', type: 's' },
				// });
				// if(in_dash){
				//     history.push(`/dashboard/courses/view/${course_slug}/${section_slug}/test/${lesson_id}/result`);
				// }else{
				//     history.push(`/courses/view/${course_slug}/${section_slug}/test/${lesson_id}/result`);
				// }
			})
			.catch(error => {
				dispatch({
					type: TEST_CHECK_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Курс не начался.', type: 'e' },
				// });
			});
	};
};

// my course
export const getMyCourses = (page = 1) => {
	return dispatch => {
		dispatch({
			type: MY_COURSES_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/courses/my?buyed=1&per_page=6&page=${page}`)
			.then(response => {
				dispatch({
					type: MY_COURSES,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: MY_COURSES_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getMyCoursesTarget = (page = 1) => {
	return dispatch => {
		dispatch({
			type: MY_COURSES_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/courses/target?per_page=6&page=${page}`)
			.then(response => {
				dispatch({
					type: MY_COURSES,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: MY_COURSES_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

//course lesson
export const getCourseLesson = (course_slug, section_slug, lesson_id) => {
	return dispatch => {
		dispatch({
			type: COURSE_LESSON_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/course/${course_slug}/sections/${section_slug}/lessons/${lesson_id}`)
			.then(response => {
				dispatch({
					type: COURSE_LESSON,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: COURSE_LESSON_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
//buy course
export const courseBuy = data => {
	return dispatch => {
		dispatch({
			type: COURSE_BUY_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/course-buy`, data)
			.then(response => {
				dispatch({
					type: COURSE_BUY,
					payload: response.data.data,
					id: data.id,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Курс начался.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: COURSE_BUY_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Курс не начался.', type: 'e' },
				});
			});
	};
};
//course sections
export const getCourseSections = (slug, section='') => {
	return dispatch => {
		dispatch({
			type: COURSE_SECTIONS_STATUS,
			payload: {
				status: 'started',
				slug: slug,
			},
		});
		getInstance()
			.get(`/api/v1/course/${slug}/sections${section?'?section_slug='+section:''}`)
			.then(response => {
				dispatch({
					type: COURSE_SECTIONS,
					payload: response.data,
					slug: slug,
				});
			})
			.catch(error => {
				oldToken(error);
				dispatch({
					type: COURSE_SECTIONS_STATUS,
					payload: {
						...error,
						status: 'error',
						slug: slug,
					},
				});
			});
	};
};

//upgrade
export const getUpgredeCategorys = (type='upgrade') => {
	return dispatch => {
		dispatch({
			type: UPGRADE_CAT_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/upgrade/categories?type=`+type)
			.then(response => {
				dispatch({
					type: UPGRADE_CAT,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: UPGRADE_CAT_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};



//retraining
export const getRetrainingCategorys = () => {
	return dispatch => {
		dispatch({
			type: RETRAINING_CAT_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/retraining/categories`)
			.then(response => {
				dispatch({
					type: RETRAINING_CAT,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: RETRAINING_CAT_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const getRetrainingCourse = (slug = '', page = 1) => {
	return dispatch => {
		dispatch({
			type: RETRAINING_COURSE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/courses?category_slug=${slug}&per_page=6&page=${page}`)
			.then(response => {
				dispatch({
					type: RETRAINING_COURSE,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: RETRAINING_COURSE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

//teacher
export const getTeacherCourses = (page = 1, search = '') => {
	return dispatch => {
		dispatch({
			type: TEACHER_COURSE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/teacher/courses?per_page=6&page=${page}${search}`)
			.then(response => {
				dispatch({
					type: TEACHER_COURSE,
					payload: response?.data?.data??{},
				});
			})
			.catch(error => {
				dispatch({
					type: TEACHER_COURSE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getTeacherCategorys = (type = 'upgrade', position = 3, lan = 1) => {
	return dispatch => {
		dispatch({
			type: TEACHER_CATEGORY_STATUS,
			payload: {
				status: 'started',
			},
		});
		const category_url =`/api/v1/teacher/categories`;
		getInstance()
			.get(category_url)
			.then(response => {
				dispatch({
					type: TEACHER_CATEGORY,
					payload: response?.data?.data??[],
				});
			})
			.catch(error => {
				dispatch({
					type: TEACHER_CATEGORY_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getTeacherLanguages = () => {
	return dispatch => {
		dispatch({
			type: TEACHER_LAN_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/teacher/languages`)
			.then(response => {
				dispatch({
					type: TEACHER_LAN,
					payload: response?.data??{},
				});
			})
			.catch(error => {
				dispatch({
					type: TEACHER_LAN_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const postTeacherCourse = (data, id = null, link = '', fettle = false, is_redirect = true) => {
	return dispatch => {
		dispatch({
			type: TEACHER_COURSE_CHANGE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/teacher/create-course${id ? '?id=' + id : ''}`, data)
			.then(response => {
				dispatch({
					type: TEACHER_COURSE_CHANGE,
					payload: response.data,
				});
				if (fettle) {
					dispatch({
						type: TEACHER_COURSE_STATUS_UPDATE,
						id: id,
					});
				}

				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });

				if(is_redirect){
					if (link && link !== '') {
						history.push(link);
					} else {
						history.push('/dashboard/copyrights/course-update/' + response.data.data.id);
					}
				}
			})
			.catch(error => {
				dispatch({
					type: TEACHER_COURSE_CHANGE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});

				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
export const getTeacherUpdateCourse = id => {
	return dispatch => {
		dispatch({
			type: GET_UPDATE_COURSE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/teacher/courses/${id}`)
			.then(response => {
				dispatch({
					type: GET_UPDATE_COURSE,
					payload: response.data,
				});
			})
			.catch(error => {
				dispatch({
					type: GET_UPDATE_COURSE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
//teacher course sections
export const getTeacherCourseSections = id => {
	return dispatch => {
		dispatch({
			type: GET_TEACHER_COURSE_SECTIONS_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/teacher/courses/${id}/sections`)
			.then(response => {
				dispatch({
					type: GET_TEACHER_COURSE_SECTIONS,
					payload: response?.data?.data,
				});
			})
			.catch(error => {
				dispatch({
					type: GET_TEACHER_COURSE_SECTIONS_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

export const createSection = data => {
	return dispatch => {
		dispatch({
			type: CREATE_SECTION_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/teacher/create-section`, data)
			.then(response => {
				dispatch({
					type: CREATE_SECTION,
					payload: response.data.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: CREATE_SECTION_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const deleteSection = data => {
	return dispatch => {
		dispatch({
			type: DELETE_SECTION_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/teacher/delete-section`, { id: data.id })
			.then(response => {
				dispatch({
					type: DELETE_SECTION,
					payload: data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: DELETE_SECTION_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
export const updateSection = data => {
	return dispatch => {
		dispatch({
			type: UPDATE_SECTION_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/update-section`, { id: data.id, name: data.name })
			.then(response => {
				dispatch({
					type: UPDATE_SECTION,
					payload: response.data.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: UPDATE_SECTION_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const orderSection = (data, link = '') => {
	return dispatch => {
		dispatch({
			type: ORDER_SECTION_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/order-section`, data)
			.then(response => {
				dispatch({
					type: ORDER_SECTION,
					payload: response.data.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
				if (link && link !== '') {
					history.push(link);
				}
			})
			.catch(error => {
				dispatch({
					type: ORDER_SECTION_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

//teacher course lessons
export const getTeacherLesson = (course_id, section_id) => {
	return dispatch => {
		dispatch({
			type: TEACHER_LESSONS_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/teacher/courses/${course_id}/sections/${section_id}/lessons`)
			.then(response => {
				dispatch({
					type: TEACHER_LESSONS,
					payload: response?.data?.data,
				});
				// if(response.data.data&&response.data.data.length>0){
				//     history.push(`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson/${response.data.data[0].lesson_type.type}`);
				// }
			})
			.catch(error => {
				dispatch({
					type: TEACHER_LESSONS_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const createLesson = data => {
	return dispatch => {
		dispatch({
			type: CREATE_LESSON_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/create-lesson`, data)
			.then(response => {
				dispatch({
					type: CREATE_LESSON,
					payload: response?.data?.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
				history.push(
					`/dashboard/copyrights/course-items/${data.course_id}/section/${data.section_id}/lesson/${
						response.data.data.lesson_type && response.data.data.lesson_type.type
							? response.data.data.lesson_type.type
							: ''
					}`,
				);
			})
			.catch(error => {
				dispatch({
					type: CREATE_LESSON_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
export const deleteLesson = lesson_id => {
	return dispatch => {
		dispatch({
			type: DELETE_LESSON_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/teacher/delete-lesson`, { id: lesson_id })
			.then(response => {
				dispatch({
					type: DELETE_LESSON,
					payload: response.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: DELETE_LESSON_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const updateLessonText = (data, redirect) => {
	return dispatch => {
		dispatch({
			type: UPDATE_LESSON_TEXT_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/update-text`, data)
			.then(response => {
				dispatch({
					type: UPDATE_LESSON_TEXT,
					payload: response.data.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
				if (redirect !== '') {
					history.push('/dashboard/copyrights/course-items/' + redirect);
				}
			})
			.catch(error => {
				dispatch({
					type: UPDATE_LESSON_TEXT_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const updateLessonVideo = (data, redirect) => {
	return dispatch => {
		dispatch({
			type: UPDATE_LESSON_VIDEO_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/update-video`, data)
			.then(response => {
				dispatch({
					type: UPDATE_LESSON_VIDEO,
					payload: response.data.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
				if (redirect !== '') {
					history.push('/dashboard/copyrights/course-items/' + redirect);
				}
			})
			.catch(error => {
				dispatch({
					type: UPDATE_LESSON_VIDEO_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const updateLessonWebinar = (data, redirect) => {
	return dispatch => {
		dispatch({
			type: UPDATE_LESSON_WEBINAR_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/update-webinar`, data)
			.then(response => {
				dispatch({
					type: UPDATE_LESSON_WEBINAR,
					payload: response.data.data,
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
				if (redirect !== '') {
					history.push('/dashboard/copyrights/course-items/' + redirect);
				}
			})
			.catch(error => {
				dispatch({
					type: UPDATE_LESSON_WEBINAR_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const updateLessonQuestion = (data, is_video_question=false, is_exam=false) => {
	return dispatch => {
		dispatch({
			type: UPDATE_LESSON_QUESTION_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/${is_exam?"manager/exams":"teacher/lesson"}/update-question`, data)
			.then(response => {
				if(is_video_question)
					dispatch({
						type: UPDATE_VIDEO_QUESTION,
						payload: response.data.data,
						id: data.id,
					});
				else
					dispatch({
						type: UPDATE_LESSON_QUESTION,
						payload: response?.data?.data,
						id: data.id,
					});
					document.getElementById('question'+response?.data?.data?.id).scrollIntoView({
						behavior: 'auto',
						block: 'center',
						inline: 'center'
					});
			})
			.catch(error => {
				dispatch({
					type: UPDATE_LESSON_QUESTION_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Kutilmagan xatolik.', type: 'e' },
				});
			});
	};
};
export const updateLessonAnswerDragWords = answers_data => {
	return dispatch => {
		dispatch({
			type: UPDATE_DRAG_WORDS_ANSWER_STATUS,
			payload: {
				status: 'started'
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/update-question-dragwords`, answers_data)
			.then(response => {
				const {data} = response;
				console.log(answers_data);
				const a = JSON.parse(answers_data.answers);
				let answers = [];
				a.forEach(item=>{
					answers.push({
						answer: item.answer,
						details: item.tag
					});
				});
				answers_data.wrongwords.split(",").forEach(item=>{
					answers.push({
						answer: item,
						details: 'wrong'
					});
				});
				dispatch({
					type: UPDATE_DRAG_WORDS_ANSWER,
					question_id: answers_data.question_id,
					data: data,
					payload: {
						details: answers_data.question_text,
						score: answers_data.score,
						answers: answers
					}
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: UPDATE_DRAG_WORDS_ANSWER_STATUS,
					payload: {
						status: 'error'
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
export const updateLessonAnswerGrouping = answers_data => {
	return dispatch => {
		dispatch({
			type: UPDATE_GROUPING_ANSWER_STATUS,
			payload: {
				status: 'started'
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/update-question-grouping`, answers_data)
			.then(response => {
				const {data} = response;
				let l = [];
				let total_score = 0;
				JSON.parse( answers_data.answers ).forEach( (item, index)=>{
					l.push({...item, id: data[index], score: item.score.toString()});
					total_score += parseInt( item.score );
				});
				dispatch({
					type: UPDATE_GROUPING_ANSWER,
					question_id: answers_data.question_id,
					data: l,
					total_score: total_score
				});
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: UPDATE_GROUPING_ANSWER_STATUS,
					payload: {
						status: 'error'
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
export const updateLessonAnswer = (data, is_video_question=false, is_exam=false) => {
	return dispatch => {
		dispatch({
			type: UPDATE_LESSON_ANSWER_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/${is_exam?"manager/exams":"teacher/lesson"}/update-answer`, data)
			.then(response => {
				if(is_video_question)
					dispatch({
						type: UPDATE_VIDEO_ANSWER,
						payload: response.data.data,
						id: data.id,
						question_id: data.question_id,
					});
				else
					dispatch({
						type: UPDATE_LESSON_ANSWER,
						payload: response.data.data,
						id: data.id,
						question_id: data.question_id,
					});
					document.getElementById('answer'+response?.data?.data?.id).scrollIntoView({
						behavior: 'auto',
						block: 'center',
						inline: 'center'
					});
			})
			.catch(error => {
				dispatch({
					type: UPDATE_LESSON_ANSWER_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const deleteQuestion = (data, is_video_question=false, is_exam=false) => {
	return dispatch => {
		dispatch({
			type: DELETE_QUESTION_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/${is_exam?"manager/exams":"teacher/lesson"}/delete-question`, data)
			.then(response => {
				if(is_video_question)
					dispatch({
						type: DELETE_VIDEO_QUESTION,
						payload: response.data.data,
						id: data.id,
					});
				else
					dispatch({
						type: DELETE_QUESTION,
						payload: response.data.data,
						id: data.id,
					});
			})
			.catch(error => {
				dispatch({
					type: DELETE_QUESTION_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
export const deleteVideoQuestionTime = (data) => {
	return dispatch => {
		dispatch({
			type: DELETE_VIDEO_TIME_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/delete-question-time`, data)
			.then(response => {
				dispatch({
					type: DELETE_VIDEO_TIME,
					payload: response.data,
					time: data.time,
				});
			})
			.catch(error => {
				dispatch({
					type: DELETE_VIDEO_TIME_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Kutilmagan xatolik.', type: 'e' },
				});
			});
	};
};

export const updatedVideoQuestionTime = (data) => {
	return dispatch => {
		dispatch({
			type: UPDATE_VIDEO_QUESTION_TIME_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/teacher/lesson/update-time`, data)
			.then(response => {
				dispatch({
					type: UPDATE_VIDEO_QUESTION_TIME,
					payload: response.data,
					time: data.time,
					questions: data.questions
				});
			})
			.catch(error => {
				dispatch({
					type: UPDATE_VIDEO_QUESTION_TIME_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Kutilmagan xatolik.', type: 'e' },
				});
			});
	};
};
export const deleteAnswer = (data, is_video_question=false, is_exam=false) => {
	return dispatch => {
		dispatch({
			type: DELETE_ANSWER_STATUS,
			payload: {
				status: 'started',
				id: data.id,
			},
		});
		getInstance()
			.post(`/api/v1/${is_exam?"manager/exams":"teacher/lesson"}/delete-answer`, data)
			.then(response => {
				if(is_video_question)
					dispatch({
						type: DELETE_VIDEO_ANSWER,
						payload: response.data.data,
						id: data.id,
						question_id: data.question_id,
					});
				else
					dispatch({
						type: DELETE_ANSWER,
						payload: response.data.data,
						id: data.id,
						question_id: data.question_id,
					});
			})
			.catch(error => {
				dispatch({
					type: DELETE_ANSWER_STATUS,
					payload: {
						...error,
						status: 'error',
						id: data.id,
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const getTeacherLessonQuestions = (course_id, section_id, lesson_id) => {
	return dispatch => {
		dispatch({
			type: GET_TECH_LESSON_QUESTION_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/teacher/courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/questions`)
			.then(response => {
				dispatch({
					type: GET_TECH_LESSON_QUESTION,
					payload: response.data.data,
				});
			})
			.catch(error => {
				dispatch({
					type: GET_TECH_LESSON_QUESTION_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const getExamsQuestions = (id) => {
	return dispatch => {
		dispatch({
			type: GET_TECH_LESSON_QUESTION_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/manager/exams/${id}/questions`)
			.then(response => {
				dispatch({
					type: GET_TECH_LESSON_QUESTION,
					payload: response.data.data,
				});
			})
			.catch(error => {
				dispatch({
					type: GET_TECH_LESSON_QUESTION_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

// auth
export const signIn = (data, remember = false) => {
	return dispatch => {
		dispatch({
			type: SIGN_STATUS,
			payload: {
				status: 'started',
			},
		});
		getNotAuthInstance()
			.post(`/api/v1/auth/login`, data)
			.then(response => {
				if (response.status === 200) {
					const role = 3;
					localStorage.setItem('role', role);
					setToken(response?.data?.data?.access_token, remember);
					dispatch({
						type: SIGN_STATUS,
						payload: {
							status: 'success',
						},
					});
					dispatch({
						type: PROFILE,
						payload: response?.data?.data?.user??{},
					});
				} else {
					dispatch({
						type: SIGN_STATUS,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			})
			.catch(error => {
				if (error.response && error.response.status && error.response.status === 401) {
					dispatch({
						type: SIGN_STATUS,
						payload: {
							status: 'error',
							error_msg: '401',
						},
					});
				} else {
					dispatch({
						type: SIGN_STATUS,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			});
	};
};
export const updatePassword = data => {
	return dispatch => {
		dispatch({
			type: PASSWORD_UPDATE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/profil/update-password`, data)
			.then(response => {
				if (response.data.success === 1) {
					dispatch({
						type: PASSWORD_UPDATE,
						payload: response.data,
					});
					dispatch({
						type: S_M,
						payload: { text: 'Успешный процесс.', type: 's' },
					});
				} else {
					dispatch({
						type: PASSWORD_UPDATE,
						payload: response.data,
					});
					dispatch({
						type: PASSWORD_UPDATE_STATUS,
						payload: { status: 'error' },
					});
					dispatch({
						type: S_M,
						payload: { text: 'Неудачный процесс.', type: 'e' },
					});
				}
			})
			.catch(error => {
				dispatch({
					type: PASSWORD_UPDATE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
export const forgotPassword = data => {
	return dispatch => {
		dispatch({
			type: FORGOT_PASSWORD_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/forgotpassword`, data)
			.then(response => {
				if (response.data.success === 1) {
					sessionStorage.setItem('phone', data.login);
					dispatch({
						type: FORGOT_PASSWORD,
						payload: response.data,
					});
				}if( response.data.is_telegram ){
					sessionStorage.setItem('bot_url', response.data.bot_url);
					sessionStorage.setItem('phone', data.login);
					dispatch({
						type: FORGOT_PASSWORD,
						payload: response.data,
					});
				} else {
					dispatch({
						type: FORGOT_PASSWORD,
						payload: response.data,
					});
					dispatch({
						type: FORGOT_PASSWORD_STATUS,
						payload: { status: 'error' },
					});
					dispatch({
						type: S_M,
						payload: { text: 'Неудачный процесс.', type: 'e' },
					});
				}
			})
			.catch(error => {
				dispatch({
					type: FORGOT_PASSWORD_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
export const forgotValidateSMS = data => {
	return dispatch => {
		dispatch({
			type: FORGOT_VALIDATE_SMS,
			payload: {
				status: 'started',
			},
		});
		getNotAuthInstance()
			.post(`/api/v1/forgotpasswordaccept`, data)
			.then(response => {
				if (response.status === 200) {
					if (response.data.access_token) {
						setToken(response.data.access_token, true);
						// localStorage.setItem('role', response.data.user.role_id ? response.data.user.role_id : 0);
						const role =
							response.data.user && response.data.user.is_teacher === 1 && response.data.user.role_id
								? response.data.user.role_id
								: 0;
						localStorage.setItem('role', role);
						dispatch({
							type: FORGOT_VALIDATE_SMS,
							payload: {
								status: 'success',
							},
						});
						dispatch({
							type: PROFILE,
							payload: response.data.user,
						});
					} else {
						dispatch({
							type: FORGOT_VALIDATE_SMS,
							payload: {
								status: 'error',
								error_msg: 'Kodda xatolik bor',
							},
						});
					}
				} else {
					dispatch({
						type: FORGOT_VALIDATE_SMS,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			})
			.catch(error => {
				dispatch({
					type: FORGOT_VALIDATE_SMS,
					payload: {
						status: 'error',
						error_msg: 'Неудачный процесс.',
						error: error,
					},
				});
			});
	};
};
export const newPassword = (data, msg) => {
	return dispatch => {
		dispatch({
			type: NEW_PASSWORD,
			payload: {
				status: 'started',
			},
		});
		getNotAuthInstance()
			.post(`/api/v1/accept`, data)
			.then(response => {
				if (response.status === 201) {
					if (response.data.success === 1) {
						setToken(response.data.data.token, true);
						const role = (_.get(response, 'data.data.is_teacher', 0)  === 1 &&
						 _.get(response, 'data.data.role_id', false))||_.get(response, 'data.data.role_id', 0)===5
							? response.data.data.role_id
							: 0;
						localStorage.setItem('role', role);
						dispatch({
							type: PROFILE,
							payload: response.data.data.user,
						});
						dispatch({
							type: NEW_PASSWORD,
							payload: {
								status: 'success',
							},
						});
						dispatch({
							type: 'SET_WARNING',
							payload: msg
						});
					} else {
						dispatch({
							type: NEW_PASSWORD,
							payload: {
								status: 'error',
								error_msg: 'Texnik nosozlik',
							},
						});
					}
				} else {
					dispatch({
						type: NEW_PASSWORD,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			})
			.catch(error => {
				dispatch({
					type: NEW_PASSWORD,
					payload: {
						status: 'error',
						error_msg: 'Неудачный процесс.',
						error: error,
					},
				});
			});
	};
};
export const forgotNewPassword = data => {
	return dispatch => {
		dispatch({
			type: FORGOT_NEW_PASSWORD,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/forgotupdatepassword`, data)
			.then(response => {
				if (response.status === 201) {
					if (response.data.status) {
						dispatch({
							type: FORGOT_NEW_PASSWORD,
							payload: {
								status: 'success',
							},
						});
					} else {
						dispatch({
							type: FORGOT_NEW_PASSWORD,
							payload: {
								status: 'error',
								error_msg: 'Texnik nosozlik',
							},
						});
					}
				} else {
					dispatch({
						type: FORGOT_NEW_PASSWORD,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			})
			.catch(error => {
				dispatch({
					type: FORGOT_NEW_PASSWORD,
					payload: {
						status: 'error',
						error_msg: 'Неудачный процесс.',
						error: error,
					},
				});
			});
	};
};
export const logOut = () => {
	return dispatch => {
		removeToken();
		localStorage.removeItem('role');
		dispatch({
			type: LOG_OUT,
		});
		history.push('/');
	};
};
export const signUp = data => {
	return dispatch => {
		dispatch({
			type: SIGNUP_STATUS,
			payload: {
				status: 'started',
			},
		});
		getNotAuthInstance()
			.post(`/api/v1/register`, data)
			.then(response => {
				if (response.status === 200) {
					sessionStorage.setItem('phone', data.login);
					// sessionStorage.setItem('password', data.password);
					if (response.data.success === 1) {
						dispatch({
							type: SIGNUP_STATUS,
							payload: {
								status: 'success',
								// error_msg: 'success'
							},
						});
					}if(response.data.is_telegram){
						sessionStorage.setItem('bot_url', response.data.bot_url);
						dispatch({
							type: SIGNUP_STATUS,
							payload: {
								status: 'success_bot',
							},
						});
					} else {
						dispatch({
							type: SIGNUP_STATUS,
							payload: {
								status: 'error',
								error_msg: 'Телефонный номер предварительно зарегистрирован',
							},
						});
					}
				} else {
					dispatch({
						type: SIGNUP_STATUS,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			})
			.catch(error => {
				if (error?.response?.status === 422) {
					dispatch({
						type: SIGNUP_STATUS,
						payload: {
							status: 'error',
							error_msg: 'Телефонный номер предварительно зарегистрирован',
						},
					});
				} else {
					dispatch({
						type: SIGNUP_STATUS,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			});
	};
};
export const validateSMS = data => {
	return dispatch => {
		dispatch({
			type: VALIDATE_SMS,
			payload: {
				status: 'started',
			},
		});
		getNotAuthInstance()
			.post(`/api/v1/check-sms`, data)
			.then(response => {
				if (response.status === 201) {
					if (response.data.success === 1) {
						dispatch({
							type: VALIDATE_SMS,
							payload: {
								status: 'success',
							},
						});
					} else {
						dispatch({
							type: VALIDATE_SMS,
							payload: {
								status: 'error',
								error_msg: 'Kodda xatolik bor',
							},
						});
					}
				} else {
					dispatch({
						type: VALIDATE_SMS,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({
						type: VALIDATE_SMS,
						payload: {
							status: 'error',
							error_msg: 'Ошибка номера телефона или пароля',
						},
					});
				} else {
					dispatch({
						type: VALIDATE_SMS,
						payload: {
							status: 'error',
							error_msg: 'Texnik nosozlik',
						},
					});
				}
			});
	};
};

// profiel
export const getProfile = () => {
	return dispatch => {
		dispatch({
			type: PROFILE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/auth/me`)
			.then(response => {
				// const role =
				// 	(_.get(response, 'data.data.is_teacher', 0)  === 1 && _.get(response, 'data.data.role_id', false))||_.get(response, 'data.data.role_id', 0)===5
				// 		? response.data.data.role_id
				// 		: 0;
				const role = _.get(response, 'data.role_id', 1);
				localStorage.setItem('role', role);

				dispatch({
					type: PROFILE,
					payload: response.data,
				});
			})
			.catch(error => {
				oldToken(error);
				dispatch({
					type: PROFILE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};
export const updateProfile = (data, msg) => {
	console.log(msg);
	return dispatch => {
		dispatch({
			type: PROFILE_UPDATE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.post(`/api/v1/profil/update`, data)
			.then(response => {
				dispatch({
					type: PROFILE_UPDATE,
					payload: response.data,
				});
				dispatch({
					type: 'SET_WARNING',
					payload: msg
				});
			})
			.catch(error => {
				dispatch({
					type: PROFILE_UPDATE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

//courses - home page
export const getCourses = () => {
	return dispatch => {
		// dispatch({
		//     type: 'TYPE',
		// });

		getInstance()
			.get(`/api/v1/courses-home?per_page=6&category_type=paid&is_home=1`)
			.then(response => {
				dispatch({
					type: COURSES_DATA,
					payload: response.data,
				});
			})
			.catch(error => {
				oldToken(error);
				dispatch({
					type: COURSES_ERROR,
					payload: error,
				});
			});
	};
};
//courses - course page
export const getCoursesPage = (page = 1, search = '') => {
	return dispatch => {
		dispatch({
			type: CAT_COURSES_START,
		});
		getInstance()
			.get(`/api/v1/courses-home?per_page=6&category_type=paid&page=${page}${search}`)
			.then(response => {
				dispatch({
					type: CAT_COURSES_DATA,
					payload: response.data,
				});
			})
			.catch(error => {
				oldToken(error);
				dispatch({
					type: CAT_COURSES_ERROR,
					payload: error,
				});
			});
	};
};
export const getCoursesSearch = (search = '', page = 1) => {
	return dispatch => {
		dispatch({
			type: CAT_COURSES_START,
		});
		getInstance()
			.get(`/api/v1/courses-home?per_page=6&category_type=paid&page=${page}&search=${search}`)
			.then(response => {
				dispatch({
					type: CAT_COURSES_DATA,
					payload: response.data,
				});
				history.push(`/courses/1?search=${search}`);
			})
			.catch(error => {
				dispatch({
					type: CAT_COURSES_ERROR,
					payload: error,
				});
			});
	};
};
export const getCourse = slug => {
	return dispatch => {
		dispatch({
			type: COURSE_STATUS,
			payload: {
				status: 'started',
			},
		});
		getInstance()
			.get(`/api/v1/course/${slug}`)
			.then(response => {
				dispatch({
					type: COURSE,
					payload: response.data,
				});
			})
			.catch(error => {
				oldToken(error);
				dispatch({
					type: COURSE_STATUS,
					payload: {
						...error,
						status: 'error',
					},
				});
			});
	};
};

//  messages
export const set_message = message => {
	return dispatch => {
		dispatch({
			type: S_M,
			payload: {
				...message,
			},
		});
	};
};
export const close_message = time => {
	return dispatch => {
		dispatch({
			type: C_M,
			payload: time,
		});
	};
};

export const addEducation = data => {
	return dispatch => {
		getInstance()
			.post('/api/v1/profil/update-education', data)
			.then(response => {
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const addWorkPlace = data => {
	return dispatch => {
		getInstance()
			.post('/api/v1/profil/update-work-place', data)
			.then(response => {
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const deleteWorkPlace = data => {
	return dispatch => {
		getInstance()
			.post('/api/v1/profil/delete-work-place', data)
			.then(response => {
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const deleteEducation = data => {
	return dispatch => {
		getInstance()
			.post('/api/v1/profil/delete-education', data)
			.then(response => {
				// dispatch({
				// 	type: S_M,
				// 	payload: { text: 'Успешный процесс.', type: 's' },
				// });
			})
			.catch(error => {
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};

export const deleteFile = data => {
	return dispatch => {
		getInstance()
			.post('/api/v1/teacher/lesson/delete-file', data)
			.then(response => { 
				dispatch({
					type: S_M,
					payload: { text: 'Успешный процесс.', type: 's' },
				}); 
			})
			.catch(error => {
				dispatch({
					type: S_M,
					payload: { text: 'Неудачный процесс.', type: 'e' },
				});
			});
	};
};
