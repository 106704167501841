import React from 'react';
import { connect } from "react-redux";
import {logOut} from "../../actions";
import { useTranslation } from 'react-i18next';

const  LogOut = (props) => {
    const { t } = useTranslation();
    return (<>
        <div class="modal fade" id="modalLogout" tabindex="-1" role="dialog" aria-labelledby="modalLogoutTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{t('log_out.title')}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span class="icon icon-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label for="" class="mb-6 text-center">{t('log_out.text')}</label>
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn btn-lighter btn-sm w-100"  data-dismiss="modal">{t('log_out.cancel')}</button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-danger btn-sm w-100" data-dismiss="modal"
                                    onClick={()=>props.logOut()}
                                >
                                    {t('log_out.log_out')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default  connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        logOut: ()=>dispatch(logOut()) 
    })
)(LogOut);