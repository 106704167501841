import React, { useState } from "react";
import ChartSpider from "./ChartSpider";
import ChartColumnH from "./ChartColumnH";
import { useTranslation } from "react-i18next";

const ResultPupil = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const [chart, setChart] = useState(0);
  return (
    <div class="card">
      <div class="card-body">
        <div className="row">
          <div
            className="col-12 col-md-5 d-flex "
            style={{ alignItems: "center" }}
          >
            <div>
              {data.map((cat, index) => {
                return (
                  <div className="result_pupil_item" key={index}>
                    {index + 1}. {cat.name}:{" "}
                    <span style={{ color: "#2962FF" }}>{cat?.persent}%</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div>
              <div className="setting__buttons">
                <label className="switch-text">
                  <input type="checkbox" checked={chart === 0 ? false : true} />
                  <span className="slider"></span>
                  <div className="switch-labels">
                    <span className="switch-label" onClick={() => setChart(0)}>
                      <img
                        style={{ width: 20 }}
                        src="/images/spider.svg"
                        alt="spider"
                      />
                    </span>
                    <span className="switch-label" onClick={() => setChart(1)}>
                      <img
                        style={{ width: 20 }}
                        src="/images/bar.svg"
                        alt="spider"
                      />
                    </span>
                  </div>
                </label>
              </div>
            </div>
            <div className="d-flex" style={{ alignItems: "center" }}>
              {chart ? (
                <ChartColumnH data={data} />
              ) : (
                <ChartSpider data={data} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResultPupil;
