import {
	S_M,
	C_M,
	COURSES_DATA,
	COURSES_ERROR,
	CAT_COURSES_DATA,
	/*CAT_COURSES_ERROR,*/ CAT_COURSES_START,
	SIGN_STATUS,
	LOG_OUT,
	SIGNUP_STATUS,
	VALIDATE_SMS,
	PROFILE,
	PROFILE_STATUS,
	PROFILE_UPDATE_STATUS,
	PROFILE_UPDATE,
	COURSE_STATUS,
	COURSE,
	TEACHER_COURSE,
	TEACHER_COURSE_STATUS,
	TEACHER_CATEGORY,
	TEACHER_CATEGORY_STATUS,
	TEACHER_LAN,
	TEACHER_LAN_STATUS,
	TEACHER_COURSE_CHANGE_STATUS,
	TEACHER_COURSE_CHANGE,
	GET_UPDATE_COURSE_STATUS,
	GET_UPDATE_COURSE,
	GET_TEACHER_COURSE_SECTIONS_STATUS,
	GET_TEACHER_COURSE_SECTIONS,
	CREATE_SECTION_STATUS,
	CREATE_SECTION,
	DELETE_SECTION_STATUS,
	DELETE_SECTION,
	UPDATE_SECTION_STATUS,
	UPDATE_SECTION,
	ORDER_SECTION_STATUS,
	ORDER_SECTION,
	TEACHER_LESSONS_STATUS,
	TEACHER_LESSONS,
	CREATE_LESSON_STATUS,
	CREATE_LESSON,
	UPDATE_LESSON_TEXT_STATUS,
	UPDATE_LESSON_TEXT,
	UPDATE_LESSON_VIDEO_STATUS,
	UPDATE_LESSON_VIDEO,
	UPDATE_LESSON_WEBINAR_STATUS,
	UPDATE_LESSON_WEBINAR,
	UPDATE_LESSON_QUESTION_STATUS,
	UPDATE_LESSON_QUESTION,
	UPDATE_LESSON_ANSWER_STATUS,
	UPDATE_LESSON_ANSWER,
	GET_TECH_LESSON_QUESTION_STATUS,
	GET_TECH_LESSON_QUESTION,
	DELETE_QUESTION,
	DELETE_QUESTION_STATUS,
	DELETE_ANSWER,
	DELETE_ANSWER_STATUS,
	UPGRADE_CAT_STATUS,
	UPGRADE_CAT,
	UPGRADE_COURSE_STATUS,
	UPGRADE_COURSE,
	TEACHER_COURSE_STATUS_UPDATE,
	COURSE_BUY_STATUS,
	COURSE_BUY,
	RETRAINING_CAT_STATUS,
	RETRAINING_CAT,
	RETRAINING_COURSE,
	RETRAINING_COURSE_STATUS,
	COURSE_SECTIONS_STATUS,
	COURSE_SECTIONS,
	COURSE_LESSON_STATUS,
	COURSE_LESSON,
	MY_COURSES_STATUS,
	MY_COURSES,
	TEST_CHECK_STATUS,
	TEST_CHECK,
	UPDATE_TEACHER_WEBINAR_STATUS,
	UPDATE_TEACHER_WEBINAR,
	TEACHER_WEBINAR_STATUS,
	TEACHER_WEBINAR,
	WEBINAR_DATA_STATUS,
	WEBINAR_DATA,
	MY_WEBINAR_STATUS,
	MY_WEBINAR,
	WEBINARS_LIST_STATUS,
	WEBINARS_LIST,
	CHAT_STATUS,
	CHAT,
	SEARCH,
	CHAT_MESSAGE_STATUS,
	CHAT_MESSAGE,
	CHAT_MESSAGE_SEND_STATUS,
	CHAT_MESSAGE_SEND,
	/*CHAT_MESSAGE_MORE_STATUS,*/ CHAT_MESSAGE_MORE,
	TEACHER_LIST_STATUS,
	TEACHER_LIST,
	LESSON_WEBINAR_JOIN_STATUS,
	LESSON_WEBINAR_JOIN,
	LESSON_WEBINAR_RECORD_STATUS,
	LESSON_WEBINAR_RECORD,
	DELETE_LESSON_STATUS,
	DELETE_LESSON,
	PASSWORD_UPDATE_STATUS,
	PASSWORD_UPDATE,
	Unread_Count_Notification,
	NOTIFICATIONS_STATUS,
	NOTIFICATIONS,
	STATISTIC_USER_STATUS,
	STATISTIC_USER,
	STATISTIC_STATUS,
	STATISTIC,
	PUPILS_STATUS,
	PUPILS,
	MANAGER_CERTIFICATE_STATUS,
	MANAGER_CERTIFICATE,
	MANAGER_COURSE_STATUS,
	MANAGER_COURSE,
	MANAGER_COUNTS_STATUS,
	MANAGER_COUNTS,
	/*REGIONS_STATUS,*/ REGIONS,
	/*CITIES_STATUS,*/ CITIES,
	FORGOT_PASSWORD_STATUS,
	FORGOT_PASSWORD,
	FORGOT_VALIDATE_SMS,
	FORGOT_NEW_PASSWORD,
	DIAGNOSTIC_TEST_STATUS,
	DIAGNOSTIC_TEST,
	DIAGNOSTIC_ANSWER_STATUS,
	DIAGNOSTIC_ANSWER,
	DIAGNOSTIC_QUESTION_STATUS,
	DIAGNOSTIC_QUESTION,
	USER_POSITION_STATUS,
	USER_POSITION,
	DELETE_DIAGNOSTIC_QUESTION_STATUS,
	DELETE_DIAGNOSTIC_QUESTION,
	DELETE_DIAGNOSTIC_ANSWER_STATUS,
	DELETE_DIAGNOSTIC_ANSWER,
	UPDATE_GROUPING_ANSWER_STATUS,
	UPDATE_GROUPING_ANSWER,
	UPDATE_DRAG_WORDS_ANSWER_STATUS,
	UPDATE_DRAG_WORDS_ANSWER,
	DELETE_VIDEO_ANSWER,
	DELETE_VIDEO_QUESTION,
	UPDATE_VIDEO_ANSWER,
	UPDATE_VIDEO_QUESTION,
	DELETE_VIDEO_TIME_STATUS,
	DELETE_VIDEO_TIME,
	UPDATE_VIDEO_QUESTION_TIME_STATUS,
	UPDATE_VIDEO_QUESTION_TIME,
	NOTIFICATION_MARK,
	CHAT_MORE,
	NEW_PASSWORD,
	Exercise_TEST_STATUS,
	Exercise_TEST,
	Exercise_ANSWER_STATUS,
	Exercise_ANSWER,
	Exercise_QUESTION_STATUS,
	Exercise_QUESTION,
	DELETE_Exercise_QUESTION_STATUS,
	DELETE_Exercise_QUESTION,
	DELETE_Exercise_ANSWER_STATUS,
	DELETE_Exercise_ANSWER,
} from '../actions/types';
import _ from 'lodash';
const INITIAL_STATE = {
	update_phone: {},
	warning: {},
	//message
	message_list: [],
	conference_list: [],
	//auth
	sign_in: { status: 'begin' },
	sign_up: { status: 'begin' },
	validate_sms: { status: 'begin' },

	//profile
	profile: {},
	profile_status: { status: 'begin' },
	profile_update: {},
	profile_update_status: { status: 'begin' },

	//courses - home page
	courses_loading: true,
	courses_list: {},
	courses_error: {},

	//courses - courses page
	cat_courses_loading: true,
	cat_courses_list: {},
	cat_courses_error: {},

	//courses
	course: {},
	course_status: { status: 'begin' },

	// course buy
	course_buy_status: { status: 'begin' },
	course_buy: {},

	// course sections
	course_sections_status: { status: 'begin' },
	course_sections: {},

	// course lesson
	course_lesson_status: { status: 'begin' },
	course_lesson: {},

	//teacher courses
	teacher_courses: {},
	teacher_courses_status: { status: 'begin' },
	teacher_category: [],
	teacher_category_status: { status: 'begin' },
	teacher_lan: {},
	teacher_lan_status: { status: 'begin' },

	//teacher courses create or update
	teacher_change_course_status: { status: 'begin' },
	teacher_update_course_status: { status: 'begin' },
	teacher_update_course: {},

	//teacher courses sections
	teacher_course_sections: {},
	teacher_course_sections_status: { status: 'begin' },

	//teacher courses sections create
	teacher_section_create_status: { status: 'begin' },
	//teacher courses sections delete
	teacher_section_delete_status: { status: 'begin' },
	//teacher courses sections update
	teacher_section_update_status: { status: 'begin' },
	//teacher courses sections order
	teacher_section_order_status: { status: 'begin' },

	//teacher lessons
	teacher_lessons_status: { status: 'begin' },
	teacher_lessons: [],
	teacher_lesson_data: {},
	create_lesson_status: { status: 'begin' },
	delete_lessons_status: { status: 'begin' },
	update_lesson_text_status: { status: 'begin' },
	update_lesson_video_status: { status: 'begin' },
	update_lesson_webinar_status: { status: 'begin' },
	update_lesson_question_status: { status: 'begin' },
	update_lesson_answer_status: { status: 'begin' },
	teacher_questions_status: { status: 'begin' },
	teacher_questions: { status: 'begin' },
	delete_question_status: { status: 'begin' },
	update_question_time_status: { status: 'begin' },
	delete_question_time_status: { status: 'begin' },
	delete_answer_status: { status: 'begin' },

	//upgrade_categories
	upgrade_categories: {},
	upgrade_categories_status: { status: 'begin' },

	upgrade_courses: {},
	upgrade_courses_status: { status: 'begin' },

	//retraining_categories
	retraining_categories: {},
	retraining_categories_status: { status: 'begin' },

	retraining_courses: {},
	retraining_courses_status: { status: 'begin' },

	//my courses
	my_courses: {},
	my_courses_status: { status: 'begin' },

	test_check: {},
	test_check_status: { status: 'begin' },

	//teacher webinar
	upadte_teacher_webinar: {},
	upadte_teacher_webinar_status: { status: 'begin' },

	teacher_webinar: {},
	teacher_webinar_status: { status: 'begin' },

	webinar_data: {},
	webinar_data_status: { status: 'begin' },

	my_webinars: {},
	my_webinars_status: { status: 'begin' },

	webinars_list: {},
	webinars_list_status: { status: 'begin' },
	chat_active_id: 0,
	chat_list: {},
	chat_list_status: { status: 'begin' },
	chat_message_list: {},
	chat_message_list_status: { status: 'begin' },

	chat_message_send_status: { status: 'begin' },

	teacher_list: {},
	teacher_list_status: { status: 'begin' },

	lesson_webinar_join: {},
	lesson_webinar_join_status: { status: 'begin' },
	lesson_webinar_record: {},
	lesson_webinar_record_status: { status: 'begin' },

	update_password: {},
	update_password_status: { status: 'begin' },

	notifications: {},
	notifications_status: { status: 'begin' },
	unread_count: {},

	search: '',

	statistic: {},
	statistic_status: { status: 'begin' },
	statistic_user: [],
	statistic_user_status: { status: 'begin' },

	pupils: {},
	pupils_status: { status: 'begin' },
	manager_certificates: {},
	manager_certificates_status: { status: 'begin' },
	manager_courses: {},
	manager_courses_status: { status: 'begin' },
	manager_counts: {},
	manager_counts_status: { status: 'begin' },

	region_list: {},
	cities_list: {},

	new_password: { status: 'begin' },
	forgot: {},
	forgot_status: { status: 'begin' },
	forgot_validate_sms: { status: 'begin' },
	forgot_new_password: { status: 'begin' },
	add_education: {},
	add_education_status: { status: 'begin' },

	diagnostics_test: {},
	diagnostics_test_status: { status: 'begin' },
	diagnostics_answer_status: { status: 'begin' },
	diagnostics_question_status: { status: 'begin' },
	diagnostics_delete_question_status: { status: 'begin' },
	diagnostics_delete_answer_status: { status: 'begin' },

	exercise_test: {},
	exercise_test_status: { status: 'begin' },
	exercise_answer_status: { status: 'begin' },
	exercise_question_status: { status: 'begin' },
	exercise_delete_question_status: { status: 'begin' },
	exercise_delete_answer_status: { status: 'begin' },

	user_position: [],
	user_position_status: { status: 'begin' },
	excel_ready: []
};

const reducers = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'EXCEL_READY': 
			return{
				...state,
				excel_ready: [...state.excel_ready, action.payload]
			}
		case 'UPDATE_PHONE':
			return{
				...state,
				update_phone: action.payload
			}
		case 'SET_WARNING':
			return{
				...state,
				warning: action.payload
			};
		case NEW_PASSWORD:
			return{
				...state,
				new_password: action.payload
			};
		case 'DIAGNOSTIC_BEGIN_STATUS':
			return {
				...state,
				diagnostics_test_status: { status: 'begin' },
				//diagnostics_answer_status: { status: 'begin' },
				diagnostics_question_status: { status: 'begin' },
				diagnostics_delete_question_status: { status: 'begin' },
				diagnostics_delete_answer_status: { status: 'begin' },
			};
		case 'Exercise_BEGIN_STATUS':
			return {
				...state,
				exercise_test_status: { status: 'begin' },
				//exercise_answer_status: { status: 'begin' },
				exercise_question_status: { status: 'begin' },
				exercise_delete_question_status: { status: 'begin' },
				exercise_delete_answer_status: { status: 'begin' },
			};
		case DELETE_DIAGNOSTIC_ANSWER_STATUS:
			return {
				...state,
				diagnostics_delete_answer_status: action.payload,
			};
		case DELETE_DIAGNOSTIC_ANSWER: {
			let list = state.diagnostics_test.data;
			let l = [];
			list.forEach(ques => {
				if (action.question_id && ques.id === action.question_id) {
					let as = ques.answers;
					let asd = [];
					if (action.id) {
						as.forEach(answer => {
							if (answer.id !== action.id) {
								asd = [...asd, answer];
							}
						});
						as = asd;
					}
					l = [...l, { ...ques, answers: as }];
				} else {
					l = [...l, ques];
				}
			});
			list = l;
			return {
				...state,
				diagnostics_delete_answer_status: { status: 'success' },
				diagnostics_test: { ...state.diagnostics_test, data: list },
			};
		}
		case DELETE_Exercise_ANSWER_STATUS:
			return {
				...state,
				exercise_delete_answer_status: action.payload,
			};
		case DELETE_Exercise_ANSWER: {
			let liste = state.exercise_test.data;
			let lee = [];
			liste.forEach(ques => {
				if (action.question_id && ques.id === action.question_id) {
					let as = ques.answers;
					let asd = [];
					if (action.id) {
						as.forEach(answer => {
							if (answer.id !== action.id) {
								asd = [...asd, answer];
							}
						});
						as = asd;
					}
					lee = [...lee, { ...ques, answers: as }];
				} else {
					lee = [...lee, ques];
				}
			});
			liste = lee;
			return {
				...state,
				exercise_delete_answer_status: { status: 'success' },
				exercise_test: { ...state.exercise_test, data: liste },
			};
		}
		case DELETE_DIAGNOSTIC_QUESTION_STATUS:
			return {
				...state,
				diagnostics_delete_question_status: action.payload,
			};
		case DELETE_DIAGNOSTIC_QUESTION: {
			let list = state.diagnostics_test.data;
			let l = [];
			if (action.id) {
				list.forEach(ques => {
					if (ques.id !== action.id) {
						l = [...l, ques];
					}
				});
				list = l;
			} else {
				list = [...list];
			}
			return {
				...state,
				diagnostics_delete_question_status: { status: 'success' },
				diagnostics_test: { ...state.diagnostics_test, data: list },
			};
		}
		case DELETE_Exercise_QUESTION_STATUS:
			return {
				...state,
				exercise_delete_question_status: action.payload,
			};
		case DELETE_Exercise_QUESTION: {
			let list = state.exercise_test.data;
			let l = [];
			if (action.id) {
				list.forEach(ques => {
					if (ques.id !== action.id) {
						l = [...l, ques];
					}
				});
				list = l;
			} else {
				list = [...list];
			}
			return {
				...state,
				exercise_delete_question_status: { status: 'success' },
				exercise_test: { ...state.exercise_test, data: list },
			};
		}
		case USER_POSITION:
			return {
				...state,
				user_position: action.payload,
				user_position_status: { status: 'success' },
			};
		case USER_POSITION_STATUS:
			return {
				...state,
				user_position_status: action.payload,
			};
		case CHAT_MESSAGE_MORE:
			const m = state.chat_message_list;
			const m_data = m.data;
			const actions = action.payload;
			const a_data = actions.data;
			return {
				...state,
				chat_message_list: { ...actions, data: [...m_data, ...a_data] },
			};
		case CHAT_MESSAGE_SEND_STATUS:
			return {
				...state,
				chat_message_send_status: action.payload,
			};
		case CHAT_MESSAGE_SEND:
			const ml = state.chat_message_list;
			const m_l = ml.data;
			const a = action.payload.data;
			return {
				...state,
				chat_message_list: { ...ml, data: [a, ...m_l] },
				chat_message_send_status: { status: 'success' },
			};
		case CHAT_STATUS:
			return {
				...state,
				chat_list_status: action.payload,
			};
		case CHAT:
			return {
				...state,
				chat_list: action.payload,
				chat_list_status: { status: 'success' },
			};
		case CHAT_MORE: {
			let l = {};
			const c = state.chat_list;
			l = {...action.payload, data: [..._.get(c, 'data', []), ..._.get(action, 'payload.data', []),]}
			return {
				...state,
				chat_list: l,
				chat_list_status: { status: 'success' },
			}
		}
		case 'SET_MESSAGE_SOCKET': {
			let state_socket = {};
			if(state.chat_active_id === action.payload || action.live){
				const ml = state.chat_message_list;
				const m_l = ml.data;
				const a = action.message;
				const d = _.get(state.chat_list, 'data', []);
				let list_chat = [];
				d.forEach(item=>{ 
					if( item.id === action.payload ){
						list_chat.push({ ...item, unreadCount: 0, last_message: action.message });
					}else{
						list_chat.push(item);
					}
				});
				state_socket = {
					chat_list: {...state.chat_list, data: list_chat},
					chat_message_list: { ...ml, data: [a, ...m_l] },
					chat_message_send_status: { status: 'success_socket' }
				};
			}else{
				const d = _.get(state.chat_list, 'data', []);
				let list_chat = [];
				d.forEach(item=>{ 
					if( item.id === action.payload ){
						list_chat.push({ ...item, unreadCount: item.unreadCount+1, last_message: action.message });
					}else{
						list_chat.push(item);
					}
				});
				state_socket = {
					chat_list: {...state.chat_list, data: list_chat},
					unread_count: {...state.unread_count, chat_unread_count: (state.unread_count.chat_unread_count?state.unread_count.chat_unread_count:0)+1},
					chat_message_send_status: { status: 'success_socket' }
				};
			}
			return {
				...state,
				...state_socket
			};
		}
		case 'OPEN_CHAR':{
			let threads = state.chat_list.data?state.chat_list.data:[];
			let l = [];
			let chat_unread__count = state.unread_count.chat_unread_count?state.unread_count.chat_unread_count:0;
			threads.forEach((item)=>{
				if(item.id===action.id){
					chat_unread__count -= item.unreadCount;
					l.push({...item, unreadCount: 0})
				}else{
					l.push(item);
				}
			});
			
			return {
				...state,
				chat_active_id: action.id,
				chat_list: {...state.chat_list, data: l},
				chat_list_status: { status: 'success' },
				unread_count: {...state.unread_count, chat_unread_count: chat_unread__count>0?chat_unread__count:0 }
			};
		}

		case CHAT_MESSAGE_STATUS:
			return {
				...state,
				chat_message_list_status: action.payload,
			};
		case CHAT_MESSAGE:
			return {
				...state,
				chat_message_list: action.payload,
				chat_message_list_status: { status: 'success' },
			};

		case WEBINARS_LIST_STATUS:
			return {
				...state,
				webinars_list_status: action.payload,
			};
		case WEBINARS_LIST:
			return {
				...state,
				webinars_list: action.payload,
				webinars_list_status: { status: 'success' },
			};
		case MY_WEBINAR_STATUS:
			return {
				...state,
				my_webinars_status: action.payload,
			};
		case MY_WEBINAR:
			return {
				...state,
				my_webinars: action.payload,
				my_webinars_status: { status: 'success' },
			};
		case WEBINAR_DATA_STATUS:
			return {
				...state,
				webinar_data_status: action.payload,
			};
		case WEBINAR_DATA:
			return {
				...state,
				webinar_data: action.payload,
				webinar_data_status: { status: 'success' },
			};
		case UPDATE_TEACHER_WEBINAR_STATUS:
			return {
				...state,
				upadte_teacher_webinar_status: action.payload,
			};
		case UPDATE_TEACHER_WEBINAR:
			return {
				...state,
				upadte_teacher_webinar: action.payload,
				upadte_teacher_webinar_status: { status: 'success' },
			};
		case TEACHER_WEBINAR_STATUS:
			return {
				...state,
				teacher_webinar_status: action.payload,
			};
		case TEACHER_WEBINAR:
			return {
				...state,
				teacher_webinar: action.payload,
				teacher_webinar_status: { status: 'success' },
			};

		case TEST_CHECK_STATUS:
			return {
				...state,
				test_check_status: action.payload,
			};
		case TEST_CHECK:
			return {
				...state,
				test_checked: true,
				test_check: action.payload,
				course_lesson: { data: action.payload.lesson, msg: action.payload.msg },
				test_check_status: { status: 'success' },
			};
		//my course sections
		case MY_COURSES_STATUS:
			return {
				...state,
				my_courses_status: action.payload,
			};
		case MY_COURSES:
			return {
				...state,
				my_courses: action.payload,
				my_courses_status: { status: 'success' },
			};
		//course sections
		case COURSE_SECTIONS_STATUS:
			return {
				...state,
				course_sections_status: action.payload,
			};
		case COURSE_SECTIONS:
			return {
				...state,
				course_sections: action.payload,
				course_sections_status: { status: 'success', slug: action.slug },
			};
		//course sections
		case COURSE_LESSON_STATUS:
			return {
				...state,
				course_lesson_status: action.payload,
			};
		case COURSE_LESSON:
			return {
				...state,
				course_lesson: action.payload,
				test_checked: false,
				course_lesson_status: { status: 'success' },
			};
		//course buy
		case COURSE_BUY_STATUS:
			return {
				...state,
				course_buy_status: action.payload,
			};
		case COURSE_BUY:
			return {
				...state,
				course_buy_status: { status: 'success' },
				course: { ...state.course, data: { ...state.course.data, active_buys_count: 1 } },
			};
		//upgrade
		case UPGRADE_CAT_STATUS:
			return {
				...state,
				upgrade_categories_status: action.payload,
			};
		case UPGRADE_CAT:
			return {
				...state,
				upgrade_categories: action.payload,
				upgrade_categories_status: { status: 'success' },
			};

		case UPGRADE_COURSE_STATUS:
			return {
				...state,
				upgrade_courses_status: action.payload,
			};
		case UPGRADE_COURSE:
			return {
				...state,
				upgrade_courses: action.payload,
				upgrade_courses_status: { status: 'success' },
			};

		//retraining
		case RETRAINING_CAT_STATUS:
			return {
				...state,
				retraining_categories_status: action.payload,
			};
		case RETRAINING_CAT:
			return {
				...state,
				retraining_categories: action.payload,
				retraining_categories_status: { status: 'success' },
			};
		case DIAGNOSTIC_QUESTION:
			let data_qq = state.diagnostics_test.data;
			const d_list = state.diagnostics_test;
			let l_q = {};
			if (action.id) {
				let list_d = [];
				data_qq.forEach(item => {
					if (action.id === item.id) {
						list_d = [...list_d, { ...action.payload.data, answers: item.answers }];
					} else {
						list_d = [...list_d, item];
					}
				});
				l_q = { ...d_list, data: list_d };
			} else {
				l_q = { ...d_list, data: [{ ...action.payload.data, answers: [] }, ...data_qq] };
			}

			return {
				...state,
				diagnostics_test: l_q,
				diagnostics_question_status: { status: 'success' },
			};
		case DIAGNOSTIC_QUESTION_STATUS:
			return {
				...state,
				diagnostics_question_status: action.payload,
			};
		
		case Exercise_QUESTION:
			let data_qqe = state.exercise_test.data;
			const e_list = state.exercise_test;
			let e_q = {};
			if (action.id) {
				let list_d = [];
				data_qqe.forEach(item => {
					if (action.id === item.id) {
						list_d = [...list_d, { ...action.payload.data, answers: item.answers }];
					} else {
						list_d = [...list_d, item];
					}
				});
				e_q = { ...e_list, data: list_d };
			} else {
				e_q = { ...e_list, data: [{ ...action.payload.data, answers: [] }, ...data_qqe] };
			}

			return {
				...state,
				exercise_test: e_q,
				exercise_question_status: { status: 'success' },
			};
		case Exercise_QUESTION_STATUS:
			return {
				...state,
				exercise_question_status: action.payload,
			};

		case DIAGNOSTIC_ANSWER:
			let data = state.diagnostics_test.data;
			const d = state.diagnostics_test;
			const a_dig = action.payload.data;
			let list = [];
			data.forEach(item => {
				if (item.id === action.question_id) {
					let edit = false;
					let answersss = [];
					item.answers.forEach(ans => {
						if (ans.id === action.id) {
							answersss = [...answersss, a_dig];
							edit = true;
						} else {
							answersss = [...answersss, ans];
						}
					});
					if (!edit) {
						list = [...list, { ...item, answers: [...item.answers, a_dig] }];
					} else {
						list = [...list, { ...item, answers: [...answersss] }];
					}
				} else {
					list = [...list, item];
				}
			});
			return {
				...state,
				diagnostics_test: { ...d, data: list },
				diagnostics_answer_status: { status: 'success' },
			};
		case DIAGNOSTIC_ANSWER_STATUS:
			return {
				...state,
				diagnostics_answer_status: action.payload,
			};
		
		case Exercise_ANSWER:
			let datae = state.exercise_test.data;
			const de = state.exercise_test;
			const a_dige = action.payload.data;
			let liste = [];
			datae.forEach(item => {
				if (item.id === action.question_id) {
					let edit = false;
					let answersss = [];
					item.answers.forEach(ans => {
						if (ans.id === action.id) {
							answersss = [...answersss, a_dige];
							edit = true;
						} else {
							answersss = [...answersss, ans];
						}
					});
					if (!edit) {
						liste = [...liste, { ...item, answers: [...item.answers, a_dige] }];
					} else {
						liste = [...liste, { ...item, answers: [...answersss] }];
					}
				} else {
					liste = [...liste, item];
				}
			});
			return {
				...state,
				exercise_test: { ...de, data: liste },
				exercise_answer_status: { status: 'success' },
			};
		case Exercise_ANSWER_STATUS:
			return {
				...state,
				exercise_answer_status: action.payload,
			};

		case DIAGNOSTIC_TEST:
			return {
				...state,
				diagnostics_test: action.payload,
				diagnostics_test_status: { status: 'success' },
			};
		case DIAGNOSTIC_TEST_STATUS:
			return {
				...state,
				diagnostics_test_status: action.payload,
			};
		case Exercise_TEST:
			return {
				...state,
				exercise_test: action.payload,
				exercise_test_status: { status: 'success' },
			};
		case Exercise_TEST_STATUS:
			return {
				...state,
				exercise_test_status: action.payload,
			};

		case FORGOT_NEW_PASSWORD:
			return {
				...state,
				forgot_new_password: action.payload,
			};
		case FORGOT_VALIDATE_SMS:
			return {
				...state,
				forgot_validate_sms: action.payload,
			};
		case FORGOT_PASSWORD_STATUS:
			return {
				...state,
				forgot_status: action.payload,
			};
		case FORGOT_PASSWORD:
			return {
				...state,
				forgot: action.payload,
				forgot_status: { status: 'success' },
			};
		case REGIONS:
			return {
				...state,
				region_list: action.payload,
				// manager_counts_status: {status: 'success'},
			};
		case CITIES:
			return {
				...state,
				cities_list: action.payload,
				// manager_counts_status: {status: 'success'},
			};
		case MANAGER_COUNTS_STATUS:
			return {
				...state,
				manager_counts_status: action.payload,
			};
		case MANAGER_COUNTS:
			return {
				...state,
				manager_counts: action.payload,
				manager_counts_status: { status: 'success' },
			};
		case MANAGER_COURSE_STATUS:
			return {
				...state,
				manager_courses_status: action.payload,
			};
		case MANAGER_COURSE:
			return {
				...state,
				manager_courses: action.payload,
				manager_courses_status: { status: 'success' },
			};
		case MANAGER_CERTIFICATE_STATUS:
			return {
				...state,
				manager_certificates_status: action.payload,
			};
		case MANAGER_CERTIFICATE:
			return {
				...state,
				manager_certificates: action.payload,
				manager_certificates_status: { status: 'success' },
			};
		case PUPILS_STATUS:
			return {
				...state,
				pupils_status: action.payload,
			};
		case PUPILS:
			return {
				...state,
				pupils: action.payload,
				pupils_status: { status: 'success' },
			};
		case STATISTIC_USER_STATUS:
			return {
				...state,
				statistic_user_status: action.payload,
			};
		case STATISTIC_USER:
			return {
				...state,
				statistic_user: action.payload,
				statistic_user_status: { status: 'success' },
			};
		case STATISTIC_STATUS:
			return {
				...state,
				statistic_status: action.payload,
			};
		case STATISTIC:
			return {
				...state,
				statistic: action.payload,
				statistic_status: { status: 'success' },
			};
		case SEARCH:
			return {
				...state,
				search: action.payload,
			};
		case NOTIFICATIONS_STATUS:
			return {
				...state,
				notifications_status: action.payload,
			};
		case NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload,
				notifications_status: { status: 'success' },
			};
		case NOTIFICATION_MARK:{
			let n = [], nl = state.notifications, u = state.unread_count;
			_.get(nl, 'data', []).forEach(item=>{
				if(item.id===action.payload){
					n.push({...item, is_read: 1});
				}else n.push(item);
			})
			return {
				...state,
				notifications: {...nl, data: n},
				unread_count: {...u, count: _.get(u, 'count', 1)-1}
			}
		}
		case Unread_Count_Notification:
			return {
				...state,
				unread_count: action.payload ? action.payload : {},
			};
		case 'SET_USER_EDU':
			return {
				...state,
				unread_count: { ...state.unread_count, select_user_education: false }
			}
		case 'CLEAR_SURVEY': 
			return {
				...state,
				unread_count: { ...state.unread_count, surveys: [] }
			}
		case PASSWORD_UPDATE_STATUS:
			return {
				...state,
				update_password_status: action.payload,
			};
		case PASSWORD_UPDATE:
			return {
				...state,
				update_password: action.payload,
				update_password_status: { status: 'success' },
			};
		case LESSON_WEBINAR_JOIN_STATUS:
			return {
				...state,
				lesson_webinar_join_status: action.payload,
			};
		case LESSON_WEBINAR_JOIN:
			return {
				...state,
				lesson_webinar_join: action.payload,
				lesson_webinar_join_status: { status: 'success' },
			};
		case LESSON_WEBINAR_RECORD_STATUS:
			return {
				...state,
				lesson_webinar_record_status: action.payload,
			};
		case LESSON_WEBINAR_RECORD:
			return {
				...state,
				lesson_webinar_record: action.payload,
				lesson_webinar_record_status: { status: 'success' },
			};
		case TEACHER_LIST_STATUS:
			return {
				...state,
				teacher_list_status: action.payload,
			};
		case TEACHER_LIST:
			return {
				...state,
				teacher_list: action.payload,
				teacher_list_status: { status: 'success' },
			};
			// case RETRAINING_COURSE_STATUS:
			// return {
			// 	...state,
			// 	retraining_courses_status: action.payload,
			// };
		case RETRAINING_COURSE:
			return {
				...state,
				retraining_courses: action.payload,
				retraining_courses_status: { status: 'success' },
			};

		//teacher courses
		case TEACHER_COURSE:
			return {
				...state,
				teacher_courses: action.payload,
				teacher_courses_status: { status: 'success' },
			};
		case TEACHER_COURSE_STATUS:
			return {
				...state,
				teacher_courses_status: action.payload,
			};
		case TEACHER_COURSE_STATUS_UPDATE: {
			let l = state.teacher_courses;
			let list = [];
			l.data.forEach(c => {
				if (c.id !== action.id) {
					list = [...list, c];
				}
			});
			return {
				...state,
				teacher_courses: { ...l, data: list },
			};
		}
		case TEACHER_CATEGORY:
			return {
				...state,
				teacher_category: action.payload,
				teacher_category_status: { status: 'success' },
			};
		case TEACHER_CATEGORY_STATUS:
			return {
				...state,
				teacher_category_status: action.payload,
			};
		case TEACHER_LAN:
			return {
				...state,
				teacher_lan: action.payload,
				teacher_lan_status: { status: 'success' },
			};
		case TEACHER_LAN_STATUS:
			return {
				...state,
				teacher_lan_status: action.payload,
			};
		//teacher change course
		case TEACHER_COURSE_CHANGE_STATUS:
			return {
				...state,
				teacher_change_course_status: action.payload,
			};
		case TEACHER_COURSE_CHANGE:
			return {
				...state,
				teacher_change_course_status: { status: 'success' },
			};
		//update course
		case GET_UPDATE_COURSE:
			return {
				...state,
				teacher_update_course: action.payload,
				teacher_update_course_status: { status: 'success' },
			};
		case GET_UPDATE_COURSE_STATUS:
			return {
				...state,
				teacher_update_course_status: action.payload,
			};

		//teacher courses sections
		case GET_TEACHER_COURSE_SECTIONS:
			return {
				...state,
				teacher_course_sections: action.payload,
				teacher_course_sections_status: { status: 'success' },
			};
		case GET_TEACHER_COURSE_SECTIONS_STATUS:
			return {
				...state,
				teacher_course_sections_status: action.payload,
			};
		//teacher change section create
		case CREATE_SECTION_STATUS:
			return {
				...state,
				teacher_section_create_status: action.payload,
			};
		case CREATE_SECTION: {
			const new_section = action.payload;
			let list = state.teacher_course_sections;
			let l = [];
			if (new_section.parent_id) {
				list.forEach(item => {
					if (new_section.parent_id === item.id) {
						l.push({ ...item, childs: [...item.childs, new_section] });
					} else {
						l.push(item);
					}
				});
				list = l;
			} else {
				list.push({ ...new_section, childs: [] });
			}
			return {
				...state,
				teacher_course_sections: list,
				teacher_section_create_status: { status: 'success' },
			};
		}
		case DELETE_SECTION_STATUS:
			return {
				...state,
				teacher_section_delete_status: action.payload,
			};
		case DELETE_SECTION: {
			const section = action.payload;
			let list = state.teacher_course_sections;
			let l = [];
			if (section.parent_id) {
				list.forEach(item => {
					if (section.parent_id === item.id) {
						let new_c = [];
						item.childs.forEach(child => {
							if (section.id !== child.id) {
								new_c.push(child);
							}
						});
						l.push({ ...item, childs: new_c });
					} else {
						l.push(item);
					}
				});
			} else {
				list.forEach(item => {
					if (section.id !== item.id) {
						l.push(item);
					}
				});
			}
			return {
				...state,
				teacher_course_sections: l,
				teacher_section_delete_status: { status: 'success' },
			};
		}

		case UPDATE_SECTION_STATUS:
			return {
				...state,
				teacher_section_update_status: action.payload,
			};

		case UPDATE_SECTION: {
			const section = action.payload;
			let list = state.teacher_course_sections;
			let l = [];
			if (section.parent_id) {
				list.forEach(item => {
					if (section.parent_id === item.id) {
						let new_c = [];
						item.childs.forEach(child => {
							if (section.id !== child.id) {
								new_c.push(child);
							} else {
								new_c.push(section);
							}
						});
						l.push({ ...item, childs: new_c });
					} else {
						l.push(item);
					}
				});
			} else {
				list.forEach(item => {
					if (section.id !== item.id) {
						l.push(item);
					} else {
						l.push({ ...section, childs: item.childs });
					}
				});
			}
			return {
				...state,
				teacher_course_sections: l,
				teacher_section_update_status: { status: 'success', id: section.id },
			};
		}
		//sections order
		case ORDER_SECTION_STATUS:
			return {
				...state,
				teacher_section_order_status: action.payload,
			};
		case ORDER_SECTION:
			return {
				...state,
				teacher_section_order_status: { status: 'success' },
			};

		//teacher_lessons
		case TEACHER_LESSONS_STATUS:
			return {
				...state,
				teacher_lessons_status: action.payload,
			};
		case TEACHER_LESSONS:
			return {
				...state,
				teacher_lessons: action.payload,
				teacher_lessons_status: { status: 'success' },
				teacher_lesson_data: action.payload ? action.payload  : {},
			};
		//delete lesson
		case DELETE_LESSON_STATUS:
			return {
				...state,
				delete_lessons_status: action.payload,
			};
		case DELETE_LESSON:
			return {
				...state,
				teacher_lesson_data: {},
				delete_lessons_status: { status: 'success' },
			};
		// create lesson
		case CREATE_LESSON_STATUS:
			return {
				...state,
				create_lesson_status: action.payload,
			};
		case CREATE_LESSON:
			return {
				...state,
				teacher_lesson_data: action.payload,
				create_lesson_status: { status: 'success' },
			};
		//update lesson text
		case UPDATE_LESSON_TEXT_STATUS:
			return {
				...state,
				update_lesson_text_status: action.payload,
			};
		case UPDATE_LESSON_TEXT:
			return {
				...state,
				teacher_lesson_data: action.payload,
				update_lesson_text_status: { status: 'success' },
			};

		//update lesson video
		case UPDATE_LESSON_VIDEO_STATUS:
			return {
				...state,
				update_lesson_video_status: action.payload,
			};
		case UPDATE_LESSON_VIDEO:
			return {
				...state,
				teacher_lesson_data: action.payload,
				update_lesson_video_status: { status: 'success' },
			};

		//update lesson webinar
		case UPDATE_LESSON_WEBINAR_STATUS:
			return {
				...state,
				update_lesson_webinar_status: action.payload,
			};
		case UPDATE_LESSON_WEBINAR:
			return {
				...state,
				teacher_lesson_data: action.payload,
				update_lesson_webinar_status: { status: 'success' },
			};

		case UPDATE_LESSON_QUESTION_STATUS:
			return {
				...state,
				update_lesson_question_status: action.payload,
			};
		case UPDATE_LESSON_QUESTION: {
			let list = state.teacher_questions;
			let l = [];
			if (action.id) {
				list.forEach(ques => {
					if (ques.id === action.id) {
						l = [...l, { ...action.payload, answers: ques.answers }];
					} else {
						l = [...l, ques];
					}
				});
				list = l;
			} else {
				list = [{ ...action.payload, answers: [] }, ...list];
			}
			return {
				...state,
				update_lesson_question_status: { status: 'success' },
				teacher_questions: list,
			};
		}
		
		case UPDATE_VIDEO_QUESTION: {
			let lesson = state.teacher_lesson_data; 
			let list = _.get(lesson, 'questions', []);
			let l = [];
			if (action.id) {
				list.forEach(ques => {
					if (ques.id === action.id) {
						l = [...l, { ...action.payload, answers: ques.answers }];
					} else {
						l = [...l, ques];
					}
				});
				list = l;
			} else {
				list = [...list, { ...action.payload, answers: [] }];
			}
			return {
				...state,
				update_lesson_question_status: { status: 'success' },
				teacher_lesson_data: {...lesson, questions: list  }
			};
		}

		case UPDATE_LESSON_ANSWER_STATUS:
			return {
				...state,
				update_lesson_answer_status: action.payload,
			};
		case UPDATE_LESSON_ANSWER: {
			let list = state.teacher_questions;
			let l = [];
			list.forEach(ques => {
				if (action.question_id && ques.id === action.question_id) {
					let as = ques.answers ? ques.answers : [];
					let asd = [];
					if (action.id) {
						as.forEach(answer => {
							if (answer.id === action.id) {
								asd = [...asd, action.payload];
							} else {
								asd = [...asd, answer];
							}
						});
						as = asd;
					} else {
						as.push(action.payload);
					}
					let score = parseInt(ques.score);
					if (ques.type === 'single') {
						score =
							parseInt(ques.score) > parseInt(action.payload.score)
								? parseInt(ques.score)
								: parseInt(action.payload.score);
					} else {
						score = parseInt(ques.score) + parseInt(action.payload.score);
					}
					l = [...l, { ...ques, answers: as, score: score }];
				} else {
					l = [...l, ques];
				}
			});
			list = l;
			return {
				...state,
				update_lesson_answer_status: { status: 'success' },
				teacher_questions: list,
			};
		}
		case UPDATE_VIDEO_ANSWER: {
			let lesson = state.teacher_lesson_data; 
			let list = _.get(lesson, 'questions', []);
			let l = [];
			list.forEach(ques => {
				if (action.question_id && ques.id === action.question_id) {
					let as = ques.answers ? ques.answers : [];
					let asd = [];
					if (action.id) {
						as.forEach(answer => {
							if (answer.id === action.id) {
								asd = [...asd, action.payload];
							} else {
								asd = [...asd, answer];
							}
						});
						as = asd;
					} else {
						as.push(action.payload);
					}
					let score = parseInt(ques.score);
					if (ques.type === 'single') {
						score =
							parseInt(ques.score) > parseInt(action.payload.score)
								? parseInt(ques.score)
								: parseInt(action.payload.score);
					} else {
						score = parseInt(ques.score) + parseInt(action.payload.score);
					}
					l = [...l, { ...ques, answers: as, score: score }];
				} else {
					l = [...l, ques];
				}
			});
			list = l;
			return {
				...state,
				update_lesson_answer_status: { status: 'success' },
				teacher_lesson_data: {...lesson, questions: list  }
			};
		}
		case UPDATE_DRAG_WORDS_ANSWER_STATUS:
			return {
				...state,
				update_lesson_answer_status: action.payload,
			};
		case UPDATE_DRAG_WORDS_ANSWER: {
			let list = state.teacher_questions;
			let l = [];
			list.forEach(ques => {
				if (action.question_id && ques.id === action.question_id) {
					l = [...l, {...ques, ...action.payload}];
				} else {
					l = [...l, ques];
				}
			});
			list = l;
			return {
				...state,
				update_lesson_answer_status: { status: 'success' },
				teacher_questions: list,
			};
		}
		case UPDATE_GROUPING_ANSWER_STATUS:
			return {
				...state,
				update_lesson_answer_status: action.payload,
			};
		case UPDATE_GROUPING_ANSWER: {
			let list = state.teacher_questions;
			let l = [];
			list.forEach(ques => {
				if (action.question_id && ques.id === action.question_id) {
					l = [...l, {...ques, score: action.total_score,  answers: action.data}];
				} else {
					l = [...l, ques];
				}
			});
			list = l;
			return {
				...state,
				update_lesson_answer_status: { status: 'success' },
				teacher_questions: list,
			};
		}
		//delete question
		case DELETE_QUESTION_STATUS:
			return {
				...state,
				delete_question_status: action.payload,
			};
		case DELETE_QUESTION: { 
			let list = state.teacher_questions;
			let l = [];
			if (action.id) {
				list.forEach(ques => {
					if (ques.id !== action.id) {
						l = [...l, ques];
					}
				});
				list = l;
			} else {
				list = [...list, action.payload];
			}
			return {
				...state,
				delete_question_status: { status: 'success' },
				teacher_questions: list,
			};
		}
		case DELETE_VIDEO_QUESTION: {
			let lesson = state.teacher_lesson_data; 
			let list = _.get(lesson, 'questions', []);
			let l = [];
			if (action.id) {
				list.forEach(ques => {
					if (ques.id !== action.id) {
						l = [...l, ques];
					}
				});
				list = l;
			} else {
				list = [...list, action.payload];
			}
			return {
				...state,
				delete_question_status: { status: 'success' },				
				teacher_lesson_data: {...lesson, questions: list  }

			};
		}
		
		case DELETE_VIDEO_TIME: {
			let lesson = state.teacher_lesson_data; 
			let list = _.get(lesson, 'questions', []);
			let l = [];
			list.forEach(ques => {
				if (ques.time !== action.time) {
					l = [...l, ques];
				}
			});
			list = l;
			return {
				...state,
				delete_question_time_status: { status: 'success' },				
				teacher_lesson_data: {...lesson, questions: list  }

			};
		}
		case DELETE_VIDEO_TIME_STATUS:
			return {
				...state,
				delete_question_time_status: action.payload,
			};
		case UPDATE_VIDEO_QUESTION_TIME: {
			let lesson = state.teacher_lesson_data; 
			let list = _.get(lesson, 'questions', []);
			let l = [];
			list.forEach(ques => {
				if ( action.questions.includes(ques.id) ) l = [...l, {...ques, time: action.time} ];
				else  l = [...l, ques ];
			});
			list = l;
			console.log('list', l);
			return {
				...state,
				update_question_time_status: { status: 'success' },				
				teacher_lesson_data: {...lesson, questions: list  }
			};
		}
		case UPDATE_VIDEO_QUESTION_TIME_STATUS:
			return {
				...state,
				update_question_time_status: action.payload,
			};
		//delete answer
		case DELETE_ANSWER_STATUS:
			return {
				...state,
				delete_answer_status: action.payload,
			};
		case DELETE_ANSWER: {
			let list = state.teacher_questions;
			let l = [];
			list.forEach(ques => {
				if (action.question_id && ques.id === action.question_id) {
					let as = ques.answers;
					let asd = [];
					if (action.id) {
						as.forEach(answer => {
							if (answer.id !== action.id) {
								asd = [...asd, answer];
							}
						});
						as = asd;
					}
					l = [...l, { ...ques, answers: as }];
				} else {
					l = [...l, ques];
				}
			});
			list = l;
			return {
				...state,
				delete_answer_status: { status: 'success' },
				teacher_questions: list,
			};
		}
		case DELETE_VIDEO_ANSWER: {
			let lesson = state.teacher_lesson_data; 
			let list = _.get(lesson, 'questions', []);
			let l = [];
			list.forEach(ques => {
				if (action.question_id && ques.id === action.question_id) {
					let as = ques.answers;
					let asd = [];
					if (action.id) {
						as.forEach(answer => {
							if (answer.id !== action.id) {
								asd = [...asd, answer];
							}
						});
						as = asd;
					}
					l = [...l, { ...ques, answers: as }];
				} else {
					l = [...l, ques];
				}
			});
			list = l;
			return {
				...state,
				delete_answer_status: { status: 'success' },				
				teacher_lesson_data: {...lesson, questions: list  }
			};
		}

		case GET_TECH_LESSON_QUESTION_STATUS:
			return {
				...state,
				teacher_questions_status: action.payload,
			};
		case GET_TECH_LESSON_QUESTION: {
			return {
				...state,
				teacher_questions_status: { status: 'success' },
				teacher_questions: action.payload,
			};
		}
		//sign_in
		case SIGN_STATUS:
			return {
				...state,
				sign_in: action.payload,
			};
		//sign_up
		case SIGNUP_STATUS:
			return {
				...state,
				sign_up: action.payload,
			};
		//validate_sms
		case VALIDATE_SMS:
			return {
				...state,
				validate_sms: action.payload,
			};
		//profile
		case PROFILE:
			return {
				...state,
				profile: action.payload,
				profile_status: { status: 'success' },
			};
		case PROFILE_STATUS:
			return {
				...state,
				profile_status: action.payload,
			};

		case PROFILE_UPDATE:
			return {
				...state,
				profile_update: action.payload,
				profile_update_status: { status: 'success' },
			};
		case PROFILE_UPDATE_STATUS:
			return {
				...state,
				profile_update_status: action.payload,
			};

		//log_out
		case LOG_OUT:
			return INITIAL_STATE;

		//course
		case COURSE:
			return {
				...state,
				course: action.payload,
				course_status: { status: 'success' },
			};
		case "TARGET_SET_CERTIFICATE":
			return{
				...state,
				course: {
					...state.course, 
					data: {
						...state?.course?.data??{},  
						targer_certificat: action.payload, 
						course_complate: true
					} 
				}
			}
		case COURSE_STATUS:
			return {
				...state,
				course_status: action.payload,
			};
		//courses - home page
		case COURSES_DATA:
			return {
				...state,
				courses_loading: false,
				courses_list: action.payload,
			};
		case COURSES_ERROR:{
			return {
				...state,
				courses_loading: false
			};
		}

		//courses - courses page
		case CAT_COURSES_DATA:
			return {
				...state,
				cat_courses_loading: false,
				cat_courses_list: action.payload,
			};
		case CAT_COURSES_START:
			return {
				...state,
				cat_courses_loading: true,
				cat_courses_list: {},
			};

		//messages
		case S_M: {
			let d = new Date();
			return {
				...state,
				message_list: [...state.message_list, { ...action.payload, time: d.getTime() }],
			};
		}
		case C_M:
			let l = [];
			const s = state.message_list;
			s.forEach(m => {
				if (m.time !== action.payload) {
					l.push(m);
				}
			});
			return {
				...state,
				message_list: l,
			};
		default:
			return state;
	}
};
export default reducers;
