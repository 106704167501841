import React, { useState, useEffect } from 'react';
import Loading from '../sections/Loading';
import Layout from '../Layout';
import Navigation from '../sections/Navigation';
import { useTranslation } from 'react-i18next';

const HelpPage = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(false);
	}, []);
	return (
		<Layout>
			<main class="main" style={{ background: '#F5F5F5' }}>
				<section class="section section-breadcrumbs">
					<div style={{ padding: '0 8%' }} class="container">
						<Navigation active={t('help.title')} navs={[]} />
					</div>
					<div style={{ padding: '0 8%', marginTop: 20 }} className="container">
						<h4 style={{ fontSize: 18, fontWeight: 600, marginBottom: 20 }}>{t('help.title')}</h4>
						{loading ? (
							<Loading />
						) : (
							<p>
								Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько
								абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору
								отточить навык публичных выступлений в домашних условиях. При создании генератора мы
								использовали небезизвестный универсальный код речей. Текст генерируется абзацами
								случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст
								более привлекательным и живым для визуально-слухового восприятия. По своей сути
								рыбатекст является альтернативой традиционному lorem ipsum, который вызывает у некторых
								людей недоумение при попытках прочитать рыбу текст. В отличии от lorem ipsum, текст рыба
								на русском языке наполнит любой макет непонятным смыслом и придаст неповторимый колорит
								советских времен. Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру
								сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а
								начинающему оратору отточить навык публичных выступлений в домашних условиях. При
								создании генератора мы использовали небезизвестный универсальный код речей. Текст
								генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что
								позволяет сделать текст более привлекательным и живым для визуально-слухового
								восприятия. По своей сути рыбатекст является альтернативой традиционному lorem ipsum,
								который вызывает у некторых людей недоумение при попытках прочитать рыбу текст. В
								отличии от lorem ipsum, текст рыба на русском языке наполнит любой макет непонятным
								смыслом и придаст неповторимый колорит советских времен.
							</p>
						)}
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default HelpPage;
