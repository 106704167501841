//messages
export const S_M = 'SET_MESSAGE';
export const C_M = 'CLOSE_M';

//sign_in
export const SIGN_STATUS = 'SIGN_STATUS';
export const SIGNUP_STATUS = 'SIGNUP_STATUS';
export const LOG_OUT = 'LOG_OUT';
export const VALIDATE_SMS = 'VALIDATE_SMS';

//profile
export const PROFILE = 'PROFILE';
export const PROFILE_STATUS = 'PROFILE_STATUS';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_STATUS = 'PROFILE_UPDATE_STATUS';

//courses - home page
export const COURSES_DATA = 'COURSES_DATA';
export const COURSES_ERROR = 'COURSES_ERROR';

//courses - courses page
export const CAT_COURSES_DATA = 'CAT_COURSES_DATA';
export const CAT_COURSES_ERROR = 'CAT_COURSES_ERROR';
export const CAT_COURSES_START = 'CAT_COURSES_START';

//course
export const COURSE = 'COURSE';
export const COURSE_STATUS = 'COURSE_STATUS';

//teacher cours
export const TEACHER_COURSE = 'TEACHER_COURSE';
export const TEACHER_COURSE_STATUS = 'TEACHER_COURSE_STATUS';

export const TEACHER_COURSE_STATUS_UPDATE = 'TEACHER_COURSE_STATUS_UPDATE';

export const TEACHER_CATEGORY = 'TEACHER_CATEGORY';
export const TEACHER_CATEGORY_STATUS = 'TEACHER_CATEGORY_STATUS';

export const TEACHER_LAN = 'TEACHER_LAN';
export const TEACHER_LAN_STATUS = 'TEACHER_LAN_STATUS';

export const TEACHER_COURSE_CHANGE = 'TEACHER_COURSE_CHANGE';
export const TEACHER_COURSE_CHANGE_STATUS = 'TEACHER_COURSE_CHANGE_STATUS';

export const GET_UPDATE_COURSE_STATUS = 'GET_UPDATE_COURSE_STATUS';
export const GET_UPDATE_COURSE = 'GET_UPDATE_COURSE';

export const GET_TEACHER_COURSE_SECTIONS_STATUS = 'GET_TEACHER_COURSE_SECTIONS_STATUS';
export const GET_TEACHER_COURSE_SECTIONS = 'GET_TEACHER_COURSE_SECTIONS';

export const CREATE_SECTION_STATUS = 'CREATE_SECTION_STATUS';
export const CREATE_SECTION = 'CREATE_SECTION';

export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_SECTION_STATUS = 'DELETE_SECTION_STATUS';

export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATE_SECTION_STATUS = 'UPDATE_SECTION_STATUS';

export const ORDER_SECTION_STATUS = 'ORDER_SECTION_STATUS';
export const ORDER_SECTION = 'ORDER_SECTION';

//teacer lessons
export const TEACHER_LESSONS_STATUS = 'TEACHER_LESSONS_STATUS';
export const TEACHER_LESSONS = 'TEACHER_LESSONS';

export const CREATE_LESSON_STATUS = 'CREATE_LESSON_STATUS';
export const CREATE_LESSON = 'CREATE_LESSON';

export const UPDATE_LESSON_TEXT_STATUS = 'UPDATE_LESSON_TEXT_STATUS';
export const UPDATE_LESSON_TEXT = 'UPDATE_LESSON_TEXT';

export const UPDATE_LESSON_VIDEO_STATUS = 'UPDATE_LESSON_VIDEO_STATUS';
export const UPDATE_LESSON_VIDEO = 'UPDATE_LESSON_VIDEO';

export const UPDATE_LESSON_WEBINAR_STATUS = 'UPDATE_LESSON_WEBINAR_STATUS';
export const UPDATE_LESSON_WEBINAR = 'UPDATE_LESSON_WEBINAR';

export const UPDATE_LESSON_QUESTION_STATUS = 'UPDATE_LESSON_QUESTION_STATUS';
export const UPDATE_LESSON_QUESTION = 'UPDATE_LESSON_QUESTION';

export const UPDATE_LESSON_ANSWER_STATUS = 'UPDATE_LESSON_ANSWER_STATUS';
export const UPDATE_LESSON_ANSWER = 'UPDATE_LESSON_ANSWER';

export const GET_TECH_LESSON_QUESTION_STATUS = 'GET_TECH_LESSON_QUESTION_STATUS';
export const GET_TECH_LESSON_QUESTION = 'GET_TECH_LESSON_QUESTION';

export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_QUESTION_STATUS = 'DELETE_QUESTION_STATUS';

export const DELETE_ANSWER = 'DELETE_ANSWER';
export const DELETE_ANSWER_STATUS = 'DELETE_ANSWER_STATUS';

export const UPGRADE_CAT = 'UPGRADE_CAT';
export const UPGRADE_CAT_STATUS = 'UPGRADE_CAT_STATUS';

export const UPGRADE_COURSE = 'UPGRADE_COURSE';
export const UPGRADE_COURSE_STATUS = 'UPGRADE_COURSE_STATUS';

export const RETRAINING_CAT = 'RETRAINING_CAT';
export const RETRAINING_CAT_STATUS = 'RETRAINING_CAT_STATUS';

export const RETRAINING_COURSE = 'RETRAINING_COURSE';
export const RETRAINING_COURSE_STATUS = 'RETRAINING_COURSE_STATUS';

export const COURSE_BUY_STATUS = 'COURSE_BUY_STATUS';
export const COURSE_BUY = 'COURSE_BUY';

export const COURSE_SECTIONS_STATUS = 'COURSE_SECTIONS_STATUS';
export const COURSE_SECTIONS = 'COURSE_SECTIONS';

export const COURSE_LESSON_STATUS = 'COURSE_LESSON_STATUS';
export const COURSE_LESSON = 'COURSE_LESSON';

export const MY_COURSES_STATUS = 'MY_COURSES_STATUS';
export const MY_COURSES = 'MY_COURSES';

export const TEST_CHECK_STATUS = 'TEST_CHECK_STATUS';
export const TEST_CHECK = 'TEST_CHECK';

export const UPDATE_TEACHER_WEBINAR_STATUS = 'UPDATE_TEACHER_WEBINAR_STATUS';
export const UPDATE_TEACHER_WEBINAR = 'UPDATE_TEACHER_WEBINAR';

export const TEACHER_WEBINAR_STATUS = 'TEACHER_WEBINAR_STATUS';
export const TEACHER_WEBINAR = 'TEACHER_WEBINAR';

export const WEBINAR_DATA_STATUS = 'WEBINAR_DATA_STATUS';
export const WEBINAR_DATA = 'WEBINAR_DATA';

export const MY_WEBINAR_STATUS = 'MY_WEBINAR_STATUS';
export const MY_WEBINAR = 'MY_WEBINAR';

export const WEBINARS_LIST_STATUS = 'WEBINARS_LIST_STATUS';
export const WEBINARS_LIST = 'WEBINARS_LIST';

export const CHAT_STATUS = 'CHAT_STATUS';
export const CHAT = 'CHAT';
export const CHAT_MORE_STATUS = 'CHAT_MORE_STATUS';
export const CHAT_MORE = 'CHAT_MORE';

export const CHAT_MESSAGE_STATUS = 'CHAT_MESSAGE_STATUS';
export const CHAT_MESSAGE = 'CHAT_MESSAGE';

export const CHAT_MESSAGE_SEND_STATUS = 'CHAT_MESSAGE_SEND_STATUS';
export const CHAT_MESSAGE_SEND = 'CHAT_MESSAGE_SEND';

export const CHAT_MESSAGE_MORE_STATUS = 'CHAT_MESSAGE_MORE_STATUS';
export const CHAT_MESSAGE_MORE = 'CHAT_MESSAGE_MORE';

export const TEACHER_LIST_STATUS = 'TEACHER_LIST_STATUS';
export const TEACHER_LIST = 'TEACHER_LIST';

export const LESSON_WEBINAR_JOIN_STATUS = 'LESSON_WEBINAR_JOIN_STATUS';
export const LESSON_WEBINAR_JOIN = 'LESSON_WEBINAR_JOIN';

export const LESSON_WEBINAR_RECORD_STATUS = 'LESSON_WEBINAR_RECORD_STATUS';
export const LESSON_WEBINAR_RECORD = 'LESSON_WEBINAR_RECORD';

export const DELETE_LESSON_STATUS = 'DELETE_LESSON_STATUS';
export const DELETE_LESSON = 'DELETE_LESSON';

export const PASSWORD_UPDATE_STATUS = 'PASSWORD_UPDATE_STATUS';
export const PASSWORD_UPDATE = 'PASSWORD_UPDATE';

export const Unread_Count_Notification = 'Unread_Count_Notification';
export const NOTIFICATIONS_STATUS = 'NOTIFICATIONS_STATUS';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const NOTIFICATION_MARK = 'NOTIFICATION_MARK';

export const SEARCH = 'SEARCH';

export const STATISTIC_USER_STATUS = 'STATISTIC_USER_STATUS';
export const STATISTIC_USER = 'STATISTIC_USER';
export const STATISTIC = 'STATISTIC';
export const STATISTIC_STATUS = 'STATISTIC_STATUS';

export const PUPILS_STATUS = 'PUPILS_STATUS';
export const PUPILS = 'PUPILS';

export const MANAGER_CERTIFICATE_STATUS = 'MANAGER_CERTIFICATE_STATUS';
export const MANAGER_CERTIFICATE = 'MANAGER_CERTIFICATE';

export const MANAGER_COURSE_STATUS = 'MANAGER_COURSE_STATUS';
export const MANAGER_COURSE = 'MANAGER_COURSE';

export const MANAGER_COUNTS_STATUS = 'MANAGER_COUNTS_STATUS';
export const MANAGER_COUNTS = 'MANAGER_COUNTS';

export const REGIONS_STATUS = 'REGIONS_STATUS';
export const REGIONS = 'REGIONS';
export const CITIES_STATUS = 'CITIES_STATUS';
export const CITIES = 'CITIES';

export const FORGOT_PASSWORD_STATUS = 'FORGOT_PASSWORD_STATUS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_VALIDATE_SMS = 'FORGOT_VALIDATE_SMS';
export const FORGOT_NEW_PASSWORD = 'FORGOT_NEW_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';

export const DIAGNOSTIC_TEST_STATUS = 'DIAGNOSTIC_TEST_STATUS';
export const DIAGNOSTIC_TEST = 'DIAGNOSTIC_TEST';
export const DIAGNOSTIC_ANSWER_STATUS = 'DIAGNOSTIC_ANSWER_STATUS';
export const DIAGNOSTIC_ANSWER = 'DIAGNOSTIC_ANSWER';
export const DIAGNOSTIC_QUESTION_STATUS = 'DIAGNOSTIC_QUESTION_STATUS';
export const DIAGNOSTIC_QUESTION = 'DIAGNOSTIC_QUESTION';
export const DELETE_DIAGNOSTIC_QUESTION_STATUS = 'DELETE_DIAGNOSTIC_QUESTION_STATUS';
export const DELETE_DIAGNOSTIC_QUESTION = 'DELETE_DIAGNOSTIC_QUESTION';
export const DELETE_DIAGNOSTIC_ANSWER_STATUS = 'DELETE_DIAGNOSTIC_ANSWER_STATUS';
export const DELETE_DIAGNOSTIC_ANSWER = 'DELETE_DIAGNOSTIC_ANSWER';

export const Exercise_TEST_STATUS = 'Exercis_TEST_STATUS';
export const Exercise_TEST = 'Exercis_TEST';
export const Exercise_ANSWER_STATUS = 'Exercis_ANSWER_STATUS';
export const Exercise_ANSWER = 'Exercis_ANSWER';
export const Exercise_QUESTION_STATUS = 'Exercis_QUESTION_STATUS';
export const Exercise_QUESTION = 'Exercis_QUESTION';
export const DELETE_Exercise_QUESTION_STATUS = 'DELETE_Exercis_QUESTION_STATUS';
export const DELETE_Exercise_QUESTION = 'DELETE_Exercise_QUESTION';
export const DELETE_Exercise_ANSWER_STATUS = 'DELETE_Exercise_ANSWER_STATUS';
export const DELETE_Exercise_ANSWER = 'DELETE_Exercise_ANSWER';

export const USER_POSITION_STATUS = 'USER_POSITION_STATUS';
export const USER_POSITION = 'USER_POSITION';

export const UPDATE_GROUPING_ANSWER_STATUS = 'UPDATE_GROUPING_ANSWER_STATUS';
export const UPDATE_GROUPING_ANSWER = 'UPDATE_GROUPING_ANSWER';

export const UPDATE_DRAG_WORDS_ANSWER_STATUS = 'UPDATE_DRAG_WORDS_ANSWER_STATUS';
export const UPDATE_DRAG_WORDS_ANSWER = 'UPDATE_DRAG_WORDS_ANSWER';

export const DELETE_VIDEO_QUESTION = 'DELETE_VIDEO_QUESTION';
export const DELETE_VIDEO_ANSWER = 'DELETE_VIDEO_ANSWER';
export const UPDATE_VIDEO_QUESTION = 'UPDATE_VIDEO_QUESTION';
export const UPDATE_VIDEO_ANSWER = 'UPDATE_VIDEO_ANSWER';
export const DELETE_VIDEO_TIME = 'DELETE_VIDEO_TIME';
export const DELETE_VIDEO_TIME_STATUS = 'DELETE_VIDEO_TIME_STATUS';
export const UPDATE_VIDEO_QUESTION_TIME = 'UPDATE_VIDEO_QUESTION_TIME';
export const UPDATE_VIDEO_QUESTION_TIME_STATUS = 'UPDATE_VIDEO_QUESTION_TIME_STATUS';

