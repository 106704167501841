import React, {useState, useEffect} from 'react';
import { getWebinar } from "../../actions";
import { connect } from "react-redux";
import Layout from '../Layout';
import DashSideBar from "../sections/DashSideBar";
import Navigation from "../sections/Navigation";
import TeacherWebinarCreateOrUpdate from "../sections/TeacherWebinarCreateOrUpdate";
import NoData from "../sections/NoData";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Loading from '../sections/Loading';
import CheckRole from '../sections/CheckRole';
import { roles } from "../../helpers/roles";


const DashCopyrightWebinarUpdate = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(props.webinar_data_status.status === 'started'){
            setLoading(true);
        }
        if(props.webinar_data_status.status === 'success'){
            setLoading(false);
        }
        if(props.webinar_data_status.status === 'error'){
            setLoading(false);
        }
    }, [props.webinar_data_status.status])
    useEffect(() => {
        props.getWebinar(props.match.params.id);
    }, [props.match.params.id]);
    const {webinar_data} = props;
    return (<Layout>
			<CheckRole roles={ [ roles.teacher ] } />
        <main className="main">
            <section className="section section-dashboard py-0">
                <div className="container">
                    <div className="row">
                        <DashSideBar/>
                        <div className="col-md-12 col-lg-9 col-xl-10">
                            <div className="dashboard-right">
                                <Navigation
                                    is_dash={true}
                                    is_btn={true}
                                    active={ t('create_webinar.update') }
                                    navs={[
                                        {
                                            'title': t('navigation.account'),
                                            'link': '/dashboard'
                                        },
                                        {
                                            'title': t('create_webinar.webinar'),
                                            'link': '/dashboard/copyrights/webinars/list/1'
                                        }
                                    ]}
                                />
                                <div className="dashboard-title">
                                    <h5> { t('create_webinar.update') } </h5>
                                </div>
                                {loading?<Loading/>:
                                    webinar_data&&webinar_data.id?
                                    
                                    <TeacherWebinarCreateOrUpdate
                                        webinar_change_data={webinar_data}
                                    />
                                    :<NoData text={ t('create_webinar.no_data') }/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getWebinar: (id) => dispatch(getWebinar(id))
    })
)(DashCopyrightWebinarUpdate);