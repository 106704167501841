import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTeacherLesson, getTeacherLessonQuestions } from '../../actions';
import history from '../../history';
import Layout from '../Layout';
import DashSideBar from '../sections/DashSideBar';
import Loading from '../sections/Loading';
import Navigation from '../sections/Navigation';
import NoData from '../sections/NoData';
import TestQuestionChange from '../sections/TestQuestionChange';
import TestQuestionAdd from '../sections/TestQuestionAdd';
import TestAnswerChange from '../sections/TestAnswerChange';
import TestExplanChange from '../sections/TestExplanChange';
import TestAnswerAdd from '../sections/TestAnswerAdd';
import TestAnswerGroupChange from '../sections/TestAnswerGroupChange';
import TestAnswerDragWords from '../sections/TestAnswerDragWords';
import CheckRole from '../sections/CheckRole';
import { roles } from "../../helpers/roles";

const DashTeacherLessonTests = props => { 
	const { t } = useTranslation();
    const course_id =  props.match.params.course_id;
    const section_id =  props.match.params.section_id;
    const { teacher_lessons_status, teacher_lesson_data, teacher_questions, teacher_questions_status } = props;
	const [add, setAdd] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.getTeacherLesson(course_id, section_id);
    }, []);

    
    useEffect(() => {
		
        if(teacher_lessons_status.status === 'started'){
            setLoading(true);
        }
        if(teacher_lessons_status.status === 'success'){
            // setLoading(false);
            if(!teacher_lesson_data.id){
                history.push(`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson`);
            }else{
				props.getTeacherLessonQuestions(course_id, section_id, teacher_lesson_data.id);
			}
        }
        if(teacher_lessons_status.status === 'error'){
            setLoading(false);
        }
    }, [teacher_lessons_status]);

	useEffect(() => {
        if(teacher_questions_status.status === 'success'){
            window.clickOk = false;
            setLoading(false);
        }
        if(teacher_questions_status.status === 'error'){
            setLoading(false);
        }
    }, [teacher_questions_status]);

	// useEffect(()=>{
	// 	props.beginDiagnosticStatus();
	// },[diagnostics_test]);	

	return (
		<Layout>
			<CheckRole roles={ [ roles.teacher ] } />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={teacher_lesson_data.name}
										navs={[
											{
												'title': t('navigation.account'),
												'link': '/dashboard'
											},
											{
												'title': t('author_page.course'),
												'link': '/dashboard/copyrights'
											},
											{
												'title': t('course_items.items'),
												'link': '/dashboard/copyrights/course-items/'+course_id
											},
											{
												'title': t('teacher_lesson.lesson'),
												'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson'
											}
										]}
									/>
									<div className="dashboard-title dashboard-title--has-buttons">
										<h5>{t('teacher_lesson.edit')}</h5>
										<div className="buttons">
											<span
												className="btn btn-primary  btn-sm font-weight-medium"
												onClick={()=>setAdd(true)}
                                            >
												<span className="icon icon-plus" style={{color: '#fff'}} ></span> { t('diagnostics.add') }
											</span>
										</div>
									</div>
									<div style={add?{}:{display: 'none'}}>
										<TestQuestionAdd
											close={()=>setAdd(false)}
											lesson_id={teacher_lesson_data.id}
										/>
									</div>
									<div>
									{loading ? (
										<Loading />
									) : teacher_questions&&teacher_questions.length>0?
										<>
											{
												teacher_questions.map((question, index)=>(
													<div className="card card--shadow ">
            											<div className="card-body" style={{margin: 0, padding: 0}} id={"question"+question?.id}>   
															<TestQuestionChange question_data={question} index={index}/>
															<div>
																{question.type==='grouping'?
																	<TestAnswerGroupChange question_id={ question.id }  
																		answers_data={question.answers}/>
																:
																question.type==='dragwords'?
																	<TestAnswerDragWords question_id={ question.id }  
																		question={question}/>
																:<>
																	{question.answers.map((answer, index_a)=>(
																		<div key={index_a}  id={"answer"+answer?.id}>
																			<TestAnswerChange answer_data={answer} index_a={index_a+1}/>
																		</div>
																	))} 
																	<div>
																		<TestAnswerAdd question_id={ question.id } />
																	</div>
																</>}
															</div>
															<div>
																<TestExplanChange question_data={question}/>
															</div>
														</div>
													</div>
												))
											}
										</>
										:<NoData text={ t('diagnostics.no_data') } />			
									}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default connect(
	(state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherLesson: (_course_id, _section_id) => dispatch(getTeacherLesson(_course_id, _section_id)),
        getTeacherLessonQuestions: (course_id, section_id, lesson_id) => dispatch(getTeacherLessonQuestions(course_id, section_id, lesson_id)),

		beginDiagnosticStatus: ()=>dispatch({type: 'DIAGNOSTIC_BEGIN_STATUS'})
	}),
)(DashTeacherLessonTests);
