import React from 'react';
import _ from 'lodash';
import { IMG_URL } from "../../helpers/api";



const  CourseCard = (props) => {
    const {key, course, avg_rating = false} = props;
    return (<>
        <div className="col-md-6 col-xl-4" key={key}>
            <div className="course">
                <div className="course__header">
                    <img src={IMG_URL+_.get(course,'image')} alt={_.get(course,'name', "Online Course")}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = '/images/courses/course.jpg';
                        }}
                    />
                    <div className="course__info">
                        <span className="badge badge-purple">{_.get(course,'category.name')}</span>
                        <div className="course__rating">
                            <div className={ (avg_rating?_.get(course, 'reviews_avg_rating', 0):_.get(course,'rating', 0))?"rating rating-"+(Number.parseFloat( (avg_rating?_.get(course, 'reviews_avg_rating', 0):_.get(course,'rating', 0)))*2).toFixed():"rating rating-0"}></div>
                            <span>{  (avg_rating?_.get(course, 'reviews_avg_rating', 0):_.get(course,'rating', 0))?Number.parseFloat( (avg_rating?_.get(course, 'reviews_avg_rating', 0):_.get(course,'rating', 0))).toFixed(1):null }</span>
                        </div>
                    </div>
                </div>
                <div className="course__body">
                    <strong>{_.get(course,'name')}</strong>
                </div>
                <div className="course__footer">
                    {props.children}
                </div>
            </div>
        </div>

    </>);
}
export default CourseCard;