import React from "react";
import Layout from "../Layout";
import DashSideBar from "../sections/DashSideBar";
import Navigation from "../sections/Navigation";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../helpers/language";
import PasswordUpdate from "../sections/PasswordUpdate";
import PhoneUpdate from "../sections/PhoneUpdate";

const DashSettingsPage = () => {
  const { t, i18n } = useTranslation();
  const language = window.localStorage.getItem("language") || "uz";
  const onLanguageHandle = (newLang) => {
    i18n.changeLanguage(newLang);
    window.localStorage.setItem("language", newLang);
  };
  return (
    <Layout>
      <main className='main'>
        <section className='section section-dashboard py-0'>
          <div className='container'>
            <div className='row'>
              <DashSideBar />
              <div className='col-md-12 col-lg-9 col-xl-10'>
                <div className='dashboard-right'>
                  <Navigation
                    is_dash={true}
                    is_btn={true}
                    active={t("settings.setting")}
                  />
                  <div className='dashboard-title'>
                    <h5>{t("settings.setting")}</h5>
                  </div>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='setting'>
                        <div className='row align-items-center'>
                          <div className='col-md-7 col-xl-9'>
                            <div className='setting__description'>
                              <h6>{t("settings.lan")}</h6>
                              <p>{t("settings.lan_text")}</p>
                            </div>
                          </div>
                          <div className='col-md-5 col-xl-3'>
                            <div className='setting__buttons'>
                              <label className='switch-text'>
                                <input
                                  type='checkbox'
                                  checked={language === "ru" ? false : true}
                                />
                                <span className='slider'></span>
                                <div className='switch-labels'>
                                  <span
                                    className='switch-label'
                                    onClick={() =>
                                      onLanguageHandle(
                                        getLanguage() === "ru" ? "uz" : "ru"
                                      )
                                    }>
                                    {t("header.ru")}
                                  </span>
                                  <span
                                    className='switch-label'
                                    onClick={() =>
                                      onLanguageHandle(
                                        getLanguage() === "ru" ? "uz" : "ru"
                                      )
                                    }>
                                    {t("header.uz")}
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='setting'>
                        <div className='row align-items-center'>
                          <div className='col-md-7 col-xl-9'>
                            <div className='setting__description'>
                              <h6>{t("settings.notification")}</h6>
                              <p>{t("settings.notification_text")}</p>
                            </div>
                          </div>
                          <div className='col-md-5 col-xl-3'>
                            <div className='setting__buttons'>
                              <label className='switch'>
                                <input type='checkbox' />
                                <span className='slider round'></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='setting'>
                        <button
                          type='button'
                          className='btn btn-primary btn-sm'
                          data-toggle='modal'
                          data-target='#PhoneUpdateModal'>
                          {t("update_phone.update_phone")}
                        </button>
                      </div>
                      <div className='setting'>
                        <button
                          type='button'
                          className='btn btn-primary btn-sm'
                          data-toggle='modal'
                          data-target='#modalChangePassword'>
                          {t("settings.new_password")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <PasswordUpdate />
      <PhoneUpdate />
    </Layout>
  );
};
export default DashSettingsPage;
