import $ from 'jquery';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SwiperCore, { Mousewheel, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.css';
import { 
	getProfile,
} from '../../actions';  
import Layout from '../Layout'; 
import DashSideBar from '../sections/DashSideBar'; 
import Loading from '../sections/Loading';
import NavigationLocation from '../sections/Navigation'; 
window.jQuery = $;
SwiperCore.use([Mousewheel, Pagination]);
const DashProfilePage = props => {
	const { t } = useTranslation(); 
	const {profile} = props;
	useEffect(()=>{
		props.getProfile();
	}, [ ])
	return (
		<Layout>
			<main className='main'>
				<section className='section section-dashboard py-0'>
					<div className='container'>
						<div className='row'>
							<DashSideBar />
							<div className='col-md-12 col-lg-9 col-xl-10'>
								<div className='dashboard-right'>
									<NavigationLocation
										is_dash={true}
										is_btn={true}
										active={t('profile.title')}
									/>
									<div className='dashboard-title'>
										<h5>{t('profile.title')}</h5>
									</div>
									{ !profile?.id ? (
										<Loading />
									) : (
										<>
											<div className='card'>
												<div className='card-body'>
													<div className='row'>
														<div
															className='p-3'
															id='card-first-info'
														>
															<div
																style={{
																	display: 'flex',
																	width: '100%',
																}}
															>
																<img
																	style={{
																		height: 100,
																		width: 100,
																		objectFit: 'cover',
																		borderRadius: '50%',
																	}}
																	onError={e => {
																		e.target.onerror = null;
																		e.target.src =
																			'/images/user/user.png';
																	}}
																	src={profile?.avatar}
																	alt=''
																/>
																<div
																	style={{
																		marginLeft: 15,
																		display: 'flex',
																		justifyContent:
																			'space-evenly',
																		flexDirection: 'column',
																	}}
																>
																	<h3 className='profile-first-last-name'>
																		<span>{profile?.name} </span> 
																	</h3>
																	<h3
																		style={{
																			fontSize: 18,
																			color: '#898DA6',
																		}}
																	>
																		<span
																			className='icon icon-briefcase'
																			style={{
																				marginRight: 8,
																			}}
																		></span>
																		<span>{profile?.email}</span>
																	</h3>
																</div>
															</div> 
														</div> 
													</div>
												</div>
											</div>
											
										</>
									)} 
								</div>
							</div>
						</div>
					</div>
				</section>
				 
			</main> 
		</Layout>
	);
};
export default connect(
	state => ({
		profile: state.profile
	}),
	dispatch => ({
		getProfile: () => dispatch(getProfile()), 
	})
)(DashProfilePage);
