import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteFile, updateLessonText } from '../../actions';
import { FILE_URL } from '../../helpers/api';
import JoditText from './JoditText';
import Loading from './Loading';

const TeacherLessonText = props => {
	const { teacher_lesson_data, update_lesson_text_status, course_id, deleteFile } = props;
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [name_e, setNameE] = useState('');
	const [score_e, setScoreE] = useState('');
	const [content_e, setContentE] = useState('');
	const [redirect, setRedirect] = useState('');
	const [score, setScore] = useState(0);
	const [description, setDescription] = useState('');
	const [file, setFile] = useState('');

	useEffect(() => {
		if (update_lesson_text_status.status === 'success') {
			window.scrollTo(0, 0);
			setLoading(false);
		}
		if (update_lesson_text_status.status === 'error') {
			window.scrollTo(0, 0);
			setLoading(false);
		}
	}, [update_lesson_text_status]);
	useEffect(() => {
		console.log(_.get(teacher_lesson_data, 'file'), _.get(teacher_lesson_data, 'id'));
		if (_.get(teacher_lesson_data, 'file')) {
			setFile(_.get(teacher_lesson_data, 'file'));
		}
		setScore(_.get(teacher_lesson_data, 'score', 0));
		setDescription(_.get(teacher_lesson_data, 'content', ''));
	}, [props.teacher_lesson_data]);
	const onChangeScore = value => {
		const v = parseInt(value);
		if (Number.isInteger(v) && v >= 0) {
			setScore(v);
		} else {
			setScore('');
		}
	};
	const UpdateLesson = event => {
		event.preventDefault();
		setLoading(true);
		let formData = new FormData(event.target);
		let name = formData.get('name');
		let score = formData.get('score');
		let content = formData.get('content');
		let n = true,
			c = true,
			s = true;
		if (name === '') {
			n = false;
			setNameE(t('teacher_video.name_e'));
		}
		if (score === '') {
			s = false;
			setScoreE(t('teacher_video.score_e'));
		}
		if (content === '') {
			c = false;
			setContentE(t('teacher_video.description_e'));
		}
		if (n && c && s) {
			props.updateLessonText(formData, redirect);
		} else {
			setLoading(false);
		}
	};
	const deleteImage = () => {
		const id = _.get(teacher_lesson_data, 'id');
		deleteFile({ id: id, type: 'lesson', field: ['file'] });
		setFile('');
	};
	return (
		<>
			<form onSubmit={UpdateLesson} noValidate>
				<div className="tab-content" id="newLessonContent">
					<div id="new-lesson-1-4">
						<div className="row row-min-8 new-lesson__tab-content">
							<div className="col ">
								<input type="hidden" name="id" value={_.get(teacher_lesson_data, 'id')} />
								<div className="form-group">
									<label for="">{t('teacher_video.name')}</label>
									<input
										type="text"
										className="form-control"
										defaultValue={_.get(teacher_lesson_data, 'name')}
										name="name"
										onClick={() => setNameE('')}
									/>
									<span style={{ color: 'red' }}>{name_e}</span>
								</div>

								<div className="form-group">
									<label for="">{t('teacher_video.score')}</label>
									<input
										type="text"
										className="form-control "
										style={{ width: 150 }}
										name="score"
										onClick={() => setScoreE('')}
										value={score}
										onChange={event => onChangeScore(event.target.value)}
									/>
									<span style={{ color: 'red' }}>{score_e}</span>
								</div>
								<div className="form-group">
									<label for="">{t('teacher_video.description')}</label>
									<JoditText
										value={description}
										setValue={v => setDescription(v)}
										setValueE={e => setContentE(e)}
									/>
									<span style={{ color: 'red' }}>{content_e}</span>
									<input type="hidden" value={description} name="content" />
								</div>
								<div className="form-group mb-0">
									<label for=""> {t('teacher_webiran.file')} (.pdf, .doc, .docx, .ppt, .pptx)</label>
									<div className="custom-file">
										<input
											type="file"
											style={{ width: 120, height: 40 }}
											name="file"
											accept=".pdf, .doc, .docx, .ppt, .pptx"
										/>
										<div>{t('teacher_webiran.upload')}</div>
										{file ? (
											<button
												className="btn btn-sm"
												onClick={() => deleteImage('file')}
												style={{ color: 'white', background: 'red', marginLeft: 10 }}
												type="button">
												<span className="icon icon-remove" style={{ marginRight: 5 }}></span>
												<span>{t('teacher_text.delete-text')}</span>
											</button>
										) : null}
									</div>
									{file ? (
										<a
											href={FILE_URL + _.get(teacher_lesson_data, 'file')}
											className="btn btn-primary ml-auto mt-2"
											target="blank">
											{t('teacher_text.view-text')}
										</a>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="dashboard-buttons mt-5">
					{loading ? (
						<Loading />
					) : (
						<>
							<button
								type="button"
								className="btn btn-danger mr-auto"
								data-toggle="modal"
								data-target="#modalDeleteLesson"
								onClick={() => props.setDelLesson(teacher_lesson_data)}>
								{t('teacher_video.del')}
							</button>
							<button type="submit" className="btn btn-primary ml-auto">
								{t('teacher_video.save')}
							</button>
							<button
								type="submit"
								className="btn btn-primary ml-2"
								onClick={() => setRedirect(course_id)}>
								{t('teacher_video.save_section')}
							</button>
						</>
					)}
				</div>
			</form>
		</>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		updateLessonText: (data, redirect) => dispatch(updateLessonText(data, redirect)),
		deleteFile: data => dispatch(deleteFile(data)),
	}),
)(TeacherLessonText);
