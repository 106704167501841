import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Loading from "./Loading";
import { connect } from 'react-redux';
import { updateLessonAnswerGrouping } from '../../actions';
import _ from 'lodash';

const TestAnswerChange = props =>{
	const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [answer_e, setAnswerE] = useState('');
    const [score_e, setScoreE] = useState({});
    const [detail_e, setDetail_E] = useState({});
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({});
    const [detailE, setDetailE] = useState({});
    const [new_id, setNewId] = useState(1);
    const {updateLessonAnswerGrouping, update_lesson_answer_status, question_id, answers_data} = props;
    
    useEffect(()=>{
        if(answers_data.length<=0){
            setAnswers([...answers, {name: '', id: 'new'+new_id, details: [], score: '0'}]);
            setNewId(new_id+1);
        }else{
            let l = [];
            answers_data.forEach(item=>{
                l.push({
                    name: item.answer,
                    id: item.id,
                    score: item.score.toString(),
                    details: item.details.split(",")
                });
            });
            setAnswers(l);
        }
    },[answers_data]);
    useEffect(()=>{
        if(update_lesson_answer_status.status === 'success'){
            setLoading(false);
            setEdit(false);
        }
        if(update_lesson_answer_status.status === 'error'){
            setLoading(false);
        }
    },[update_lesson_answer_status]);

    const addDetail = (id, v) => {
        setDetailE({...detailE, [id]: ""});
        if(v){
            let l = [];
            answers.forEach(a => {
                if(a.id===id && v.trim() ){
                    let is_has = true;
                    a.details.forEach(item=>{
                        if(item.trim()===v.trim()) is_has = false;
                    });
                    if(is_has){
                        l.push({...a, details: [...a.details, v]});
                        setDetail({});
                    }else {
                        l.push(a);
                        setDetailE({...detailE, [id]: t('diagnostics.detail') });
                    }
                }else l.push(a);
            });
            setAnswers(l);
        }
    }
    const delDetail = (id, v) => {
        if(v){
            let l = [];
            answers.forEach(a => {
                if(a.id===id){
                    let dl = [];
                    a.details.forEach((d)=>{
                        if(d!==v) dl.push(d);
                    });
                    l.push({...a, details: dl});
                }else l.push(a);
            });
            setAnswers(l);
        }
    }
    const onKeyDownHandle = (id, e) =>{
        if(e.keyCode===13){
            addDetail(id, e.target.value);
        }
        if(e.keyCode===8 && !e.target.value){
            let l = [];
            answers.forEach( (a)=> {
                    if(a.id===id ){
                        let dl = [];
                        a.details.forEach((d, index)=>{
                            if(index!==a.details.length-1) dl.push(d);
                            setDetail({});
                        });
                        l.push({...a, details: dl});
                    }else l.push(a);
            });
            setAnswers(l);
        }
    }
    const changeAnsver = (id, n, v) => {
        let l = [];
        answers.forEach(a=>{
            if( a.id === id ){
                l.push({...a, [n]: v});
                if( n === 'name' ) setAnswerE({...answer_e, [a.id]: ''});
                if( n === 'score' ) setScoreE({...score_e, [a.id]: ''});
            }else l.push(a);
        });
        setAnswers(l);
    }
    const deleteAnsver = (id) => {
        let l = [];
        answers.forEach(a=>{
            if( a.id !== id ) l.push(a);
        });
        setAnswers(l);
    }
    const saveAnswer = () => {
        setLoading(true);
        let d = [];
        let is_check = true;
        let e_d = {}, s_e = {}, n_e = {};
        answers.forEach(a=>{
            if(!a.name){ 
                is_check = false;
                n_e = { ...n_e, [a.id]:  t('diagnostics.groups_n_e') };
            }
            if( !a.score || parseInt(a.score) < 0 ){ 
                is_check = false;
                s_e = {...s_e, [a.id]: t('diagnostics.score_e') };
            }
            if ( !a.details.length ){
                is_check = false;
                e_d = {...e_d, [a.id]: t('diagnostics.groups_d_e') };
            }
            d.push({
                id: a.id.toString().substring(0, 3)==='new'?'new':a.id,
                answer: a.name,
                details: a.details.length?a.details.toString():'',
                score: parseInt(a.score)
            });
        });
        if(is_check) updateLessonAnswerGrouping({
            question_id: question_id,
            answers:  JSON.stringify(  d )
        });
        else {
            setLoading(false);
            setAnswerE({...answer_e, ...n_e});
            setDetail_E({...detail_e, ...e_d});
            setScoreE({...score_e, ...s_e});
        }
    }
    return(<>
        <div className="card card--shadow ml-5 mt-1 mb-0">
            <div className="card-body card-body-diagnostics">
                <div className="row">
                    <div className="col-12 dig-title">
                        <div style={{fontSize: 14}}>{ t('diagnostics.groups') }</div>
                        {edit?
                            <span className="icon icon-close" onClick={()=>setEdit(false)}></span>
                        :
                            <span className="icon icon-edit dig-answer-edit" onClick={()=>setEdit(true)}></span>
                        }
                    </div>
                    {edit?<>
                        <div className="col-12 mb-1">
                            {answers.map((answer, index)=>(
                                <div className="row">
                                    <div  className="col-12">
                                        <div className="form-group mb-1">
                                            <label for=""> <b>{index+1}. { t('diagnostics.group_name') }  </b>
                                                <input type="text" className="form-control"   
                                                    value={answer.name}
                                                    onChange={(event)=>{
                                                        changeAnsver(answer.id, 'name', event.target.value);
                                                    }}
                                                />
                                                <div style={{color: 'red'}}>{_.get(answer_e, answer.id, '')}</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div  className="col-12">
                                        <label style={{margin: 0}}> { t('diagnostics.g_details') }</label>
                                        <label style={{margin: 0}}>
                                        <div  className="form-group mb-1 answer_grouping">
                                            {answer.details.map((item, index_item)=>(
                                            <span className="a_g_item" key={index_item}>
                                                {item}
                                                <span className="icon icon-close a_g_item_close" onClick={()=>delDetail(answer.id, item)}></span>
                                            </span> 
                                            ))}
                                            <input type="text" 
                                                // placeholder={ t('diagnostics.details_add_g') } 
                                                className='a_g_input'
                                                size={detail.id===answer.id?detail.v.length+1:2}
                                                value={detail.id===answer.id?detail.v:''}
                                                onChange={e=>{ setDetail( { id: answer.id,  v: e.target.value });
                                                    setDetailE({...detailE, [answer.id]: ""});
                                                    setDetail_E({...detail_e, [answer.id]: ""});
                                                } }
                                                onBlur={e=>{
                                                    addDetail(answer.id, e.target.value)
                                                }}
                                                onKeyDown={e=>{ onKeyDownHandle(answer.id, e) } }
                                            />
                                        </div>
                                        <div style={{color: 'red'}}>{_.get(detailE, answer.id, '')}</div>
                                        <div style={{color: 'red'}}>{_.get(detail_e, answer.id, '')}</div>
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-1">
                                            <label for=""> { t('diagnostics.score') } 
                                                <input type="number" className="form-control"  style={{width: 150}} 
                                                    value={answer.score}
                                                    onChange={(event)=>{
                                                        changeAnsver(answer.id, 'score', event.target.value);
                                                    }}
                                                />
                                                <div style={{color: 'red'}}>{_.get(score_e, answer.id, '')}</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 btn-target">
                                        <span className="button" style={{color: '#f00', margin: 'auto 10px', cursor: 'pointer', float: 'right'}}
                                            onClick={()=>deleteAnsver(answer.id)}
                                        ><span className="icon icon-remove"></span>
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-12">
                            <b style={{cursor: 'pointer', marginTop: 10, background: '#fff', border: 0, color: '#898DA6'}}
                                className="btn btn-primary btn-sm"
                                onClick={()=>{
                                    setAnswers([...answers, {name: '', id: 'new'+new_id, details: [], score: '0'}]);
                                    setNewId(new_id+1);
                                    }
                                }
                            >
                                <span className="icon icon-plus" style={{color: '#898DA6'}} ></span>  { t('diagnostics.answer_add_g') }
                            </b>
                        </div>
                        {answers.length?<div className="col-12">
                            {loading?<Loading/>:
                            <b className="btn btn-primary btn-sm"  onClick={()=>saveAnswer()} style={{float: 'right', margin: 10}}>
                                { t('diagnostics.save') }
                            </b>}
                        </div>:null}
                     </>: <>
                     {answers_data.length?answers_data.map((aa, index)=>(<>
                        <div className="col-12 col-md-9 col-lg-10 dig-title " style={{margin: '5px 0'}} key={index}>
                            <div className="test-items-target"  style={{width: '100%'}}>
                                <b>{index+1}.</b> {aa.answer} 
                                <div>
                                    {aa.details.split(",").map((item, index_item)=>(
                                        <span className="a_g_item" key={index_item}>{item}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 dig-title pl-0" >
                            <div className="dig-items-terget" style={{margin: '5px 0'}}>
                                <div className="test-items-target mr-2 mb-2 dig-item">  {aa.score}  {t('diagnostics.ball')}</div>
                            </div>
                        </div>
                    </>)):<div className="col-12"  style={{minHeight: 50}}>
                            <div className="test-items-target my-1 " style={{height: 50, display: 'block'}}></div>
                        </div>}
                    </>}
                </div>
            </div>
        </div>
    </>)
}
export default connect(
	state => ({
		update_lesson_answer_status: state.update_lesson_answer_status,
	}),
	dispatch => ({
		updateLessonAnswerGrouping: (data) => dispatch(updateLessonAnswerGrouping(data)),
	}),
)(TestAnswerChange);
