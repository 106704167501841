import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getExamsQuestions } from "../../actions";
import Layout from "../Layout";
import DashSideBar from "../sections/DashSideBar";
import Loading from "../sections/Loading";
import Navigation from "../sections/Navigation";
import NoData from "../sections/NoData";
import TestAnswerChange from "../sections/TestAnswerChange";
import TestExplanChange from "../sections/TestExplanChange";
import TestAnswerAdd from "../sections/TestAnswerAdd";
import TestAnswerGroupChange from "../sections/TestAnswerGroupChange";
import TestAnswerDragWords from "../sections/TestAnswerDragWords";
import CheckRole from "../sections/CheckRole";
import { roles } from "../../helpers/roles";
import TestExamQuestionAdd from "../sections/TestExamQuestionAdd";
import TestExamQuestionChange from "../sections/TestExamQuestionChange";
import { getInstance } from "../../helpers/httpClient";
import { Link } from "react-router-dom";

const Diadnostics = (props) => {
  const { t } = useTranslation();
  const id = 10;
  const { teacher_questions, teacher_questions_status } = props;
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categorys, setCategorys] = useState([]);

  useEffect(() => {
    props.getExamsQuestions(id);
    getCategory();
  }, []);
  useEffect(() => {
    if (teacher_questions_status.status === "started") {
      setLoading(true);
    }
    if (teacher_questions_status.status === "success") {
      window.clickOk = false;
      setLoading(false);
    }
    if (teacher_questions_status.status === "error") {
      setLoading(false);
    }
  }, [teacher_questions_status]);

  const getCategory = () => {
    getInstance()
      .get("api/v1/manager/exams/diagnostic/categories")
      .then((r) => {
        let l = [];
        (r?.data?.data ?? []).forEach((item) => {
          l.push({ label: item?.name, value: item?.id });
        });
        setCategorys(l);
      })
      .catch((r) => {})
      .finally(() => {});
  };
  return (
    <Layout>
      <CheckRole roles={[roles.teacher]} />
      <main className="main">
        <section className="section section-dashboard py-0">
          <div className="container">
            <div className="row">
              <DashSideBar />
              <div className="col-md-12 col-lg-9 col-xl-10">
                <div className="dashboard-right">
                  <Navigation
                    is_dash={true}
                    is_btn={true}
                    active={t("dash_side_bar.diagnostics-test")}
                    navs={[
                      {
                        title: t("navigation.account"),
                        link: "/dashboard",
                      },
                    ]}
                  />
                  <div className="dashboard-title dashboard-title--has-buttons">
                    <h5>{t("dash_side_bar.diagnostics-test")} </h5>
                    <div className="buttons">
                      <span
                        className="btn btn-primary  btn-sm font-weight-medium"
                        onClick={() => setAdd(true)}
                      >
                        <span
                          className="icon icon-plus mr-2"
                          style={{ color: "#fff" }}
                        ></span>
                        {t("diagnostics.add")}
                      </span>
                      <Link
                        to="/dashboard/diagnostic-statistic"
                        className="btn btn-primary  btn-sm"
                      >
                        <span
                          className="icon icon-grid mr-2"
                          style={{ color: "#fff" }}
                        ></span>
                        {t("dash_side_bar.statistic")}
                      </Link>
                    </div>
                  </div>
                  <div style={add ? {} : { display: "none" }}>
                    <TestExamQuestionAdd
                      close={() => setAdd(false)}
                      lesson_id={id}
                      is_exam={true}
                      categorys={categorys}
                      is_diagnostic={true}
                    />
                  </div>
                  <div>
                    {loading ? (
                      <Loading />
                    ) : teacher_questions && teacher_questions.length > 0 ? (
                      <>
                        {teacher_questions.map((question, index) => (
                          <div className="card card--shadow ">
                            <div
                              className="card-body"
                              style={{ margin: 0, padding: 0 }}
                              id={"question" + question?.id}
                            >
                              <TestExamQuestionChange
                                question_data={question}
                                index={index}
                                is_exam={true}
                                categorys={categorys}
                                is_diagnostic={true}
                              />
                              <div>
                                {question.type === "grouping" ? (
                                  <TestAnswerGroupChange
                                    question_id={question.id}
                                    is_exam={true}
                                    answers_data={question.answers}
                                  />
                                ) : question.type === "dragwords" ? (
                                  <TestAnswerDragWords
                                    question_id={question.id}
                                    is_exam={true}
                                    question={question}
                                  />
                                ) : (
                                  <>
                                    {question.answers.map((answer, index_a) => (
                                      <div
                                        key={index_a}
                                        id={"answer" + answer?.id}
                                      >
                                        <TestAnswerChange
                                          answer_data={answer}
                                          index_a={index_a + 1}
                                          is_exam={true}
                                        />
                                      </div>
                                    ))}
                                    <div>
                                      <TestAnswerAdd
                                        question_id={question.id}
                                        is_exam={true}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <div>
                                <TestExplanChange
                                  question_data={question}
                                  is_exam={true}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <NoData text={t("diagnostics.no_data")} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};
export default connect(
  (state) => ({
    ...state,
  }),
  (dispatch) => ({
    getExamsQuestions: (id) => dispatch(getExamsQuestions(id)),
  })
)(Diadnostics);
