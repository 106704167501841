import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import JoditText from "./JoditText";
import JoditTextShow from "./JoditTextShow";
import Select from "react-select";
import Loading from "./Loading";
import { connect } from "react-redux";
import { updateLessonQuestion, deleteQuestion } from "../../actions";
import { get } from "lodash";

const TestExamQuestionChange = (props) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [question, setQuestion] = useState("");
  const [question_e, setQuestionE] = useState("");
  const [answer, setAnswer] = useState(null);
  const [answer_e, setAnswerE] = useState("");
  const [extra_question, setExtra_question] = useState(null);
  const [extra_question_e, setExtra_questionE] = useState("");
  const [type, setType] = useState({
    value: "single",
    label: t("diagnostics.single"),
  });
  const {
    index,
    question_data,
    update_lesson_question_status,
    delete_question_status,
    is_exam = false,
  } = props;
  const [loading, setLoading] = useState(false);
  const [category_id, setCategory_id] = useState(0);
  const [category_e, setCategory_e] = useState("");

  useEffect(() => {
    if (update_lesson_question_status.status === "success") {
      setLoading(false);
      setEdit(false);
    }
    if (update_lesson_question_status.status === "error") {
      setLoading(false);
      // setEdit(false);
    }
  }, [update_lesson_question_status]);
  useEffect(() => {
    if (delete_question_status.status === "success") {
      setLoading(false);
      setEdit(false);
    }
    if (delete_question_status.status === "error") {
      setLoading(false);
      // setEdit(false);
    }
  }, [delete_question_status]);

  useEffect(() => {
    setQuestion(question_data.question);
    setAnswer(question_data.answer);
    setExtra_question(question_data.extra_question);
    setType(
      question_data.type === "dragwords"
        ? { value: "dragwords", label: t("diagnostics.dragwords") }
        : question_data.type === "grouping"
        ? { value: "grouping", label: t("diagnostics.grouping") }
        : question_data.type === "multiple"
        ? { value: "multiple", label: t("diagnostics.multiple") }
        : { value: "single", label: t("diagnostics.single") }
    );
    setCategory_id(
      get(
        (props?.categorys ?? []).filter(
          (o) => o.value === question_data?.category_id
        ),
        "0.value",
        0
      )
    );
  }, [question_data]);
  useEffect(() => {
    setCategory_id(
      get(
        (props?.categorys ?? []).filter(
          (o) => o.value === question_data?.category_id
        ),
        "0.value",
        0
      )
    );
  }, [props?.categorys]);

  const onSubmit = () => {
    if (
      question &&
      (props?.is_diagnostic ? true : answer && extra_question) &&
      (props?.is_diagnostic ? category_id : true)
    ) {
      setLoading(true);
      const data = {
        ...question_data,
        question: question,
        extra_question: extra_question,
        answer: answer,
        type: type.value,
        category_id: category_id,
      };
      props.updateLessonQuestion(data, is_exam);
    } else {
      if (!question) setQuestionE(t("diagnostics.q_e"));
      if (!answer) setAnswerE(t("teacher_test.text_e_answer"));
      if (!extra_question) setExtra_questionE(t("diagnostics.q_e"));
      if (!category_id) setCategory_e(t("create_course.cat"));
    }
  };

  return (
    <>
      <div className="card card--shadow  mb-0">
        <div className="card-body card-body-diagnostics">
          <div className="row">
            <div className="col-12 dig-title">
              <b>
                {index + 1}-{t("diagnostics.q")}
              </b>
              {edit ? (
                <span
                  className="icon icon-close"
                  onClick={() => setEdit(false)}
                ></span>
              ) : (
                <span
                  className="icon icon-edit"
                  onClick={() => setEdit(true)}
                ></span>
              )}
            </div>

            {edit ? (
              <>
                <div className="col-12 mb-1">
                  <label>{t("teacher_test.question")}</label>
                  <JoditText
                    value={question}
                    setValue={(v) => setQuestion(v)}
                    setValueE={(e) => setQuestionE(e)}
                  />
                  <div style={{ color: "red" }}>{question_e}</div>
                </div>
                {props?.is_diagnostic ? null : (
                  <>
                    <div className="col-12 mb-1">
                      <label>{t("teacher_test.text_answer")}</label>
                      <JoditText
                        value={answer}
                        setValue={(v) => setAnswer(v)}
                        setValueE={(e) => setAnswerE(e)}
                      />
                      <div style={{ color: "red" }}>{answer_e}</div>
                    </div>
                    <div className="col-12 mb-1">
                      <label>{t("diagnostics.question")}</label>
                      <JoditText
                        value={extra_question}
                        setValue={(v) => setExtra_question(v)}
                        setValueE={(e) => setExtra_questionE(e)}
                      />
                      <div style={{ color: "red" }}>{extra_question_e}</div>
                    </div>
                  </>
                )}
                {props?.is_diagnostic ? (
                  <div className="col-12">
                    <div className="form-group mb-1">
                      <label>
                        {t("courses.categorys")}
                        <div>
                          <Select
                            value={get(
                              (props?.categorys ?? []).filter(
                                (o) => o.value === category_id
                              ),
                              "0",
                              null
                            )}
                            onChange={(select) => {
                              setCategory_id(select?.value);
                              setCategory_e(false);
                            }}
                            options={props?.categorys ?? []}
                            placeholder={t("courses.categorys")}
                          />
                        </div>
                      </label>
                      <div style={{ color: "red" }}>{category_e}</div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-6">
                  <div className="form-group mb-1">
                    <label>
                      {t("diagnostics.type")}
                      <div>
                        <Select
                          value={type}
                          onChange={(select) => {
                            setType(select);
                          }}
                          options={
                            question_data.type === "dragwords"
                              ? [
                                  {
                                    value: "dragwords",
                                    label: t("diagnostics.dragwords"),
                                  },
                                ]
                              : question_data.type === "grouping"
                              ? [
                                  {
                                    value: "grouping",
                                    label: t("diagnostics.grouping"),
                                  },
                                ]
                              : [
                                  {
                                    value: "single",
                                    label: t("diagnostics.single"),
                                  },
                                  {
                                    value: "multiple",
                                    label: t("diagnostics.multiple"),
                                  },
                                ]
                          }
                          placeholder={t("diagnostics.type")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-6 btn-target">
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <button
                        className="btn btn-primary mb-1 mr-1 btn-sm"
                        onClick={() => onSubmit()}
                      >
                        {t("diagnostics.save")}
                      </button>
                      <button
                        className="btn btn-lighter mb-1 btn-sm"
                        onClick={() => {
                          setLoading(true);
                          props.deleteQuestion(
                            { id: question_data.id },
                            is_exam
                          );
                        }}
                      >
                        {t("diagnostics.delete")}
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col-12 col-md-9 col-lg-10 dig-title mb-2">
                  <div className="test-items-target" style={{ width: "100%" }}>
                    {" "}
                    <JoditTextShow
                      text={question ? question : "<p></p>"}
                    />{" "}
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-2 dig-title pl-0 mb-2">
                  <div className="dig-items-terget">
                    <div className="test-items-target mr-2 mb-2 dig-item">
                      {" "}
                      {question_data.score} {t("diagnostics.ball")}
                    </div>
                    <div className="test-items-target  dig-item">
                      {" "}
                      {type.value === "dragwords"
                        ? t("diagnostics.dragwords")
                        : type.value === "grouping"
                        ? t("diagnostics.grouping")
                        : type.value === "single"
                        ? t("diagnostics.single")
                        : t("diagnostics.multiple")}
                    </div>
                  </div>
                </div>

                {props?.is_diagnostic ? (
                  <div className="col-12 col-md-9 col-lg-10 dig-title mb-2">
                    <div
                      className="test-items-target"
                      style={{ width: "100%" }}
                    >
                      {get(
                        (props?.categorys ?? []).filter(
                          (o) => o.value === category_id
                        ),
                        "0.label",
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-12 col-md-9 col-lg-10 dig-title mb-2">
                      <div
                        className="test-items-target"
                        style={{ width: "100%" }}
                      >
                        <JoditTextShow text={answer ? answer : "<p></p>"} />{" "}
                      </div>
                    </div>
                    <div className="col-12 col-md-9 col-lg-10 dig-title mb-2">
                      <div
                        className="test-items-target"
                        style={{ width: "100%" }}
                      >
                        <JoditTextShow
                          text={extra_question ? extra_question : "<p></p>"}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(
  (state) => ({
    update_lesson_question_status: state.update_lesson_question_status,
    delete_question_status: state.delete_question_status,
  }),
  (dispatch) => ({
    updateLessonQuestion: (data, is_exam) =>
      dispatch(updateLessonQuestion(data, false, is_exam)),
    deleteQuestion: (data, is_exam) =>
      dispatch(deleteQuestion(data, false, is_exam)),
  })
)(TestExamQuestionChange);
