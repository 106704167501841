import React, { useState, useEffect } from "react";
import {
  getTeacherCourseSections,
  createSection,
  deleteSection,
  updateSection,
  orderSection,
} from "../../actions";
import { connect } from "react-redux";
import Layout from "../Layout";
import DashSideBar from "../sections/DashSideBar";
import Navigation from "../sections/Navigation";
import Loading from "../sections/Loading";
import NoData from "../sections/NoData";
import { Link } from "react-router-dom";
import $ from "jquery";
import "jquery-ui";
import "jquery-ui/ui/widgets/mouse";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui-touch-punch";
import { useTranslation } from "react-i18next";
import CheckRole from "../sections/CheckRole";
import { roles } from "../../helpers/roles";
import _ from "lodash";

window.jQuery = $;

const DashCourseItems = (props) => {
  const {
    teacher_course_sections,
    teacher_course_sections_status,
    teacher_section_create_status,
    teacher_section_delete_status,
    teacher_section_update_status,
    teacher_section_order_status,
  } = props;
  const { t } = useTranslation();
  const course_id = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [new_loading, setNewLoading] = useState(false);
  const [new_section, setNewSection] = useState({});
  const [del_loading, setDelLoading] = useState(false);
  const [del_section, setDelSection] = useState({});
  const [update_loading, setUpdateLoading] = useState(false);
  const [update_section, setUpdateSection] = useState("");
  const [disabled_section, setDisabletSection] = useState(0);
  const [change_section, setChangeSection] = useState([]);
  const [change_btn_section, setChangeBtnSection] = useState(0);
  const [order_loading, setOrderLoading] = useState(false);

  useEffect(() => {
    props.getTeacherCourseSections(props.match.params.id);
  }, []);
  useEffect(() => {
    if (teacher_course_sections_status.status === "started") {
      setLoading(true);
    }
    if (
      teacher_course_sections_status.status === "success" ||
      teacher_course_sections_status.status === "error"
    ) {
      setLoading(false);
    }
  }, [teacher_course_sections_status.status]);

  useEffect(() => {
    // Sort the parents
    $(".sortable").sortable({
      containment: "parent",
      items: "> div",
      handle: ".move",
      tolerance: "pointer",
      cursor: "move",
      opacity: 0.7,
      revert: 300,
      delay: 150,
      placeholder: "movable-placeholder",
      start: function (e, ui) {
        ui.placeholder.height(ui.helper.outerHeight());
      },
    });
    // Sort the children
    $(".group-items").sortable({
      items: "> div",
      connectWith: ".group-items",
      handle: ".move",
    });
  });

  useEffect(() => {
    if (teacher_section_create_status.status === "started") {
      setNewLoading(true);
    }
    if (teacher_section_create_status.status === "success") {
      window.clickOk = false;
      $("body").removeClass("modal-open");
      $("body").css("padding-right", "");
      $("#modalCreateSection").hide();
      $("#modalCreateSection").removeClass("show");
      $("#modalCreateSection").attr("aria-hidden", true);
      $("#modalCreateSection").removeAttr("aria-modal");
      $(".modal-backdrop").remove();
      $("#modalCreateSection").css("padding-right", "");
      setNewLoading(false);
      SectionOrder("");
    }
    if (teacher_section_create_status.status === "error") {
      setNewLoading(false);
    }
  }, [teacher_section_create_status]);

  useEffect(() => {
    if (teacher_section_delete_status.status === "started") {
      setDelLoading(true);
    }
    if (teacher_section_delete_status.status === "success") {
      window.clickOk = false;
      $("body").removeClass("modal-open");
      $("#modalDeleteSection").hide();
      $("#modalDeleteSection").removeClass("show");
      $("#modalDeleteSection").attr("aria-hidden", true);
      $("#modalDeleteSection").removeAttr("aria-modal");
      $(".modal-backdrop").remove();
      $("body").css("padding-right", "");
      $("#modalDeleteSection").css("padding-right", "");
      setDelLoading(false);
    }
    if (teacher_section_delete_status.status === "error") {
      setDelLoading(false);
    }
  }, [teacher_section_delete_status]);

  useEffect(() => {
    // if(teacher_section_update_status.status === 'started'){
    //     setUpdateLoading(true);
    // }
    if (teacher_section_update_status.status === "success") {
      let l = [];
      change_section.forEach((s) => {
        if (s !== teacher_section_update_status.id) {
          l.push(s);
        }
      });
      setChangeSection(l);
      setChangeBtnSection(0);
      setUpdateLoading(0);
      setDisabletSection(0);
    }
    if (teacher_section_update_status.status === "error") {
      setUpdateLoading(0);
    }
  }, [teacher_section_update_status]);
  useEffect(() => {
    if (teacher_section_order_status.status === "started") {
      setOrderLoading(true);
    }
    if (teacher_section_order_status.status === "success") {
      setOrderLoading(false);
      window.scrollTo(0, 0);
    }
    if (teacher_section_order_status.status === "error") {
      setOrderLoading(false);
      window.scrollTo(0, 0);
    }
  }, [teacher_section_order_status]);

  const SectionHandle = () => {
    //create
    setNewLoading(true);
    props.createSection(new_section);
  };
  const SectionDeleteHandle = () => {
    //delete
    setDelLoading(true);
    props.deleteSection(del_section);
  };
  const SectionUpdateHandle = (data) => {
    //delete
    // setDisabletSection(0);
    setUpdateLoading(data.id);
    props.updateSection(data);
  };
  const SectionOrder = (link = "") => {
    setOrderLoading(true);
    let section__order = [];
    let child_index = 0;
    $(".group-caption").each(function (index, item) {
      let childs__order = [];
      $(".group-item", item).each(function (index_paragraph, paragraph) {
        childs__order.push({ id: parseInt(paragraph.id), order: child_index });
        child_index++;
      });
      section__order.push({
        id: parseInt(item.id),
        order: index,
        childs: childs__order,
      });
    });
    props.orderSection(
      { course_id: props.match.params.id, sections: section__order },
      link
    );
  };
  return (
    <Layout>
      <CheckRole roles={[roles.teacher]} />
      <main className="main">
        <section className="section section-dashboard py-0">
          <div className="container">
            <div className="row">
              <DashSideBar />
              <div className="col-md-12 col-lg-9 col-xl-10">
                <div className="dashboard-right">
                  <Navigation
                    is_dash={true}
                    is_btn={true}
                    active={t("course_items.items")}
                    navs={[
                      {
                        title: t("navigation.account"),
                        link: "/dashboard",
                      },
                      {
                        title: t("author_page.course"),
                        link: "/dashboard/copyrights",
                      },
                    ]}
                  />
                  <div className="dashboard-title">
                    {/* <h5>Добавление нового курса</h5> */}
                  </div>
                  {loading ? (
                    <Loading />
                  ) : Array.isArray(teacher_course_sections) ? (
                    <div className="row row-dashboard-sidebar">
                      <div
                        className="col-12"
                        style={{ flex: "0 0 100%", maxWidth: "100%" }}
                      >
                        <div className="card card--shadow mb-5">
                          <div className="card-body">
                            <h6 className="mb-4">{t("course_items.items")}</h6>
                            <div className="sortable">
                              {teacher_course_sections?.map(
                                (section, index) => (
                                  <div
                                    className="group-caption"
                                    key={index}
                                    id={section.id}
                                  >
                                    <div className="group-parent">
                                      <div className="move">
                                        <span className="icon icon-hamburger"></span>
                                      </div>
                                      <div
                                        className="clmn-1"
                                        onClick={() => {
                                          setDisabletSection(section.id);
                                          // setUpdateSection('');
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={section.name}
                                          // disabled={disabled_section===section.id?false:true}
                                          onChange={(event) => {
                                            if (
                                              disabled_section === section.id
                                            ) {
                                              setUpdateSection(
                                                event.target.value
                                              );
                                            }
                                          }}
                                          onClick={(event) => {
                                            setUpdateSection(
                                              event.target.value
                                            );
                                            setChangeBtnSection(section.id);
                                          }}
                                          onBlur={(event) => {
                                            if (
                                              disabled_section === section.id &&
                                              section.name !==
                                                event.target.value
                                            ) {
                                              setChangeSection([
                                                ...change_section,
                                                section.id,
                                              ]);
                                            }
                                          }}
                                          style={
                                            change_section.includes(section.id)
                                              ? { background: "#FFFF80" }
                                              : null
                                          }
                                        />
                                        <div
                                          className={
                                            _.get(section, "childs", []).length
                                              ? "buttons section-child-has"
                                              : "buttons section-child-no"
                                          }
                                        >
                                          {change_btn_section === section.id &&
                                          update_loading !== section.id ? (
                                            <button
                                              type="button"
                                              className="button"
                                              onClick={() =>
                                                SectionUpdateHandle({
                                                  ...section,
                                                  name: update_section,
                                                })
                                              }
                                            >
                                              <span className="icon icon-check"></span>
                                            </button>
                                          ) : null}
                                          {update_loading === section.id ? (
                                            <button
                                              type="button"
                                              className="button"
                                            >
                                              <Loading size={4} />
                                            </button>
                                          ) : null}
                                          <button
                                            type="button"
                                            className="button"
                                            data-toggle="modal"
                                            data-target="#modalDeleteSection"
                                            onClick={() =>
                                              setDelSection(section)
                                            }
                                          >
                                            <span className="icon icon-remove"></span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="group-items">
                                      {section.childs
                                        ? section.childs.map(
                                            (paragraph, index_child) => (
                                              <div
                                                className="group-item"
                                                key={index_child}
                                                id={paragraph.id}
                                              >
                                                <div className="move">
                                                  <span className="icon icon-hamburger"></span>
                                                </div>
                                                <div
                                                  className="clmn-1"
                                                  onClick={() => {
                                                    setDisabletSection(
                                                      paragraph.id
                                                    );
                                                    // setUpdateSection('');
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={
                                                      paragraph.name
                                                    }
                                                    // disabled={disabled_section===paragraph.id?false:true}
                                                    onChange={(event) => {
                                                      if (
                                                        disabled_section ===
                                                        paragraph.id
                                                      ) {
                                                        setUpdateSection(
                                                          event.target.value
                                                        );
                                                      }
                                                    }}
                                                    onClick={(event) => {
                                                      setUpdateSection(
                                                        event.target.value
                                                      );
                                                      setChangeBtnSection(
                                                        paragraph.id
                                                      );
                                                    }}
                                                    onBlur={(event) => {
                                                      if (
                                                        disabled_section ===
                                                          paragraph.id &&
                                                        paragraph.name !==
                                                          event.target.value
                                                      ) {
                                                        setChangeSection([
                                                          ...change_section,
                                                          paragraph.id,
                                                        ]);
                                                      }
                                                    }}
                                                    style={
                                                      change_section.includes(
                                                        paragraph.id
                                                      )
                                                        ? {
                                                            background:
                                                              "#FFFF80",
                                                          }
                                                        : null
                                                    }
                                                  />
                                                  <div
                                                    className={
                                                      _.get(
                                                        paragraph,
                                                        "lessons",
                                                        false
                                                      )
                                                        ? "buttons section-child-has"
                                                        : "buttons section-child-no"
                                                    }
                                                  >
                                                    {change_btn_section ===
                                                      paragraph.id &&
                                                    update_loading !==
                                                      paragraph.id ? (
                                                      <button
                                                        type="button"
                                                        className="button"
                                                        onClick={() =>
                                                          SectionUpdateHandle({
                                                            ...paragraph,
                                                            name: update_section,
                                                          })
                                                        }
                                                      >
                                                        <span className="icon icon-check"></span>
                                                      </button>
                                                    ) : null}

                                                    {update_loading ===
                                                    paragraph.id ? (
                                                      <button
                                                        type="button"
                                                        className="button"
                                                      >
                                                        <Loading size={4} />
                                                      </button>
                                                    ) : null}

                                                    <Link
                                                      to={
                                                        "/dashboard/copyrights/course-items/" +
                                                        course_id +
                                                        "/section/" +
                                                        paragraph.id +
                                                        "/lesson"
                                                      }
                                                      className="button"
                                                    >
                                                      <span className="icon icon-edit"></span>
                                                    </Link>
                                                    <button
                                                      type="button"
                                                      className="button"
                                                      data-toggle="modal"
                                                      data-target="#modalDeleteSection"
                                                      onClick={() =>
                                                        setDelSection(paragraph)
                                                      }
                                                    >
                                                      <span className="icon icon-remove"></span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )
                                        : null}
                                    </div>
                                    <div className="group-buttons">
                                      <span
                                        className="group-buttons__add"
                                        style={{ cursor: "pointer" }}
                                        data-toggle="modal"
                                        data-target="#modalCreateSection"
                                        onClick={() =>
                                          setNewSection({
                                            name: "",
                                            course_id: course_id,
                                            section_type: "paragraph",
                                            parent_id: section.id,
                                          })
                                        }
                                      >
                                        <span className="icon icon-plus"></span>
                                        {t("course_items.add_s")}
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            <label for=""> {t("course_items.add_label")}</label>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm px-3 font-weight-medium"
                              data-toggle="modal"
                              data-target="#modalCreateSection"
                              onClick={() =>
                                setNewSection({
                                  name: "",
                                  course_id: course_id,
                                  section_type: "section",
                                })
                              }
                            >
                              <span className="icon icon-plus mr-2"></span>
                              {t("course_items.add")}
                            </button>
                            {/* <div className="mt-3">
                                                <label for="">Kurs So'rovnomasi</label>
                                                <div className="question_course">
                                                    <span className="icon icon-question" style={{marginRight: 25}}></span>
                                                    <span style={{color: '#0D2E69', fontWeight: 500}}>So'rovnoma</span>
                                                    <Link to={`/dashboard/copyrights/course-items/${course_id}/surveys`} className="icon icon-edit question_course_edit"></Link>
                                                </div>
                                            </div> */}
                          </div>
                        </div>
                        {order_loading ? (
                          <Loading />
                        ) : (
                          <div className="test__buttons mb-5">
                            <div className="dashboard-buttons">
                              <button
                                className="btn btn-primary mr-2"
                                onClick={() =>
                                  SectionOrder(
                                    "/dashboard/copyrights/course-update/" +
                                      props.match.params.id
                                  )
                                }
                              >
                                {t("course_items.save_d")}
                              </button>
                              <button
                                className="btn btn-primary ml-2"
                                onClick={() => SectionOrder()}
                              >
                                {" "}
                                {t("course_items.save")}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <NoData text={t("course_items.no_data")} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* create  */}
      <div
        className="modal fade"
        id="modalCreateSection"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalCreateSectionTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {new_section.parent_id
                  ? t("course_items.add_s")
                  : t("course_items.add")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon icon-close"></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <label for="">
                  {new_section.parent_id
                    ? t("course_items.add_s")
                    : t("course_items.add")}{" "}
                  {t("course_items.name")}
                </label>
                <textarea
                  id=""
                  rows="4"
                  className="form-control"
                  value={new_section.name ? new_section.name : ""}
                  onChange={(event) =>
                    setNewSection({ ...new_section, name: event.target.value })
                  }
                ></textarea>
              </div>
              {new_loading ? (
                <Loading />
              ) : (
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  onClick={() => SectionHandle()}
                >
                  {t("course_items.save")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* delete */}
      <div
        className="modal fade"
        id="modalDeleteSection"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalDeleteSectionTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {del_section.parent_id
                  ? t("course_items.delete_lesson")
                  : t("course_items.delete_section")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon icon-close"></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <label for="">
                  {del_section.parent_id
                    ? t("course_items.delete_lesson")
                    : t("course_items.delete_section")}
                  : {del_section.name}
                </label>
              </div>
              {del_loading ? (
                <Loading />
              ) : (
                <button
                  type="button"
                  className="btn btn-danger w-100"
                  onClick={() => SectionDeleteHandle()}
                >
                  {t("course_items.delete")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default connect(
  (state) => ({
    ...state,
  }),
  (dispatch) => ({
    getTeacherCourseSections: (id) => dispatch(getTeacherCourseSections(id)),
    createSection: (data) => dispatch(createSection(data)),
    deleteSection: (data) => dispatch(deleteSection(data)),
    updateSection: (data) => dispatch(updateSection(data)),
    orderSection: (data, link) => dispatch(orderSection(data, link)),
  })
)(DashCourseItems);
