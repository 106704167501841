import React from "react";
import Layout from "../Layout";
import CheckRole from "../sections/CheckRole";
import { roles } from "../../helpers/roles";
import DashSideBar from "../sections/DashSideBar";
import Navigation from "../sections/Navigation";
import { useTranslation } from "react-i18next";
import ResultPupil from "../sections/ResultPupil";
import { getInstance } from "../../helpers/httpClient";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../sections/Loading";

const DiadnosticStatistic = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getStatistic();
  }, []);
  const getStatistic = () => {
    setLoading(true);
    getInstance()
      .get("api/v1/user/diagnostic/passing-all")
      .then((r) => {
        const d = r?.data?.data;
        let l = [];
        d.forEach((item) => {
          l.push({
            name: item?.category?.name ?? "",
            persent: parseFloat(
              (
                (parseFloat(item?.score) * 100) /
                parseFloat(item?.maxsource)
              ).toFixed(2)
            ),
          });
        });
        setData(l);
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Layout>
        <CheckRole roles={[roles.teacher]} />
        <main className="main">
          <section className="section section-dashboard py-0">
            <div className="container">
              <div className="row">
                <DashSideBar />
                <div className="col-md-12 col-lg-9 col-xl-10">
                  <div className="dashboard-right">
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      active={t("test_result.diagnostics-results")}
                      navs={[
                        {
                          title: t("navigation.account"),
                          link: "/dashboard",
                        },
                        {
                          title: t("dash_side_bar.diagnostics-test"),
                          link: "/dashboard/diagnostics",
                        },
                      ]}
                    />
                    <div className="dashboard-title dashboard-title--has-buttons">
                      <h5>{t("test_result.diagnostics-results")} </h5>
                      <div className="buttons"></div>
                    </div>
                    {loading ? (
                      <Loading />
                    ) : (
                      <div>
                        <ResultPupil data={data} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default DiadnosticStatistic;
