import React, { useState } from 'react';
import { removeToken } from '../../helpers/tokenStorge';
import Layout from '../Layout';
import ForgotNewPassword from '../sections/ForgotNewPassword';
import ForgotPassword from '../sections/ForgotPassword';
import ForgotValidateBotSms from '../sections/ForgotValidateBotSms';
import ForgotValidateSms from '../sections/ForgotValidateSms';
import SignIn from '../sections/SignIn';
import SignUp from '../sections/SignUp';
import ValidateBotSMS from '../sections/ValidateBotSMS';
import ValidateSMS from '../sections/ValidateSMS';
import ValidNewPassword from '../sections/ValidNewPassword';
const AuthOldToken = () => {
	const [act, actState] = useState(0);
	// removeToken();
	// localStorage.removeItem('role');
	return (
		<Layout>
			<main className='main old-token'>
				<section className='section section-dashboard py-0'>
					<div className='container'>
						<br/>
						<br/>
						<br/>
						<div className='row'>
							<div className='col-12 col-md-2 col-lg-3 col-lx-4'></div>
							<div className='col-12 col-md-8 col-lg-6 col-lx-4'>
								<div className='card my-5'>
									<div className='card-body'>
										{act === 1 ? (
											<SignUp actState={a => actState(a)} />
										) : act === 2 ? (
											<ValidateSMS actState={a => actState(a)} />
										) : act === 3 ? (
											<ForgotPassword actState={a => actState(a)} />
										) : act === 4 ? (
											<ForgotValidateSms
												actState={a => actState(a)}
											/>
										) : act === 5 ? (
											<ForgotNewPassword
												actState={a => actState(a)}
											/>
										) : act === 6 ? (
											<ValidateBotSMS actState={a => actState(a)} />
										) : act === 7 ? (
											<ForgotValidateBotSms
												actState={a => actState(a)}
											/>
										) : act === 8 ? (
											<ValidNewPassword
												actState={a => actState(a)}
											/>
										) : (
											<SignIn actState={a => actState(a)} />
										)}
									</div>
								</div>
							</div>
							<div className='col-12 col-md-2 col-lg-3 col-lx-4'></div>
						</div>
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
					</div>
				</section>
				
			</main>
		</Layout>
	);
};
export default AuthOldToken;
