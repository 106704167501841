import React from 'react';
import { connect } from "react-redux";
import styles from './Messages.module.css'
import Message from './Message'
import {
    close_message, set_message
 } from '../../../actions';
 
const Messages = (props) => {
	// useEffect(()=>{
	// 	props.set_message({text: 'Success', type: "s", lasting: true});
	// 	props.set_message({text: 'Error', type: "e"});
	// 	props.set_message({text: 'Warning', type: "w", lasting: true});

	// },[]);
	return (
		<div className={styles.target}>
			{props.message_list.length?<div className={styles.title}>Сообщение:</div>:''}
			{props.message_list.map((message,index)=>(
				<div key={index}>
					<Message message={message} 
						close_message={(time)=>props.close_message(time)}
					/>
				</div>
			))}
		</div>
	);
}
export default 	connect(
	(state) => ({
		message_list: state.message_list,
	}),
	(dispatch) => ({
		close_message: (time)=>dispatch(close_message(time)),
		set_message: (message)=>dispatch(set_message(message))
	})
)( Messages );