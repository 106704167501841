import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import JoditText from './JoditText';
import Loading from "./Loading";
import { connect } from 'react-redux';
import { updateLessonAnswer } from '../../actions';


const VideoAnswerAdd = props =>{
	const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [answer, setAnswer] = useState('');
    const [answer_e, setAnswerE] = useState('');
    const [score, setScore] = useState(0);
    const [score_e, setScoreE] = useState('');
    const [loading, setLoading] = useState(false);
    const {question_id} = props;
    const {update_lesson_answer_status} = props;
    useEffect(()=>{
        if(update_lesson_answer_status.status === 'success'){
            setLoading(false);
            setAnswer('');
            setScore(0);
            setEdit(false);
        }
        if(update_lesson_answer_status.status === 'error'){
            setLoading(false);
            setAnswer('');
            setScore(0);
            // setEdit(false);
        }
        
    },[update_lesson_answer_status]);

    const onSubmit = () => {
        if(answer){
            setLoading(true);
            const data ={
                question_id: question_id,
                answer: answer,
                score: score?score:0
            }
            props.updateLessonAnswer(data);
        }else{
            setAnswerE( t('diagnostics.answer_e') );
        }
    }

    return(<>
    {edit?<>
        <div className="card card--shadow ml-5 mt-1 mb-0">
            <div className="card-body card-body-diagnostics">
                <div className="row">
                    <div className="col-12 dig-title">
                        
                            <b>{ t('diagnostics.answer_add') }</b>
                            <span className="icon icon-close" onClick={()=>setEdit(false)}></span>
                        
                    </div>
                    {edit?<>
                    <div className="col-12 mb-1">
                        <label>
                        { t('diagnostics.answer_t') }
                        </label>
                        <JoditText 
                            value = {answer}
                            setValue = { v => setAnswer(v)}
                            setValueE = { e => setAnswerE(e)}
                        />
                        <div style={{color: 'red'}}>{answer_e}</div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-1">
                            <label for=""> { t('diagnostics.score') } 
                                <input type="number" className="form-control"  style={{width: 150}} 
                                    value={score}
                                    onChange={(event)=>{
                                        setScore(event.target.value);
                                        setScoreE('');
                                    }}
                                />
                                <div style={{color: 'red'}}>{score_e}</div>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6 btn-target">
                        {loading?<Loading/>:<>
                            <button className="btn btn-primary mb-1 mr-1 btn-sm"
                                onClick={()=>onSubmit()}
                            >
                                { t('diagnostics.save') }
                            </button>
                        </>}
                    </div>
                    </>:null}
                </div>
            </div>
        </div>
        </>:<>
            <b style={{cursor: 'pointer', marginLeft: 50, marginTop: 10, background: '#fff', border: 0, color: '#898DA6'}}  onClick={()=>setEdit(true)}  className="btn btn-primary btn-sm">
                <span className="icon icon-plus" style={{color: '#898DA6'}} ></span>  { t('diagnostics.answer_add') }
            </b>
        </>}
    </>)
}
export default connect(
	state => ({
		update_lesson_answer_status: state.update_lesson_answer_status,
	}),
	dispatch => ({
		updateLessonAnswer: (data) => dispatch(updateLessonAnswer(data, true)),
	}),
)(VideoAnswerAdd);