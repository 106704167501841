import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteFile, updateLessonVideo } from '../../actions';
import { FILE_URL } from '../../helpers/api';
import JoditText from './JoditText';
import Loading from './Loading';
import VideoPlayer from './VideoPlayer';

const TeacherLessonVideo = props => {
	const { t } = useTranslation();
	const { teacher_lesson_data, update_lesson_video_status, course_id, deleteFile } = props;
	const [loading, setLoading] = useState(false);
	const [score_e, setScoreE] = useState('');
	const [name_e, setNameE] = useState('');
	const [content_e, setContentE] = useState('');
	const [redirect, setRedirect] = useState('');
	const [score, setScore] = useState(0);
	const [description, setDescription] = useState('');
	const [short_description, setShortDescription] = useState('');
	const [file, setFile] = useState('');
	const [videoData, setVideoData] = useState({});

	useEffect(() => {
		if (update_lesson_video_status.status === 'success') {
			window.scrollTo(0, 0);
			setLoading(false);
		}
		if (update_lesson_video_status.status === 'error') {
			window.scrollTo(0, 0);
			setLoading(false);
		}
	}, [update_lesson_video_status]);
	useEffect(() => {
		if (_.get(teacher_lesson_data, 'file')) {
			setFile(_.get(teacher_lesson_data, 'file'));
			setVideoData({data: {
				file: _.get(teacher_lesson_data, 'file', ''),
				is_enc:  _.get(teacher_lesson_data, 'is_enc', 0)
			}})
		}
		setScore(_.get(teacher_lesson_data, 'score', 0));
		setDescription(_.get(teacher_lesson_data, 'description', ''));
		setShortDescription(_.get(teacher_lesson_data, 'short_description', ''));
	}, [props.teacher_lesson_data]);
	const onChangeScore = value => {
		const v = parseInt(value);
		if (Number.isInteger(v) && v >= 0) {
			setScore(v);
		} else {
			setScore('');
		}
	};
	const UpdateLesson = event => {
		event.preventDefault();
		setLoading(true);
		let formData = new FormData(event.target);
		let name = formData.get('name');
		let score = formData.get('score');
		let content = formData.get('description');
		let n = true,
			c = true,
			s = true;
		if (name === '') {
			n = false;
			setNameE(t('teacher_video.name_e'));
		}
		if (score === '') {
			s = false;
			setScoreE(t('teacher_video.score_e'));
		}
		if (content === '') {
			c = false;
			setContentE(t('teacher_video.description_e'));
		}
		if (n && c && s) {
			props.updateLessonVideo(formData, redirect);
		} else {
			setLoading(false);
		}
	};
	const deleteImage = () => {
		const id = _.get(teacher_lesson_data, 'id', false);
		document.getElementById('video_file').value = null;
		if( id && _.get(teacher_lesson_data, 'file', false) ){
			deleteFile({ id: id, type: 'lesson', field: ['file'] });
		}
		setFile('');
		setVideoData({});
	};
	return (
		<>
			<form onSubmit={UpdateLesson} noValidate>
				<div className="tab-content" id="newLessonContent">
					<div id="new-lesson-1-2">
						<div className="row row-min-8 new-lesson__tab-content">
							<div className="col ">
								<input type="hidden" name="id" value={_.get(teacher_lesson_data, 'id')} />
								<div className="form-group">
									<label for="">{t('teacher_video.name')}</label>
									<input
										type="text"
										className="form-control"
										name="name"
										defaultValue={_.get(teacher_lesson_data, 'name')}
										onClick={() => setNameE('')}
									/>
									<span style={{ color: 'red' }}>{name_e}</span>
								</div>
								<div className="form-group">
									<label for="">{t('teacher_video.score')}</label>
									<input
										type="text"
										className="form-control "
										style={{ width: 150 }}
										name="score"
										onClick={() => setScoreE('')}
										value={score}
										onChange={event => onChangeScore(event.target.value)}
									/>
									<span style={{ color: 'red' }}>{score_e}</span>
								</div>
								<div className="form-group">
									<label for="">{t('teacher_video.short_description')}</label>
									<JoditText
										value={short_description}
										setValue={v => setShortDescription(v)}
										setValueE={e => {}}
									/>
									{/* <span style={{ color: 'red' }}>{content_e}</span> */}
									<input type="hidden" value={short_description} name="short_description" />
								</div>
								<div className="form-group">
									<label for="">{t('teacher_video.description')}</label>
									<JoditText
										value={description}
										setValue={v => setDescription(v)}
										setValueE={e => setContentE(e)}
									/>
									<span style={{ color: 'red' }}>{content_e}</span>
									<input type="hidden" value={description} name="description" />
								</div>
								<div className="form-group">
									<label for="">{t('teacher_video.file')} (.mp4)</label>
									<div className="custom-file">
										<input
											id='video_file'
											type="file"
											style={{ width: 200, height: 40 }}
											name="video"
											accept=".mp4"
											onChange={e=>{
												const file = e.target.files[0];
  												const url = URL.createObjectURL( file );
												// setVideoData({data: {file: url, is_local: true}});
												setFile(url); 
											}}
										/>
										<div>{t('teacher_video.file')}</div>
										{file ? (
											<button
												className="btn btn-sm"
												onClick={() => deleteImage('file')}
												style={{ color: 'white', background: 'red', marginLeft: 10 }}
												type="button">
												<span className="icon icon-remove" style={{ marginRight: 5 }}></span>
												<span>{t('teacher_video.delete-video')}</span>
											</button>
										) : null}
									</div>
									<div className='mt-2'>
										{(videoData?.data?.file??null)?<>
											<VideoPlayer course_lesson={videoData}/>
										</>:''}
									</div>
									{/* {file ? (
										<a
											href={FILE_URL + _.get(teacher_lesson_data, 'file')}
											className="btn btn-primary ml-auto mt-2"
											target="blank">
											{t('teacher_video.file_btn')}
										</a>
									) : null} */}
								</div>
								{/* <div className="form-group mb-0">
									<label for="">{t('teacher_video.qualities')}</label>
									<label className="checkbox-custom lg text-secondary mb-3">
										<input type="checkbox" name="qualities" value="1080" />
										<p>1080 (FullHD)</p>
										<span className="checkmark"></span>
									</label>
									<label className="checkbox-custom lg text-secondary mb-3">
										<input type="checkbox" name="qualities" value="720" />
										<p>720 (HD)</p>
										<span className="checkmark"></span>
									</label>
									<label className="checkbox-custom lg text-secondary mb-3">
										<input type="checkbox" name="qualities" value="480" />
										<p>480h</p>
										<span className="checkmark"></span>
									</label>
									<label className="checkbox-custom lg text-secondary">
										<input type="checkbox" defaultChecked={true} name="qualities" value="360" />
										<p>360h</p>
										<span className="checkmark"></span>
									</label>
								</div> */}
							</div>
						</div>
					</div>
				</div>
				<div className="dashboard-buttons mt-5">
					{loading ? (
						<Loading />
					) : (
						<>
							<button
								type="button"
								className="btn btn-danger mr-auto"
								data-toggle="modal"
								data-target="#modalDeleteLesson"
								onClick={() => props.setDelLesson(teacher_lesson_data)}>
								{t('teacher_video.del')}
							</button>
							<button type="submit" className="btn btn-primary ml-auto">
								{t('teacher_video.save')}
							</button>
							<button
								type="submit"
								className="btn btn-primary ml-2"
								onClick={() => setRedirect(course_id)}>
								{t('teacher_video.save_section')}
							</button>
						</>
					)}
				</div>
			</form>
		</>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		updateLessonVideo: (data, redirect) => dispatch(updateLessonVideo(data, redirect)),
		deleteFile: data => dispatch(deleteFile(data)),
	}),
)(TeacherLessonVideo);
