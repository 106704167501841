import React, {useState} from 'react';
import PhoneInput from "./PhoneInput";
import Loading from './Loading';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getInstance } from '../../helpers/httpClient';

const  PhoneUpdateCheck = (props) => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [loading, setLoading] = useState(false); 
    const profile = useSelector(state => state.profile);
    const dispatch = useDispatch();
    const onSubmit = event => {
		event.preventDefault();
        setLoading(true);
        let p=true;
        if(phone.length!==12){
            setPhoneError(t('update_phone.phone_e'));
            p=false;
        }else{
            setPhoneError('');
            p=true;
        }
        if(p){
            getInstance().post('/api/v1/profil/replace-phone', {
                phone: phone
            }).then(res=>{
                const s = _.get(res, 'data.success', 0);
                if(s===2) setPhoneError(_.get(res, 'data.msg', '')); 
                else{
                    props.actState(1);
                    dispatch({
                        type: 'UPDATE_PHONE',
                        payload: {
                            phone: phone,
                            bot_url: _.get(res, 'data.bot_url', '')
                        }
                    });
                }
                setLoading(false);
            }).catch(e=>{
                setLoading(false);
            });
        }else  setLoading(false);
    }
    return (<>
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{t('update_phone.update_phone')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span className="icon icon-close"></span>
            </button>

            </div>
            <div className="modal-body">
                <form onSubmit={onSubmit} noValidate>
                    <div className="form-group mb-3">
                        <label for="">{t('update_phone.old')+' +'+_.get(profile, 'name')}</label>
                        <label for="">{t('update_phone.new')}</label>
                        <PhoneInput
                            inputClass="form-control"
                            onClickInput={()=>{ setPhoneError("");  }}
                            setPhone={(phone)=>setPhone(phone)}
                        />
                    </div>
                    <div className="form-group mt-4 mb-5">
                        <div style={{color: 'red'}}>{phoneError}</div>
                    </div>
                    {loading?<Loading />:
                    <button type="submit" className="btn btn-primary w-100">
                        {t('update_phone.send')}
                    </button>
                    } 
                </form>
            </div>
        </div>
  </>);
}
export default  PhoneUpdateCheck;