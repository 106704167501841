import React, { useState } from "react"; 
import Loading from "./Loading";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getInstance } from "../../helpers/httpClient";
import { useDispatch, useSelector } from "react-redux";

const PhoneUpdateBotSms = (props) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [loading, setLoading] = useState(false);
  const [commonError, setCommonError] = useState("");
  const profile = useSelector(state => state.profile);
  const update_phone = useSelector(state => state.update_phone);
  const dispatch = useDispatch();

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let c = true, phone_c = true;
    if (code > 99999 || code < 10000) {
      setCodeError(t("validate_sms.code_e"));
      c = false;
    } else {
      setCodeError("");
      c = true;
    }
    const phone = _.get(update_phone, 'phone', '');
    if (phone && phone.length !== 12) {
      setCommonError("Error");
      phone_c = false;
    } else {
      setCommonError("");
      phone_c = true;
    }
    if (c && phone_c) {
      getInstance().post('/api/v1/profil/replace-phone-accept', {
        phone: phone,
        smscode: code
    }).then(res=>{
        const s = _.get(res, 'data.success', 0);
        if(s===1){
            dispatch({type: "PROFILE", payload: {...profile, name: _.get(res, 'data.login')}});
            sessionStorage.removeItem("bot_url");
            sessionStorage.removeItem("phone");
            window.$('#PhoneUpdateModal').modal('hide');
            props.actState(0);
        }
        else{
            setCommonError(_.get(res, 'data.msg', '')); 
        }
        setLoading(false);
    }).catch(e=>{
        setLoading(false);
    });
    }else setLoading(false);
  };

  const handleOnChange = (value) => {
    if (value.length <= 5) {
      setCode(parseInt(value));
    } else {
      setCode(parseInt(value.slice(0, 5)));
    }
  }; 
  return (
    <>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>{t("validate_sms.title_bot")}</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'>
            <span className='icon icon-close'></span>
          </button>
        </div>
        <div className='modal-body'>
          <p style={{ textAlign: "center", fontWeight: 500 }}>
            {t("validate_sms.join_text")}
            <br />
            <a
              className='btn btn-primary btn-sm'
              target='_blank'
              style={{ color: "#fff" }}
              href={"https://t.me/" + _.get(update_phone, 'bot_url', '')}>
              {t("validate_sms.join")}
            </a>
          </p>
          <form onSubmit={onSubmit} noValidate>
            <div className='form-group mb-3'>
              <label for=''>{t("validate_sms.code_bot")}</label>
              <div className='password-field-wrapper'>
                <input
                  type='number'
                  name='code'
                  onChange={(e) => handleOnChange(e.target.value)}
                  className='form-control'
                  placeholder={t("validate_sms.code_bot")}
                  onClick={() => {
                    setCommonError("");
                    setCodeError("");
                  }}
                  value={code}
                />
                <div style={{ color: "red" }}>{codeError}</div>
                <div style={{ color: "red" }}>{commonError}</div>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <button type='submit' className='btn btn-primary w-100'>
                {t("validate_sms.btn")}
              </button>
            )}
            <div className='form-group mt-4 mb-5'>
              <p
                style={{ color: "#26CAAC", cursor: "pointer" }}
                onClick={() => props.actState(0)}>
                {t("update_phone.prev")}
              </p> 
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default PhoneUpdateBotSms;
